import React from 'react';
import img1 from '../../assests/images/alumni/15- Alumni/img1.jpg';
import img2 from '../../assests/images/alumni/15- Alumni/img2.jpg';
import img3 from '../../assests/images/alumni/15- Alumni/img3.jpg';
import img4 from '../../assests/images/alumni/15- Alumni/img4.jpg';
import img5 from '../../assests/images/alumni/15- Alumni/img5.jpg';
import img6 from '../../assests/images/alumni/15- Alumni/img6.jpg';
import img7 from '../../assests/images/alumni/15- Alumni/img4.jpg';
import img8 from '../../assests/images/alumni/15- Alumni/img7.jpg';
import img9 from '../../assests/images/alumni/15- Alumni/img8.jpg';
import img10 from '../../assests/images/alumni/15- Alumni/img9.jpg';

export default function Album() {
    const row1 = [
        { img: img1 }, { img: img2 }, { img: img3 }
    ]
    const row2 = [
        { img: img4 }, { img: img5 }, { img: img6 }, { img: img7 }
    ]
    const row3 = [
        { img: img8 }, { img: img9 }, { img: img10 }
    ]
    return (
        <div className='container'>
          <div className='d-flex'>
          {row1.map((img, i) => (
                <div className='col-4 alimg ' key={i}>
                    <img src={img.img} alt='' className='img-fluid blockimg' />
                </div>
            ))
            }
          </div>
          <div className='d-flex'>
          {row2.map((img, i) => (
                <div className='col-3 alimg' key={i}>
                    <img src={img.img} alt='' className='img-fluid blockimg' />
                </div>
            ))
            }
          </div>
          <div className='d-flex'>
          {row3.map((img, i) => (
                <div className='col-4 alimg' key={i}>
                    <img src={img.img} alt='' className='img-fluid blockimg' />
                </div>
            ))
            }
          </div>
        </div>
    )
}
