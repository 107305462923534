
import React, { useState } from 'react';
import EventDetail from '../components/Events/EventDetail';
import { useParams } from 'react-router-dom';
import EventPhotos from '../components/Events/EventPhotos';

export default function Events() {
    
    return (
        <div>
            <div className='aboutbg'>
                <div className='about-banner'>
                    <h2 className='title'>Events</h2>
                </div>
            </div>
             <EventPhotos />
                         
        </div >
    )
}
