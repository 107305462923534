import React, { useState, useRef, useEffect } from "react";
import aboutimg from "../../../assests/images/others/about1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import video from "../../../assests/images/ALC 1st NATIONAL SEMINAR 2023.mp4";

export default function Campus() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const windowHeight = window.innerHeight;
        const videoPosition = videoRef.current.getBoundingClientRect().top;
        const isVisible = videoPosition < windowHeight && videoPosition > 0;
        setIsPlaying(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  const toggleVideo = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      setShowVideo(true);
    }
  };

  return (
    <div className="container ">
      <div>
        <div>
          <div className=" d-flex justify-content-center position-relative">
            <img
              src={aboutimg}
              alt=""
              className={showVideo ? "d-none" : "img-fluid "}
            />
            {showVideo && (
              <video
                ref={videoRef}
                className="img-fluid"
                onClick={toggleVideo}
                controls
              >
                <source src={video} type="video/mp4" />
              </video>
            )}
            <div className="playdiv" onClick={toggleVideo}>
              <FontAwesomeIcon icon={faCaretRight} className="playicon" />
              <p>{isPlaying ? "Pause" : "Play"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
