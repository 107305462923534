import React, { useState, useEffect } from "react";
import pdfimg from "../assests/images/others/Group 1778.png";
import shoutimg from "../assests/images/others/Group 1771.png";
import Notices from "../components/notice/Notices";
import Archive from "../components/notice/Archive";
import Announcement from "../components/notice/Announcement";

export default function NoticesPage() {
  const [activeTab, setActiveTab] = useState("notices");

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="aboutbg">
        <div className="about-banner">
          <h2 className="title">NOTICES & ANNOUNCEMENTS</h2>
        </div>
      </div>
      <div className="noticepage py-5">
        <div className="container  ">
          <div className="d-flex">
            <div
              className={`notice-btn  d-flex justify-content-between align-items-center ${
                activeTab === "notices" ? "nactiveTab" : ""
              }`}
              onClick={() => handleClick("notices")}
            >
              <img src={pdfimg} alt="" className="img-fluid col-2" />
              <p className="mb-0 col-9">Notices</p>
            </div>
            <div
              className={`notice-btn d-flex justify-content-between align-items-center  ${
                activeTab === "announcements" ? "aactiveTab" : ""
              }`}
              onClick={() => handleClick("announcements")}
            >
              <img src={shoutimg} alt="" className="img-fluid col-2" />
              <p className="mb-0 col-9">Announcements</p>
            </div>
          </div>
          <div className="notice-cont p-3">
            {activeTab === "notices" && <Notices />}
            {activeTab === "announcements" && <Announcement />}
            {/* <Archive /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
