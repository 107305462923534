import React from 'react';
import img1 from '../../../assests/images/infrastructure/17- Classroooms/3A-Smart Class Room.jpg';
import img2 from '../../../assests/images/infrastructure/17- Classroooms/GMC21112023_153701.jpg';
import img3 from '../../../assests/images/infrastructure/17- Classroooms/GMC21112023_153732.jpg';
import img4 from '../../../assests/images/infrastructure/17- Classroooms/GMC21112023_154135.jpg';
import img5 from '../../../assests/images/infrastructure/17- Classroooms/WhatsApp Image 2024-02-12 at 10.46.27 AM.jpeg';
import img6 from '../../../assests/images/infrastructure/17- Classroooms/WhatsApp Image 2024-02-12 at 10.46.42 AM.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';


export default function Classrooms() {
    const audiimg = [
        { img: img1 },
        { img: img2 },
        { img: img3 },
        { img: img4 },
        { img: img5 },
        { img: img6 },
    ]
    return (
        <div className='container mt-4 sai'>
            <p className='progsubhead'>Smart Classrooms:</p>
            <p className='progtext'>All classrooms are spacious and are equipped with Smart Board, Speakers Audio Video system and Wi-Fi. </p>
            <Swiper
                modules={[Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{ delay: 3000 }}
                breakpoints={{
                    426: {
                        slidesPerView: 2
                    }
                }}
            >
                {
                    audiimg.map((img, i) => (
                        <SwiperSlide key={i}>
                            <img src={img.img} alt='' className='img-fluid swiperimg' />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}
