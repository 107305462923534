import React from 'react';
import img1 from '../../assests/images/Sports and Recreation/Copy of Chesss.png';
import img2 from '../../assests/images/Sports and Recreation/Copy of IMG_3064.jpg';
import img3 from '../../assests/images/Sports and Recreation/Copy of IMG_5778.png';
import img4 from '../../assests/images/Sports and Recreation/Copy of IMG_5851.png';
import img5 from '../../assests/images/Sports and Recreation/Copy of IMG_5876.png';
import img6 from '../../assests/images/Sports and Recreation/Copy of IMG_7236.png';
import img7 from '../../assests/images/Sports and Recreation/Copy of IMG_7592.png';
import img8 from '../../assests/images/Sports and Recreation/Copy of IMG_7611.png';
import img9 from '../../assests/images/Sports and Recreation/Copy of IMG_7635.png';
import img10 from '../../assests/images/Sports and Recreation/Copy of IMG_7698.png';
import img11 from '../../assests/images/Sports and Recreation/Copy of IMG_8291.png';
import img12 from '../../assests/images/Sports and Recreation/Copy of IMG_8320.png';
import img13 from '../../assests/images/Sports and Recreation/Copy of IMG_8344.png';
import img14 from '../../assests/images/Sports and Recreation/Copy of IMG_9122.png';
import img15 from '../../assests/images/Sports and Recreation/Copy of IMG_9308.png';
import img16 from '../../assests/images/Sports and Recreation/Copy of infra open gym - Copy.jpg';
import img17 from '../../assests/images/Sports and Recreation/IMG_20231018_225443.jpg';

export default function Sports() {
    const sportimages = [
        { id: 1, src: img1, colSpan: 6 },
        { id: 2, src: img2, colSpan: 6 },
        { id: 3, src: img3, colSpan: 5 },
        { id: 4, src: img4, colSpan: 7 },
        { id: 5, src: img5, colSpan: 7 },
        { id: 6, src: img6, colSpan: 5 },
        { id: 7, src: img7, colSpan: 6 },
        { id: 8, src: img8, colSpan: 6 },
        { id: 9, src: img9, colSpan: 5 },
        { id: 10, src: img10, colSpan: 7 },
        { id: 11, src: img11, colSpan: 7 },
        { id: 12, src: img12, colSpan: 5 },
        { id: 13, src: img13, colSpan: 6 },
        { id: 14, src: img14, colSpan: 6 },
        { id: 15, src: img15, colSpan: 5 },
        { id: 16, src: img16, colSpan: 7 },
    ];
    return (
        <div className='container mt-5'>
            <p className='titles text-center'>Sports & Extra Curricular Activities</p>
            <div className=' gallery '>
            {sportimages.map((image, i) => (
                <div
                    key={i}   
                    className={`image cspan-${image.colSpan}`}
                >
                    <img src={image.src} alt='' className={`img-fluid `} />
                </div>
            ))}

            </div>
        </div>
    )
}
