import React from 'react'
import Banner from '../components/programs/Banner'
import Sem1 from '../components/programs/BBAProgram/Sem1'
import Sem2 from '../components/programs/BBAProgram/Sem2'
import Sem3 from '../components/programs/BBAProgram/Sem3'
import Sem4 from '../components/programs/BBAProgram/Sem4'
// import { Document, Page } from 'react-pdf';

import Certificates from '../components/programs/Certificates'
import Sem5 from '../components/programs/BBAProgram/Sem5'
import Sem6 from '../components/programs/BBAProgram/Sem6'
import Sem7 from '../components/programs/BBAProgram/Sem7'
import Sem8 from '../components/programs/BBAProgram/Sem8'
import Sem9 from '../components/programs/BBAProgram/Sem9'
import Sem10 from '../components/programs/BBAProgram/Sem10'

export default function ProgramsPage2({ program, pdf }) {

  // let title;
  // if (program === 'BBA') {
  //   title = 'B.A. LL.B. (Bachelor of Arts and Bachelor of Laws)';
  // } else {
  //   title = 'B.B.A. LL.B. (Bachelor of Business Administration and Bachelor of Laws)';
  // }

  return (
    <div >
      <Banner />
      <div className='container' id="BBA">
        <div className='abouta d-flex justify-content-center flex-column align-items-center mt-5'>
          <h2 className='abouta-title'>BBA. LL.B. (Bachelor of Business Administration and Bachelor of Laws)</h2>
          <p className='progbtn mt-2'>Annual Intake: 60 Seats</p>
        </div>
        <p className='progsub'>BBA LL.B is a five years integrated program to develop legal professional having knowledge of management.</p>

        <h5 className='progsubhead mt-4'>Eligibility:</h5>
        <div className='progtext'>
          <p>Higher Secondary School Certificate Examination/ All India Senior School Certificate Examination (AISSCE) All (10+2) or its equivalent with minimum 50% marks. And relaxation of 5 % marks for SC/ST students.</p>
          <p> Bar Council rules for Eligibility of B.A. LL.B. (5 Year Law Course) as per Bar Council of India Legal Education Rules 2008, BCI Circular No. BCI: D: 1823/2010 dated 30- 11- 2010 and Circular No. 02/2010 dated 20- 12- 2010.</p>
          <p> Sec 5 (b) and explanation: - Applicants who have obtained 10+2 higher secondary pass certificate or first- degree certificate after prosecuting studies in distance or correspondence method are eligible for admission in the integrated five year course.</p>
          <p> Applicants who have obtained 10+2 or graduation/post- graduation through open universities system, directly without having any basic qualification for prosecuting such studies are NOT ELIGIBLE for admission in law course.</p>
          <p>Admission to 1st year shall be granted as per the eligibility criteria laid down by AWES in respect of children of Army Personnel. 100% seats are reserved for children of Army Personnel including the prescribed additional weightage in r/o wards of Gallantry Award recipient and War Widows.   </p>
        </div>
      </div>
      <div className='tablediv'>
        <h3 className='prog-title mt-5 p-4'>COURSE STRUCTURE (BBA., LL.B. Course)</h3>
        <Sem1 />
        <Sem2 />
        <Sem3 />
        <Sem4 />
        <Sem5/>
        <Sem6/>
        <Sem7/>
        <Sem8/>
        <Sem9/>
        <Sem10/>
      </div>
      <div className='container'>
        <h5 className='progsubhead  mt-4'>Syllabus:</h5>
        <div className='d-flex flex-wrap align-items-center'><p className='progtext my-0 mx-1'>Click here to download syllabus:</p>
          <span className='link'> <a href="http://collegecirculars.unipune.ac.in/sites/documents/Syllabus%202019/B.A.LL.B._B.B.A.LL.B._LL.B._Syllabus_as_Amended_in_July_2019_16.072019.pdf" className="link">
        https://www.unipune.ac.in /university_files/syllabus.pdf
      </a></span></div>
      </div>
    </div>
  )
}
