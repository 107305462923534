import React, { useState } from "react";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faEye,
  faHamburger,
  faTimeline,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Eventsdata, dropdownList } from "../../EventsData";
import { useNavigate } from "react-router-dom";
import EventDetail from "./EventDetail";

export default function EventPhotos() {
  const [selectedEvent, setSelectedEvent] = useState("2022-23");
  const [isOpen, setIsOpen] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2023-24");
  const handleChangeYear = (year) => {
    setSelectedYear(year);
  };

  const filteredEvents = selectedYear
    ? Eventsdata.filter((event) => event.year === selectedYear)
    : Eventsdata;

  const navigate = useNavigate();
  const handleClick = (name, id) => {
    setSelectedEvent(name, id);
    navigate(`/event/${name}'/${id}`);
  };
  const toggleMobileDrop = () => {
    setIsMobileOpen(!isMobileOpen);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectYear = (year) => {
    setSelectedYear(year);
    setIsOpen(true);
    setIsMobileOpen(false);
  };

  return (
    <div className="d-flex flex-wrap mt-5 container">
      <div className="col-lg-3 col-sm-12 col-md-3 px-3 ">
        <FontAwesomeIcon
          icon={faBars}
          className="eventbar"
          onClick={toggleMobileDrop}
        />
        {isMobileOpen && (
          <ul className="position-absolute Eventmobiledrop">
            {dropdownList.map((menuItem, index) => (
              <li
                key={index}
                className="Edropdown"
                onClick={() => selectYear(menuItem.year)}
              >
                {menuItem.year}
              </li>
            ))}
          </ul>
        )}
        <div className="EdropdownMenu">
          <li className="Edropdown" onClick={toggleDropdown}>
            {selectedYear}{" "}
            <FontAwesomeIcon
              icon={isOpen ? faAngleUp : faAngleDown}
              className=""
            />
          </li>

          {isOpen && (
            <>
              {dropdownList.map((year, index) => (
                <li key={index} onClick={() => selectYear(year.year)}>
                  {year.year}
                </li>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="col-lg-9 col-sm-12 col-sm-9 d-flex flex-wrap px-2">
        {filteredEvents.map((data, i) => (
          <div className="col-lg-4 col-sm-10 col-md-6 p-2" key={i}>
            <div
              className="eventimg "
              onClick={() => handleClick(data.name, data.id)}
            >
              <div className="e-img">
                <img
                  src={data.img}
                  alt=""
                  className="img-fluid position-relative"
                />
                <div className="overlay ">
                  <p className="overlay-text">
                    <div className="eover-text">
                      <FontAwesomeIcon icon={faEye} />
                      <span>
                        {data.relatedEvents[0].Images
                          ? data.relatedEvents[0].Images.length
                          : 0}{" "}
                        photos
                      </span>
                    </div>
                  </p>
                </div>
              </div>

              <p>{data.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
