import React from "react";

export default function Banner() {
  return (
    <>
      <div className="aboutbg">
        <div className="about-banner">
          <h2 className="title">About ALC</h2>
        </div>
      </div>
    </>
  );
}
