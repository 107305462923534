import React, { useEffect } from "react";
import form from "../../assests/images/alumni/ALC-ALUMNI-MEMBERSHIP-REGISTRATION-FORM_page-0001.jpg";
import coimg from "../../assests/images/others/img_team_1 (1).png";
import coimg1 from "../../assests/images/teaching/Dr kasturi.jpg";
import mail from "../../assests/images/alumni/email.png";
import pdf1 from "../../assests/images/pdf/ALUMNI ASSOCIATION OF ALC.pdf";
import dimg from "../../assests/images/others/default-avatar-photo-placeholder-icon-grey-vector-38508163.jpg";

const members = [
  // {
  //   name: "Dr. Madhushree Joshi",
  //   designation: "Chairman",
  //   department: "Principal",
  //   image: dimg,
  // },
  // {
  //   name: "Col AK Pandey (Retd)",
  //   designation: "Dy-chairman",
  //   department: "Registrar",
  //   image: dimg,
  // },
  {
    name: "Mr. Ganeshappa Desmane",
    designation: "Co-ordinator",
    department: "Ganesh.alc2021@gmail.com",
    image: coimg,
  },
  {
    name: "Dr. Kasturi Bhagat",
    designation: "Member",
    department: "Kasturibhagat@gmail.com",
    image: coimg1,
  },
  // {
  //   name: "Mr Bhaskar Lokhande",
  //   designation: "Member",
  //   department: "Adm Co-ord",
  //   image: dimg,
  // },
];

const coordinateTable = [
  {
    id: 1,
    name: "Dr. Madhushree Joshi",
    designation: "Chairman",
    department: "Principal",
    // image: dimg,
  },
  {
    id: 2,
    name: "Col AK Pandey (Retd)",
    designation: "Dy-chairman",
    department: "Registrar",
    // image: dimg,
  },
  {
    id: 3,
    name: "Mr. Ganeshappa Desmane",
    designation: "Co-ordinator",
    department: "Law",
    // image: coimg,
  },
  {
    id: 4,
    name: "Dr. Kasturi Bhagat",
    designation: "Member",
    department: "Law",
    // image: coimg1,
  },
  {
    id: 5,
    name: "Mr Bhaskar Lokhande",
    designation: "Member",
    department: "Adm Co-ord",
    // image: dimg,
  },
];

export default function Coordinator() {
  useEffect(() => {
    document.title = "Download Alumni Membership Form";
  }, []);

  return (
    <div className="container ">
      <div className="qassurance py-5">
        <h3 className="prog-title ">Co-ordinators</h3>
        <div class="tables  mt-3 container">
          <table className="custom-table container">
            <thead>
              <tr className="rtihead">
                <td className="col-1">Sr.No</td>
                <td className="col-3">Name Of Members</td>
                <td className="col-2">Designation</td>
                <td className="col-2">Department</td>
              </tr>
            </thead>
            <tbody>
              {coordinateTable.map((data, i) => (
                <tr key={i}>
                  <td className="col-1">{data.id}</td>
                  <td className="col-3">{data.name}</td>
                  <td className="col-2">{data.designation}</td>
                  <td className="col-2">{data.department}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-lg-12 col-sm-12 mt-4">
        <p className="progsubhead">Connect to Co-ordinator:</p>
        <div className=" container d-flex flex-wrap justify-content-center ">
          {members.map((member, index) => (
            <div className="teamcarddiv" key={index}>
              <div className="teamcard">
                <img
                  src={member.image} // Use the image property from the member object
                  className="card-img-top img-fluid"
                  alt={member.name} // Use member name for alt text
                />
              </div>
              <div className="card-body px-1 py-2 ">
                <h5 className="teamname">{member.name}</h5>
                <p className="destiny">{member.designation}</p>
                <p className="destiny">{member.department}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="px-3 progtext">
          <img src={mail} alt=" " className="img-fluid col-1" />
          <span className="col-11"> nageshkankialc@gmail.com</span>
        </div> */}
      </div>

      <div className="col-lg-12 col-sm-12 mt-4">
        <p className="progsubhead">Alumni Registration Form:</p>
        <div className="container d-flex justify-content-center ">
          <div className="formdiv col-6 col-sm-3">
            <a href={pdf1} download={pdf1}>
              <img src={form} alt="" className="img-fluid p-2" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
