import React from 'react';
import { DisiplinaryCommitee } from '../../../SocietiesCellData';

export default function Disiplinary() {
    
    return (
        <div className='container mt-5'>
            <p className='prog-title text-center'>College Disciplinary Committee</p>
            <div className='tables'>
                <table className="custom-table ">
                    <tbody>
                        <tr className='rtihead'>
                            <td className="col-1">No</td>
                            <td className="col-3">Name</td>
                            <td className="col-3">Designation</td>
                            <td className='col-2'>Contact No.</td>
                            <td className='col-3'>Email-Id</td>
                        </tr>
                        {
                            DisiplinaryCommitee.map((data, i) => (
                                <tr key={i}>
                                    <td className="col-1">{data.id}</td>
                                    <td className="col-3">{data.name}</td>
                                    <td className="col-3">{data.designation}</td>
                                    <td className='col-2'>{data.contact}</td>
                                    <td className='col-3'>{data.email}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
