import React from 'react';
import { semData } from '../../../SemData';

export default function Sem1() {
  const totalL = semData.reduce((acc, curr) => acc + curr.L, 0);
  const totalT = semData.reduce((acc, curr) => acc + curr.T, 0);
  const totalP = semData.reduce((acc, curr) => acc + curr.P, 0);
  const totalCredit = semData.reduce((acc, curr) => acc + curr.credit, 0);
  const totalInternal = semData.reduce((acc, curr) => acc + curr.internal, 0);
  const totalExternal = semData.reduce((acc, curr) => acc + curr.external, 0);
  const totalTotal = semData.reduce((acc, curr) => acc + curr.total, 0);

  return (
    <div className='d-flex flex-column justify-content-center container'>
      <p className='sem'>SEMESTER I</p>
      <div class="tables  mt-3" >
        <table className="custom-table ">
          <thead className='tablehead'>
            <tr>
              <th className="col-1">No</th>
              <th className="col-2">Course Code</th>
              <th className="col-3">Course</th>
              <th className='col-2'>Activity
                <div className='d-flex justify-content-center'>
                  <th className='col-4'>L</th>
                  <th className='col-4'>T</th>
                  <th className='col-4'>P</th>
                </div></th>
              <th className='col-1'>Credit</th>
              <th className='col-3'>Evaluation Scheme
                <div className='d-flex justify-content-center'>
                  <th className='col-4'>Internal</th>
                  <th className='col-4'>External</th>
                  <th className='col-4'>Total</th>
                </div></th>
            </tr>
          </thead>
          <tbody>
            {
              semData.map((data, i) => (
                <tr key={i}>
                  <td className='text-center col-1' >{data.id}</td>
                  <td className='text-center col-2'>{data.code}</td>
                  <td className='text-left col-3'>{data.subject}</td>
                  <td className='col-2 '>
                    <div className='d-flex justify-content-center'>
                      <td className='col-4 text-center'>{data.L}</td>
                      <td className='col-4 text-center'>{data.T}</td>
                      <td className='col-4 text-center'>{data.P}</td>
                    </div>
                  </td>
                  <td className='text-center col-1'>{data.credit} </td>
                  <td className='col-2 '>
                    <div className='d-flex justify-content-center'>
                      <td className='col-4 text-center'>{data.internal}</td>
                      <td className='col-4 text-center'>{data.external}</td>
                      <td className='col-4 text-center'>{data.total}</td>
                    </div>
                  </td>
                </tr>
              ))
            }

          </tbody>
          <tfoot>
            <tr>
            <td></td>
              <td></td>
              <td className='text-left'>Total</td>
              <td className='col-2'>
                <div className='d-flex justify-content-center'>
                  <td className='col-4 text-center'>{totalL}</td>
                  <td className='col-4 text-center'>{totalT}</td>
                  <td className='col-4 text-center'>{totalP}</td>
                </div>
              </td>
              <td className='col-1 text-center'>{totalCredit}</td>
              <td className='col-3'>
                <div className='d-flex justify-content-center'>
                  <td className='col-4  text-center'>{totalInternal}</td>
                  <td className='col-4  text-center'>{totalExternal}</td>
                  <td className='col-4  text-center'>{totalTotal}</td>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}
