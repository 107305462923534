import prosimg from "./assests/images/others/prospectusimg.png";
import coimg1 from "./assests/images/teaching/teaching-staff23_10_2.png";
import coimg2 from "./assests/images/teaching/teaching-staff23_10_3.png";
import pdf from "./assests/images/pdf/ALC_Pune_Prospectus-2023 (1).pdf";
import prashant from "./assests/images/teaching/Mr. Prashant Vanshiv - Librarian.png";
import prasad from "./assests/images/teaching/teaching-staff23_10_5.png";
import nagesh from "./assests/images/teaching/teaching-staff23_10_4.png";
import tejaswini from "./assests/images/teaching/Ms. Tejaswini Dewangan.png";
import job1 from "./assests/images/jobs&tenders/JOBS1.jpg";
import job2 from "./assests/images/jobs&tenders/JOBS2.jpg";
import gajanan from "./assests/images/teaching/G_V_Patil.png";
import dimg from "./assests/images/others/default-avatar-photo-placeholder-icon-grey-vector-38508163.jpg";
import hrishikesh from "./assests/images/teaching/Copy of Hrishikesh Honkalas.png";
import img1 from "./assests/images/commitee/Moot Court Society.jpg";
import img3 from "./assests/images/commitee/ADR CELL.jpg";
import img2 from "./assests/images/commitee/Career & Placement Cell.jpg";
import img4 from "./assests/images/commitee/Legal AID.jpg";
import img5 from "./assests/images/commitee/Library Development Cell.jpg";
import img6 from "./assests/images/commitee/MUN Cell.jpg";
import img7 from "./assests/images/commitee/NATIONAL SERVICE SCHEME.jpg";
import img8 from "./assests/images/commitee/PR and Media Cell.jpg";
import img9 from "./assests/images/commitee/Research Cell.jpg";
import defimg from "./assests/images/others/about-bg.jpg";

export const committeeData = {
  MootCourt: {
    title: "MOOT COURT SOCIETY",
    imageUrl: img1,
    bgColor: "#F1A95E",
    about: {
      title: "About",
      content: ` Moot Court Society is one of the most important and prestigious committees of a law school. Mooting is the activity which most closely resembles what one does as a professional lawyer. It is something that every student in a law school must, at the very least, be inclined towards learning. 
      The Moot Court Society, therefore, aims to create a professional skill culture among the students by training them for various inter and intra college competitions and train them to learn the element that makes the difference between the winning and losing side in an apparently objective and concrete subject.
      `,
    },
    object: {
      title: "Object",
      data: [
        {
          id: 1,
          list: "To keep the spirits alive on the campus by organizing various moot court competitions and inductions.",
        },
        {
          id: 2,
          list: "To provide platform for the students to create a professional skill culture by training them for national and international moot court competitions",
        },
        {
          id: 3,
          list: "To assist students in understanding real- life proceedings and the proper code of conduct and use of formal language.",
        },
        {
          id: 4,
          list: "To assist students in learning the art of mooting by training them to understand the interpretation and application of law.",
        },
        {
          id: 5,
          list: "To assist the students in their drafting skills and research skills.",
        },
      ],
    },
    composition: {
      title: "Composition",
      content: `The Cultural committee will comprise of following members:      `,
      data: [
        { id: 1, list: "Faculty Coordinator" },
        { id: 2, list: "Student Convener" },
        { id: 3, list: "Student Co-Conveners" },
        { id: 4, list: "Student Co- Conveners" },
        { id: 5, list: "Student Members " },
      ],
    },
    duties: {
      title: "Duties & Responsibilities",
      data: [
        {
          id: 1,
          list: "The Moot Court Society shall be responsible for all intra and inter collegiate Moot Court Competitions.",
        },
        {
          id: 2,
          list: "To plan and schedule Intra College Moot Court Competitions for the academic year. (Tentative dates to be included in the academic calendar of the institute.)",
        },
        {
          id: 3,
          list: "To plan and schedule trials for Inter College Moot Court Competitions.",
        },
        {
          id: 4,
          list: "To assess the students during trials with respect to their mooting, research and drafting skills and further train them.",
        },
        {
          id: 5,
          list: "To conduct Induction for new students and teach them the basics of mooting and how to write memorial.",
        },
        {
          id: 6,
          list: "To prepare budget for all Moot Court events and take necessary steps for its approval.",
        },
        {
          id: 7,
          list: "The co-ordinator of the committee shall conduct a meeting of the committee from time to time to discuss and delegate the task.",
        },
        {
          id: 8,
          list: "To maintain year- wise compendium of moot court events and submit the same to the respected authorities for record keeping.",
        },
        {
          id: 9,
          list: "Any other duties the Principal / Registrar may assign.",
        },
      ],
    },
    contact: {
      title: "Contact",
      coordinators: [
        {
          img: coimg1,
          name: "Ms. Shreya Shrivastav",
          destiny: "Co-ordinator",
          email: "shreyasrialc@gmail.com",
        },
        {
          img: dimg,
          name: " Mr. Prasad Kamble",
          destiny: "Co-ordinator",
          email: "prasadkamblealc@gmail.com",
        },
      ],
    },
  },
  LibraryCell: {
    title: "LIBRARY DEVELOPMENT CELL",
    imageUrl: img5,
    bgColor: "#D4F15E",
    about: {
      title: "About",
      content: `Library Development Cell in our college is a significant step toward enhancing the academic environment and supporting the educational needs of students and faculty. This specialized unit is dedicated to the expansion and improvement of the college's library resources, services, and infrastructure. It plays a crucial role in acquiring new books, digital resources, and research materials, ensuring that the library remains up-to-date and relevant. Additionally, the Library Development Cell focuses on organizing workshops, seminars, and training sessions to promote information literacy and research skills among students. It acts as a bridge between the academic community and the ever- evolving world of knowledge, contributing to the overall growth and excellence of the institution.
      `,
    },
    object: {
      title: "Object",
      content:
        "The following are the objectives of the Library Development Cell.",
      data: [
        {
          id: 1,
          list: "Facilitating communication with and obtaining feedback from the College community on library collections, programs, services, infrastructure, and communication mechanisms.",
        },
        {
          id: 2,
          list: "To provide a forum for discussing College library and its community’s needs and expectations for information.",
        },
        {
          id: 3,
          list: "To act as advocates for the Library in College‐wide decision‐making groups",
        },
        {
          id: 4,
          list: "To advise the library on policy matters concerning services, resources and facilities.",
        },
        {
          id: 5,
          list: "To provide an opportunity to raise and discuss initiatives in the provision of library and information services.",
        },
        {
          id: 6,
          list: "To facilitate communication between the Library and the community it serves.",
        },
      ],
    },
    composition: {
      title: "Composition",
      content: ` The Library Development Cell will comprise of the following:`,
      data: [
        { id: 1, list: "Faculty Coordinator" },
        { id: 2, list: "Student Coordinator" },
        { id: 3, list: "Student Co-Conveners" },
        { id: 4, list: "Co - coordinators " },
        { id: 5, list: " Members " },
      ],
    },
    duties: {
      title: "Duties & Responsibilities",
      data: [
        {
          id: 1,
          list: `Procuring books, journals, digital resources, and other educational materials to expand the library's collection.`,
        },
        {
          id: 2,
          list: " Digitizing valuable resources and maintaining an updated catalog for easy access.",
        },
        {
          id: 3,
          list: "Assistance to students and faculty in finding and utilizing library resources effectively.",
        },
        {
          id: 4,
          list: "Organizing workshops, seminars, and training sessions to support research endeavors.",
        },
        {
          id: 5,
          list: "Establishing partnerships with other institutions, libraries, and publishers to access a wider range of resources.",
        },
        {
          id: 6,
          list: "Ensuring that library services are accessible to all, including individuals with disabilities.",
        },
        {
          id: 7,
          list: "Gathering feedback from the library users to improve services and resource selection.",
        },
        {
          id: 8,
          list: "Efficiently managing the budget allocated for library development initiatives.",
        },
        {
          id: 9,
          list: "Implementing and maintaining library management systems and adopting modern technologies. ",
        },
        {
          id: 10,
          list: `Developing a strategic plan for the library's growth and continuous improvement. Any other duties the Principal / Registrar may assign.`,
        },
      ],
    },
    contact: {
      title: "Contact",
      coordinators: [
        {
          img: prashant,
          name: "MR. Prashant Bharat Vanshiv",
          destiny: "Co-ordinator",
          email: "prasshantvanshiv@gmail.com",
        },
      ],
    },
  },
  PRMedia: {
    title: "PUBLIC RELATIONS AND MEDIA CELL ",
    imageUrl: img8,
    bgColor: "#F1DE5E",
    about: {
      title: "About",
      content: ` Public Relations and Media Cell (PR and Media Cell) is constituted to streamline the reports of various events organized on campus. The Committee maintains a media content loop and work towards developing the college's public relations. PR and Media Cell also ensures that the activities where students have represented the College are placed on record. The cell further keeps track of the scheduled events like guest lectures, workshops, seminars, cultural events, etc. It serves as a strategic communication hub with multifaceted objectives to foster positive relationships, enhance institutional visibility, and ensure effective communication.`,
    },
    object: {
      title: "Object",
      data: [
        {
          id: 1,
          list: "To manage and cultivate a positive image by highlighting academic achievements, innovative programs, and contributions to the community. Through strategic communication, it aims to build trust and credibility among students.",
        },
        {
          id: 2,
          list: "To ensure the timely and accurate dissemination of information related to academics, student achievements, and institutional and grand events.",
        },
        {
          id: 3,
          list: "To promote and organize a diverse range of activities, including academic guest lectures, cultural and sport events, legal aid programs, etc.",
        },
        {
          id: 4,
          list: "To form a platform to bring together ideas and creative skills of different students.",
        },
        {
          id: 5,
          list: "To arrange outdoor sessions, so students can directly implement a few skills or tips given.",
        },
      ],
    },
    composition: {
      title: "Composition",
      content: `The Public Relations and Media Cell comprises of the following:      `,
      data: [
        { id: 1, list: "Faculty Coordinator" },
        { id: 2, list: "Student Coordinator" },
        { id: 3, list: "Co - coordinators " },
        { id: 4, list: " Members " },
      ],
    },
    duties: {
      title: "Duties & Responsibilities",
      data: [
        {
          id: 1,
          list: "The cell shall be responsible for developing a comprehensive understanding of the institution’s values, mission and strengths. Through strategic communication, the cell highlights academic achievements, research breakthroughs, faculty accolades, and successful student stories. Regularly showcasing the contribution of college to build a strong and good community",
        },
        {
          id: 2,
          list: "The cell creates a yearly newsletter that includes all about the college and its various committees also including the student achievements, grand events, educational visits, and all the guest lectures.",
        },
        {
          id: 3,
          list: "The cell promotes and organizes various academic workshops, seminars, cultural and sports events, and other significant activities. Events also showcase various talents of students and give them opportunities to excel.",
        },
        {
          id: 4,
          list: "It oversees all digital platforms of the college to develop a content strategy aligned with the institution’s goals, create compelling multimedia content, and engage with the online community.",
        },
        {
          id: 5,
          list: "The cell regularly updates the college activities, for interactive content, and strategic use of social media contributes to a dynamic and positive digital presence reaching a broad audience.",
        },
        {
          id: 6,
          list: "The cell works closely with faculty and committee heads to identify noteworthy research, innovative programs, and academic excellence. This ensures that the communication strategy aligns with the academic goals and priorities of the institution.",
        },
      ],
    },
    contact: {
      title: "Contact",
      coordinators: [
        {
          img: prashant,
          name: "MR. Prashant Bharat Vanshiv",
          destiny: "Co-ordinator",
          email: "prasshantvanshiv@gmail.com",
        },
      ],
    },
  },
  Cultural: {
    title: "Cultural & Extra-Curricular Committee",
    imageUrl: defimg,
    bgColor: "#8FF15E",
    about: {
      title: "About",
      content: `The Cultural Committee is one of the crucial committees responsible for the healthy and cohesive environment during the students’ Institute life...`,
    },
    object: {
      title: "Object",
      data: [
        {
          id: 1,
          list: "To provide platform for the students to showcase talent inside and outside Institute.",
        },
        {
          id: 2,
          list: "To keep the spirits alive on the campus by organizing multitude of cultural activities round the year.",
        },
        {
          id: 3,
          list: "To organize and celebrate festivals or frequent open air get- togethers sessions to bind the students.",
        },
        {
          id: 4,
          list: "To encourage and organize Inter- Batch events, Fresher’s party, Farewell party, etc.",
        },
        {
          id: 5,
          list: "To encourage the students, participate in both Intra and Inter- Institute Cultural Competitions.",
        },
      ],
    },
    composition: {
      title: "Composition",
      content: `The Cultural Committee will comprise as under:`,
      data: [
        { id: 1, list: "Faculty Coordinator" },
        { id: 2, list: "Student Coordinator" },
        { id: 3, list: "Co - coordinators " },
        { id: 4, list: " Members " },
      ],
    },
    duties: {
      title: "Duties & Responsibilities",
      data: [
        {
          id: 1,
          list: "The Cultural Committee shall be responsible for all intra and inter collegiate cultural events in the Institute.",
        },
        {
          id: 2,
          list: "To plan and schedule cultural events for the academic year. (Tentative dates to be included in the academic calendar of the institute.)",
        },
        {
          id: 3,
          list: "To prepare budget for all cultural events and take necessary steps for its approval.",
        },
        {
          id: 4,
          list: "The Coordinator of the committee shall conduct a meeting of the committee to discuss and delegate task.",
        },
        {
          id: 5,
          list: "To maintain year- wise compendium of cultural events and submit the same to the respected authorities for record keeping.",
        },
        {
          id: 6,
          list: "The committee shall display on Institute Website about festivals to be celebrated and write up on events already celebrated.",
        },
        {
          id: 7,
          list: "Any other duties the Principal / Registrar may assign.",
        },
      ],
    },
    // activity: {
    //   title: 'Activity',
    //   images: [
    //     {img:prosimg, link:pdf},
    //     {img:prosimg, link:pdf},
    //     {img:prosimg, link:pdf}
    //   ],
    // },
    contact: {
      title: "Contact",
      coordinators: [
        {
          img: coimg1,
          name: "Ms. Shreya Shrivastav",
          destiny: "Co-ordinator",
          email: "shreyasrialc@gmail.com",
        },
        {
          img: coimg2,
          name: "Ms. Tanvi Goyal",
          destiny: "Co-ordinator",
          email: "tanvigoyal.alc@gmail.com",
        },
      ],
    },
  },
  NSS: {
    title: "NATIONAL SERVICE SCHEME ",
    imageUrl: img7,
    bgColor: "#5EF195",
    about: {
      title: "About",
      content: ` Army Law College, Pune started an NSS unit from the academic year 2022-23 with 50 Student Volunteers with the objective to develop student's personality through community service, to identify the needs and problems of the community and involve them in the problem solving process.  `,
    },
    object: {
      title: "Object",
      content: `The National Service Scheme (NSS) is an Indian government- sponsored public service program conducted by the Department of Youth Affairs and Sports of the Government of India, which is popularly known as NSS. The NSS scheme was launched in Mahatma Gandhiji's Centenary year, 1969.
          The main objective of the National Service Scheme is “development of the personality of students through community service”. This objective is sought to be achieved by enabling the students to work in community. The programme aims to inculcate social welfare in students, and to provide service to society without bias.
          The objectives of National Service Scheme (NSS) are
          `,
      data: [
        {
          id: 1,
          list: "To enable the students to understand the community in which they work",
        },
        {
          id: 2,
          list: "To understand themselves in relation to their community.",
        },
        {
          id: 3,
          list: "To identify the needs and problems of the community and involve them in problem solving process.",
        },
        {
          id: 4,
          list: "To develop among themselves a sense of social and civic responsibility",
        },
        {
          id: 5,
          list: "To utilize their knowledge in finding practical solutions to individual and community problems.",
        },
        {
          id: 6,
          list: "To develop competence required for group-living and sharing of responsibilities",
        },
        {
          id: 7,
          list: "To acquire leadership qualities and democratic attitude ",
        },
        {
          id: 8,
          list: "To develop capacity to meet emergencies and national disasters",
        },
        { id: 9, list: "To practice national integration and social harmony" },
      ],
    },
    contact: {
      title: "Contact",
      coordinators: [
        {
          img: nagesh,
          name: "Asst. Prof. Nagesh Kanki ",
          destiny: "NSS Programme Officer",
          email: "nageshkankialc@gmail.com",
        },
      ],
    },
  },
  Carrer: {
    title: "CAREERS & PLACEMENTS",
    imageUrl: img2,
    bgColor: "#5EF1CF",
    about: {
      title: "About",
      content: ` In the current scenario career progression of the students through guidance and placement is of the prime concern for all stakeholders. Since it facilitates the provision of linkages of the student’s campus life with the realities to be faced by them when they seek employment or opt for further higher studies. At ALC, Pune we are committed to smoothen this transition by providing state-of-the-art inputs and facilities. The motive of ALC Internship & Placement Cell is to have Industry Institute Interface. To identify and lessen wherever there is a gap in the employability skills of the students, curricula of the course and the requirements of the industries or the requirements for qualifying for higher education. For this ALC – PLACEMENT CELL will undertake the task of organising suitable programs and imparting it to the young budding professionals, through seminars, workshops, corporate talks, field visits, training programs etc. and allied placement activities. Further, the cell will organise placement drives and provide all infrastructural support required for the selection process of the students by various organisations visiting the campus for placements.
          `,
    },
    object: {
      title: "Object",
      content: `To ensure that the students advancement needs are addressed in the context of career development. In order to ensure that students are well placed, the college will support them in the development of soft skills and core skills.
As the ALC students may also need training in various aspects of career development. ALC will guide students in getting internships, allied certificate courses, on-the-job training and add-on courses that will enable the students to excel in their careers.`,
      data: [],
    },
    composition: {
      title: "Composition",
      content: `Placement cell will have the following members:
          The placement cell will be headed by the Faculty in-charge of Placements / Placement officer of ALC. The cell will be functioning with the help of ALC student members of the cell.
          `,
      data: [
        { id: 1, list: "Faculty In-charge / Placement Coordinator" },
        { id: 2, list: "Student Convener" },
        { id: 3, list: "Student Co-Conveners" },
        { id: 4, list: "Student Members" },
      ],
    },

    rules: {
      title: "Rules",
      data: [
        {
          id: 1,
          list: " The interested students shall apply for various positions upon announcement of vacancies.",
        },
        {
          id: 2,
          list: " The students shall apply for posts of one Cell at a time. ",
        },
        {
          id: 3,
          list: " Appointment shall be made by way of interview conducted on campus by respective Faculty Co-ordinators.",
        },
        {
          id: 4,
          list: " Decision of the Faculty Co-ordinators is final and binding. ",
        },
        {
          id: 5,
          list: " The students shall be appointed for a term of one academic year.",
        },
        {
          id: 6,
          list: "	The students are permitted for re-appointment in the next academic year upon following the procedure of appointment, as mentioned above. ",
        },
        {
          id: 7,
          list: "The term of the students may be terminated before the end of the academic year owing to any form of misbehaviour, violation of Code of Conduct, failure to observe the assigned duties or responsibilities, as per the discretion of the Faculty Co-ordinators.",
        },
      ],
    },
    functioning: {
      title: "Functioning",
      content: `The ALC - PLACEMENT CELL will be coordinated by coordinator teacher represented by a senior teacher/ placement coordinator as coordinator. 
      Throughout the year the Cell shall organise activities such as career guidance workshops/ seminars, career fairs, lectures by corporates, tests, training programs etc. 
      Campus Interviews will be conducted for the students seeking internships and also for final year students. ALC - PLACEMENT CELL may have MOUs with corporates from the public and private sectors to upgrade the employability skills of the students and to conduct campus interview for the students.  
      `,
    },
    resources: {
      title: "Resources",
      content: `Resources like, seminar hall to conduct pre-placement talks and workshops, seminars and other programmes, computer labs to conduct online tests, classrooms for selection tests, pre-placement talks, group discussions and interview cabins etc. will be provided by ALC.
      ALC Intends to maintain a symbiotic relationship with multiple industries and institutions across diverse segments as it will help in getting internships and placements for the students in various organisations. Accordingly, it is expected that mementoes, refreshments and lunch etc. for the concerned guests will be provided by ALC during placement drive/activity.
      `,
    },
    activity: {
      title: "Activities Info",
      content: `The Placement Cell shall organise both on/off campus placement drives. 
      The training programs, workshops, career fairs and value addition programs may be planned and organized to ensure that the students derive maximum benefits. 
      Additional programmes suggested for the students: 
            `,
      data: [
        { id: 1, list: "	Soft skill development " },
        { id: 2, list: "	Technical skill development " },
        { id: 3, list: "	Additional Certification courses " },
        { id: 4, list: "	Entrepreneurship Centre" },
        { id: 5, list: "	Training program " },
        { id: 6, list: "	Skill Enhancement Programme " },
        { id: 7, list: "	Placement oriented training " },
        { id: 8, list: "	Life-skill training" },
        {
          id: 9,
          list: "	Turning point and other trainings etc. to develop employability and personality of the students.",
        },
        {
          id: 10,
          list: "	Excellent support from society through CSR activities. ",
        },
        {
          id: 11,
          list: "	Preparing the students in acquiring knowledge through personal development skills like: Self-confidence, Self-discipline, Self-reliance, Awareness of strengths and weaknesses.",
        },
        {
          id: 12,
          list: "	Creativity, independence, knowledge of international affairs.",
        },
        {
          id: 13,
          list: "	Desire to go on learning, ability to reflect, reliability, integrity, honesty, an understanding of equality of opportunity and the recognition and celebration of diversity in others.",
        },
        {
          id: 14,
          list: "	Enterprise and business skills through: Entrepreneurial skills as appropriate, Ability to prioritize tasks, time management, interpersonal skills, presentational skills.Ability to work in teams and leadership skills. Commercial awareness, flexibility, innovation, independence and risk-taking.",
        },
      ],
    },
    eligibility: {
      title: "Eligibility",
      content: ` Eligibility criteria will be as per the norms of the company providing placement opportunities. A student shall be permitted to appear in the campus recruitment drive subject to his/her meeting the criteria laid down by the visiting companies and the conditions put forward by the companies are acceptable to him.      
      `,
    },
    prerequisite: {
      title: "Prerequisite for Placement",
      data: [
        {
          id: 1,
          list: " Academic Attendance of 75% in the current semester of the final year is mandatory for every student on the date of campus placement drive to appear in the campus placement drive of any company. ",
        },
        {
          id: 2,
          list: "Fulfilling the criteria of Internship duration as prescribed by SPPU is compulsory. (Failing to which there will be no placement assistance for the student doing so)",
        },
        {
          id: 3,
          list: "Debarred students or students having a year down or the students having previous pending fees dues will not be eligible for placements.",
        },
        {
          id: 4,
          list: "No Preference related to Place of work will be entertained.",
        },
        {
          id: 5,
          list: "The students in the final semester of their program will be eligible for the placements. And the placed students may be considered for providing concession in attendance if they fulfil the required guidelines of the institute and take approval from the concerned authorities. However, it is expected that the students must attend the classes whenever possible and exemption from attending the classes on the ground of being placed cannot be considered their right",
        },
        {
          id: 6,
          list: "Internship can be undertaken by the students during vacations or after college hours. No exemptions from attendance will be provided to the students undertaking internships during the entire course. ",
        },
      ],
    },
    // contact: {
    //   title: 'Contact',
    //   coordinators: [
    //     { img: nagesh, name: 'Asst. Prof. Nagesh Kanki ', destiny:'NSS Programme Officer', email: 'nageshkankialc@gmail.com' },
    //   ]
    // }
  },

  NAAC: {
    title: "NAAC/ IQAC ",
    imageUrl: defimg,
    bgColor: "#5E63F1",
    about: {
      title: "About",
      content: `The Internal Quality Assurance Cell of the Army Law College, Kanhe is established in the year 2019. Since then the cell is focusing on holistic quality development of academic and administrative segments of the College.
      `,
    },
    composition: {
      title: "Composition",
      content: "",
      data: [
        {
          id: 1,
          list: "Mr. Ganeshappa Deshmane (Principal I/C)",
          designation: "Chairperson",
        },
        {
          id: 2,
          list: "Dr. Hrishikesh Honkalas",
          designation: "Co Ordinator- IQAC",
        },
        {
          id: 3,
          list: "Mr. Nagesh Kanki",
          designation: "Representative Teachers",
        },
        {
          id: 4,
          list: "Ms. Anurita Yadav",
          designation: "Representative Teachers",
        },
        {
          id: 5,
          list: "Mr. Sudam Jadhao",
          designation: "Senior Administrative Officer ",
        },
        {
          id: 6,
          list: "Mr. Thorat",
          designation: "Senior Administrative Officer ",
        },
        {
          id: 7,
          list: "Col. Thapa (SO)",
          designation: "Management Representative",
        },
        // { id: 8, list: '________', designation:'Nominee from Local Society' },
        {
          id: 8,
          list: "Ms. Chanchal Dagur",
          designation: "Student Representative",
        },
        {
          id: 9,
          list: "Ms. Prerna Manocha",
          designation: "Student Representative",
        },
        {
          id: 10,
          list: "Adv. Swapnil Bengali",
          designation: "Member Stakeholder",
        },
      ],
    },

    object: {
      title: "Objectives",
      content:
        "The Internal Quality Assurance Cell (IQAC) of the Army Law Colleges constantly endeavours to adhere to maintaining of highest quality standards in maximising holistic development of students, faculties and administrative staff. The institution prospects this development through following objectives.",
      data: [
        {
          id: 1,
          list: "Consistent upgradation of academic and administrative policies best suiting for the student centric enrichment.",
          designation: "",
        },

        {
          id: 2,
          list: "Amplify outreach towards industry experts and stakeholders creating opportunities for students.",
          designation: "",
        },

        {
          id: 3,
          list: "Promotion of professional and healthy work culture by strict adherence ethical practices providing conducive working environment for all.",
          designation: "",
        },
      ],
    },

    duties: {
      title: "Functions",
      content: "",
      data: [
        {
          id: 1,
          list: "Consistent in adhering to higher standards of providing quality legal education.",
          designation: "",
        },

        {
          id: 2,
          list: "Setting up of SOPs for various college level committees ensuring standard and uniform work process enhancing working and system efficiency.",

          designation: "",
        },

        {
          id: 3,
          list: "Focus on faculty development by constant encouragement to conduct and participate in various Faculty Development Programs, publications, organisation of seminars, symposiums and other academic enrichment events.  ",
          designation: "",
        },

        {
          id: 4,
          list: "Continuing mentor mentee development program intended towards, complete support to students inspiring and encouraging them beyond classroom engagement.",
          designation: "",
        },

        {
          id: 5,
          list: " Development of strong feedback mechanism ensuring identification, analysis and upgrade of areas having scope for development. ",
          designation: "",
        },

        {
          id: 6,
          list: "Developing systematic arrangement for Documentation and archival thereof.",
          designation: "",
        },
      ],
    },

    contact: {
      title: "Contact",
      coordinators: [
        {
          img: hrishikesh,
          name: "Dr. Hrishikesh Honkalas,",
          destiny: "Co Ordinator",
          email: "dr.hrishikeshhonkalas.alc@gmail.com",
        },
      ],
    },
  },

  JOBS: {
    title: "JOBS/TENDERS",
    imageUrl: defimg,
    bgColor: "#5ec0f1",
    job: {
      title: "Jobs & Tenders",
      coordinators: [{ img: job1 }, { img: job2 }],
    },
  },
  LAC: {
    title: "LEGAL AID CELL",
    imageUrl: img4,
    bgColor: "#5EF178",
    about: {
      title: "About",
      content: ` “Sahay” the legal Aid Cell of the Army Law College is actively involved in reaching the needy masses of the society to deliver quality legal and paralegal services. The cell organises various activities like Legal Aid Camps, Legal Awareness drives, free legal Consultation programs. 
      The new relocated Legal Aid Cell is inaugurated by the auspicious hand of Hon’ble Justice Shyam C. Chandak the then Principal District Judge, District and Sessions Court Pune. Since then the cell has conducted activities like Youth Awareness, Legal Awareness along with participation in Lok Adalats and so on.
    
      `,
    },
    object: {
      title: "Object",
      content: `The Cell focuses on maximising social outreach, reaching needy sections of the society who are unable to avail access to justice due to indigency, lack of awareness and so on. The cell also emphases on student development through conduction of various guest lectures and programs enabling them to understand practical intricacies involved in law which can help them to further develop their legal consultation skills.  
      `,
      data: [],
    },

    composition: {
      title: "Composition",
      content: `The Cultural committee will comprise of the following member: `,
      data: [
        { id: 1, list: "Faculty Coordinator " },
        { id: 2, list: "Student Coordinator" },
        { id: 3, list: "Co - coordinator " },
        { id: 4, list: "Member" },
      ],
    },
    duties: {
      title: "Duties & Responsibilities",
      data: [
        {
          id: 1,
          list: `The Cultural Committee shall be responsible for all intra and inter collegiate cultural events in the Institute.
        `,
        },
        {
          id: 2,
          list: " To plan and schedule cultural events for the academic year. (Tentative dates to be included in the academic calendar of the institute.) ",
        },
        {
          id: 3,
          list: "To prepare budget for all cultural events and take necessary steps for its approval.",
        },
        {
          id: 4,
          list: "The Coordinator of the committee shall conduct a meeting of the committee to discuss and delegate task.",
        },
        {
          id: 5,
          list: " To maintain year- wise compendium of cultural events and submit the same to the respected authorities for record keeping. ",
        },
        {
          id: 6,
          list: "The committee shall display on Institute Website about festivals to be celebrated and write up on events already celebrated. ",
        },
        {
          id: 7,
          list: "Any other duties the Principal / Registrar may assign. ",
        },
      ],
    },
    contact: {
      title: "Contact",
      coordinators: [
        {
          img: dimg,
          name: "Dr. Hrishikesh Honkalas,",
          destiny:
            "Co Ordinator, Legal Aid Cell Army Law College, Kanhe Pune. ",
          email: "dr.hrishikeshhonkalas.alc@gmail.com",
        },
      ],
    },
  },
  RAJ: {
    title: "RESEARCH AND JOURNAL CELL",
    imageUrl: img9,
    bgColor: "#5ED0F1",
    about: {
      title: "About",
      content: ` Research is the soul of reason and judicious decision-making. Since the beginning of the first year in 2018 of Army Law College, Pune, a fully operational Legal Research Cell has been established with an aim to promote research culture among law students and academia. The Research and Journal Cell, with its motto तत्ववेधनित्यम्, (the eternal seeking of truth) is passionately dedicated to nurture and promote the cognitive culture of reading, research and democratic deliberation. 
      The Research and Journal Cell since August, 2022, is led and coordinated by Asst. Prof. (Law) Mr. Ajay Tambulkar and Asst. Prof. (Eng.) Mr. Vinod Jangle. The committee for academic year 2023-24 consists of Ms. Anjali Devi of 5th Year BBA-LLB, as Convener and Ms. Niharika Sinha, 4th Year BBA-LLB, as Co-Convener along with 12 student members. Post the appointment of Asst. Prof. (Law) Mr. Ajay Tambulkar the committee has received a second breath and has been revived on a war-footing, with the cell currently being engaged in furthering its overall mission objectives by initiating various research-oriented activities. 
      
    
      `,
    },
    object: {
      title: "Object",
      content: `
           `,
      data: [
        {
          id: 1,
          list: `	To inculcate the Constitutional goal of scientific temper and rational outlook among students and end beneficiary at large.
      `,
        },
        {
          id: 2,
          list: `	To establish the Research & Journal Cell of Army Law College, Pune as a Centre of Excellence in Research and academia across all disciplines.
      `,
        },
        {
          id: 3,
          list: `	To inspire, train and assist students of Army Law College, Pune as well as stakeholders across institutions to undertake research, develop analytical bent of mind
      `,
        },
        {
          id: 4,
          list: `	To develop the deliberative platform through well designed research oriented programs i.e. Fundamentals of Research Workshops, Panel Discussions on pertinent themes, Law Symposium, etc.
      `,
        },
        {
          id: 5,
          list: `	To establish the Journal of Army Law College, Pune (JALCP) and student dedicated Journal Tattva-Vedh as a thoughtful platform for research scholars and academia, both at national and international level. 
      `,
        },
        {
          id: 6,
          list: `	To develop a movement of reading, democratic deliberation, articulated expression and creating the pool of research-database.
      `,
        },
      ],
    },

    duties: {
      title: " Duties  Of Research Cell Members",
      data: [
        {
          id: 1,
          list: `	The Research Cell member shall strive to sensitize peer group and students about reading, research and allied initiatives in the interest of large student base.
        `,
        },
        {
          id: 2,
          list: "	The Research Cell members shall dedicate themselves to promote the motto and execute the mission objectives of Cell.",
        },
        {
          id: 3,
          list: "The Research Cell member shall preserve, protect and promote the research integrity, ethical research practices and culture.",
        },
        {
          id: 4,
          list: "The Research Cell member shall regularly attend and contribute in Cell meetings. ",
        },
        {
          id: 5,
          list: " The Research Cell member shall undertake given tasks and report the updates to Student Convener and Faculty Coordinator in timely manner.",
        },
        {
          id: 6,
          list: "The Research Cell member shall assist other academic committees for the collaborative efforts and organisation of any academic program.  ",
        },
      ],
    },

    activity: {
      title: " Terms Of Membership",
      data: [
        {
          id: 1,
          list: `The convener, co-convener and members shall be selected purely on merit basis as per institutionally set parameters.
        `,
        },
        {
          id: 2,
          list: `The interview based on set assessment grounds shall decide the merit and suitability of candidate.
      `,
        },
        {
          id: 3,
          list: `The basic eligibility for the post of Convener and Co-convener: Student shall have experience of at least one academic year as either Convener, Co-convener, member of any academic committee/ cell/ society. 
    `,
        },
        {
          id: 4,
          list: `A student shall not have any repeated antecedents of disciplinary action taken by respective academic committee/ cell/ society or college.
  `,
        },
        {
          id: 5,
          list: `The tenure of selected convener, co-convener and members shall be of one academic year. 
`,
        },
        {
          id: 6,
          list: `	A student re-nomination is permitted.
`,
        },
        {
          id: 7,
          list: `	The assignment of academic position is purely on voluntary application and subsequent institutional assessment basis. The volunteering/ membership service of student is meant for academic enrichment hence not paid either in cash or kind.  
`,
        },
        {
          id: 8,
          list: `	The Certificate of appreciation shall be granted to student member/ volunteer at the end of academic year.
`,
        },
        {
          id: 9,
          list: `The selection and membership of student can be cancelled or suspended by Faculty Coordinator or Principal or any other authority subject to any disciplinary action/ violation of code of conduct.
`,
        },
      ],
    },
    composition: {
      title: "Composition",
      content: `
       `,
      data: [
        { id: 1, list: "Convener:  Ms. Anjali Devi (BBA LLB V) " },
        { id: 2, list: " Co-Convener: Ms. Niharika Sinha (BBA LLB V)" },
      ],
    },
  },

  ADR: {
    title: "ALTERNATIVE DISPUTE REDRESSAL CELL – ADR CELL",
    imageUrl: img3,
    bgColor: "#F1A95E ",
    about: {
      title: "About",
      content: ` ADR Cell of Army Law College, Pune, is a statutory cell which was founded in 2018, under Moot Court Society and it subsequently got merge with Legal Aid Cell and finally, after 4 years of emergence, Cell has got its own wing and became a separate cell. ADRC is working with a vision to promote the understanding of various form of ADR and familiarizing the students with the intricacies of Alternative Dispute Resolution. The ADRC strives to foster an ADR culture amongst the students, believing that the emergence of ADR will reduce the burden on the Indian Judiciary. The Cell promotes the ADR culture amongst the students by organising various workshops and lectures conducted by experts; interviews for students wishing to pursue their career in this field and hosting the ADR competitions and prepare students for such competition at National and International Level. The ADR Cell also hosted the first edition of ADR Intra College Quiz Competition aimed at not only informing but instilling a perspective, an attitude and aptitude for resolving disputes among the students and inculcating real life dispute resolution mechanism in the participants. Further, under ADR Cell, students of Vth Year had participated in International Mediation Tournament hosted by INADR, Bologna, Italy and secured 19th position  `,
    },
    object: {
      title: "Object",
      content: ` `,
      data: [
        {
          id: 1,
          list: "To keep the spirit alive on the campus by organizing various Arbitration, Mediation and Client Counselling competitions at inter college, National and International Level.",
        },
        {
          id: 2,
          list: "To provide platform for the students to create a professional skills culture by training them in Dispute resolution.",
        },
        {
          id: 3,
          list: "To assist students in understanding real-life arbitration proceedings, means and methods of calling the parties to Mediation and conciliation Forum and understanding the ground rules.",
        },
        {
          id: 4,
          list: "To assist students in learning the art of convincing and mediating by resolving the Parties issue, by out of Court settlements or by entering into Compromises. ",
        },
        {
          id: 5,
          list: "To assist the students in their drafting skills and research skills.",
        },
      ],
    },

    duties: {
      title: "Duties & Responsibilities",
      data: [
        {
          id: 1,
          list: "The ADR Cell shall be responsible for all intra and inter collegiate Competitions.   ",
        },
        {
          id: 2,
          list: "To plan and schedule Intra College Mediation Tournament Competitions for the academic year. (Tentative dates to be included in the academic calendar of the institute.) ",
        },
        {
          id: 3,
          list: " To plan and schedule trials for Inter College ADR Competitions.",
        },
        {
          id: 4,
          list: "To assess the students during trials with respect to their mediating, research and communication skills and further train them. ",
        },
        {
          id: 5,
          list: "To conduct Induction for new students and teach them the basics of ADR.",
        },
        {
          id: 6,
          list: " To prepare budget for all ADR related events and take necessary steps for its approval.",
        },
        {
          id: 7,
          list: "The coordinator of the committee shall conduct a meeting of the committee from time to time to discuss and delegate task. ",
        },
        {
          id: 8,
          list: "To maintain year-wise compendium of ADR events and submit the same to the respected authorities for record keeping.",
        },
        {
          id: 9,
          list: "Any other duties the Principal / Registrar may assign.",
        },
      ],
    },
    contact: {
      title: "Contact",
      coordinators: [
        {
          img: tejaswini,
          name: "MS. TEJASWINI DEWANGAN (COORDINATOR)",
          destiny: "",
          email: " tejaswini.alc2022@gmail.com",
        },
      ],
    },
  },

  GreennClub: {
    title: "GREEN CLUB",
    imageUrl: defimg,
    bgColor: "#B3C69B",
    about: {
      title: "About",
      content: ` Climate change is one of the critical challenges of the times we live in. Every component of the environment, including the water cycle, rising heat levels, biodiversity, and soil is affected by environmental degradation and these changes, in turn, impact our living, food, health, employment and almost every aspect of our life. Hence, these changing times call for coordinated interventions to combat and face climate change. Young people are playing and going to play a crucial role in addressing climate change globally, nationally, and at their local level. Maharashtra is the third most climate-vulnerable state in India after Assam and Andhra Pradesh. The Green Club is a partnership of the Government of Maharashtra and UNISEF with educational institutions for joint environmental initiatives aimed at building the capacity of youth as future leaders and driving forces behind a new climate change regime, where college students can diversify their careers and abilities by learning new skills whilst tackling environmental and climate change issues
      `,
    },
    object: {
      title: "Object",
      content: `The Green Club (GC) was established to provide the values of environmental stewardship among the students. It aims to work towards an eco-friendly environment in and around colleges and education institutions by efficient use of resources like water, waste, energy and circularity. The formation of Green Clubs primarily focuses on coordinating with youth in universities and colleges to support environmental activities and projects within colleges and communities as extension services. Under this program, the aim is to empower students to participate in and take up meaningful environmental activities and projects. Green Clubs would be imbibed in college-level curricular activities to ensure effective implementation and act as a platform to develop sustainable lifestyles, knowledge, and leadership skills.

      `,
      data: [],
    },
    contact: {
      title: "Contact",
      coordinators: [
        {
          img: gajanan,
          name: "Dr. Gajanan Patil  Faculty Co-ordinator ",
          destiny: "",
          email: " patilgv30@gmail.com",
        },
      ],
    },
  },
};
