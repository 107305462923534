import React, { useState } from 'react';
import img1 from '../../../assests/images/Award/20231012_183902 - vaishnavi D.jpg';
import img2 from '../../../assests/images/Award/20240102_131509 - Divya Kanwar.jpg';
import img3 from '../../../assests/images/Award/318a5118-efa3-4f9f-90a2-aedfb45ea61c - Parinaaz Sood (1).jpeg';
import img4 from '../../../assests/images/Award/IMG_20230708_223926 - Nikhil Kumar Pathak.png';
import img5 from '../../../assests/images/Award/IMG-20220525-WA0001 - Swarn Keshav.jpg';
import img6 from '../../../assests/images/Award/b1afdf71-cac5-4842-8314-e4e6c5a6f9c8 - Yogesh Sonawane.jpeg';
import img7 from '../../../assests/images/Award/IMG-20231022-WA0007 - Nidhi Martand.jpg';
import img8 from '../../../assests/images/Award/IMG_0073 - Divya Taneja.jpeg';
import img9 from '../../../assests/images/Award/IMG_20231014_185108 - Komal.jpg';
import img10 from '../../../assests/images/Award/IMG_20240110_181803 - Isha.jpg';
import img11 from '../../../assests/images/Award/Kaagaz_20240107_225645849926-1 - Neerja Choudhuri.jpg';
import img12 from '../../../assests/images/Award/Vineeta.jpg.HEIC';
import img13 from '../../../assests/images/Award/Screenshot_2024-01-02-12-17-26-129_com.whatsapp - Amit Kumar.jpg';
import img14 from '../../../assests/images/Award/all - Chandana Prakash.jpg';
import img15 from '../../../assests/images/Award/IMG-20231009-WA0019 - Atanu Chakraborty.jpg'
import strip from '../../../assests/images/Award/Group 143.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';



export default function Award() {
    const images = [
        {
            img: img1,
            name: "Vaishnavi D ",
            position: "Participation ",
            awardyear: "4th KLA National ADR(virtual) competiton, 2023"
        },
        {
            img: img2,
            name: "Divya Kanwar",
            position: "3rd",
            awardyear: "Khelo mp youth games 2023"
            
        },
        {
            img: img3,
            name: "Parinaaz Sood",
            position: "Participant (both)",
            awardyear: "International Mediation Tournament, Italy 2. International Mediation Competition, Goa"
        },
        {
            img: img4,
            name: "Nikhil Kumar Pathak   ",
            position: "Top 75 ",
            awardyear: "Justice Depak Mishra National Call for Papers 2023"
        },
        {
            img: img5,
            name: "SWARN KESHAV",
            position: "A Grade ",
            awardyear: "ATC CAMP and NCC 'B' Certificate "
        },
        
        {
            img: img7,
            name: "Nidhi Martand",
            position: "2nd ",
            awardyear: "Zonals- Basketball (women), 2023"
        },
        {
            img: img8,
            name: "Divya Taneja",
            position: "Best researcher,",
            awardyear: "SRM NATIONAL MOOT COURT COMEPTITION"
        },
        {
            img: img9,
            name: "Komal",
            position: "3rd position ",
            awardyear: "Turncourt competition "
        },
        {
            img: img10,
            name: "Isha",
            position: "High Commendation ",
            awardyear: "1st Intra MUN"
        },
        {
            img: img11,
            name: "Neerja Choudhuri",
            position: "",
            awardyear: "International workshop on Space Policy and Roundtable on Indian Space Policy"
        },
        // {
        //     img: img12,
        //     name: "Vineeta Wadhwa",
        //     position: "B Grade",
        //     awardyear: "-"
        // },
        {
            img: img13,
            name: "Amit Kumar",
            position: "Quarter Finalist ",
            awardyear: "9th Kashibai Navale National Moot Court Competition,2023-Singhad Law College,Pune"
        },
        {
            img: img14,
            name: "Chandana Prakash",
            position: "Presentation ",
            awardyear: "Research Paper Presentation"
        },
        {
            img:img15,
            name:"Atanu chakraborty",
            position:'Best paper presentation on space law ',
            awardyear:'Maharashtra national law, University symposium '
        }
    ]

    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    return (
        <div className='awardsection container my-5'>
            <h2 className='titles'>Student Awards & Recognitions</h2>
            <div className='award mt-5'>
                <Swiper
                    modules={[Autoplay, Pagination]}
                    spaceBetween={50}
                    slidesPerView={3}
                    pagination={{ clickable: true }}
                    onSwiper={setSwiper}
                    autoplay={{delay:3000}}
                    loop={true}
                    onSlideChange={(swiper) => handleSlideChange(swiper)}
                    breakpoints={{
                        320: { slidesPerView: 1, spaceBetween: 20 },
                        425: { slidesPerView: 1, spaceBetween: 20 },
                        540:{slidesPerView: 2, spaceBetween: 20 },
                        999: { slidesPerView: 3, spaceBetween: 20 }
                    }}

                >
                    {images.map((img, index) => (
                        <SwiperSlide key={index}>
                            <div className='mt-5 d-flex justify-content-center flex-column align-items-center award-card'>
                                <div className='position-relative'>
                                    <img src={strip} alt='' className='img-fluid strip' />
                                    <img alt='' src={img.img} className='img-fluid ' />     
                                </div>
                                <div className='holder'>
                                    <p className='name'>{img.name}</p>
                                    <p className='position'>{img.position}</p>
                                    <p className='awardyear'>{img.awardyear}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
