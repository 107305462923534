import React from 'react'
import Bannr from '../components/staff/Bannr'
import Staff from '../components/staff/Staff'
// import Visiting from '../components/staff/Visiting'

export default function StaffPage() {
    return (
        <div>
            <Bannr />
            <Staff />
            {/* <Visiting /> */}
        </div>
    )
}
