import React from 'react';
import img1 from '../../assests/images/downloadforms/Certificate 1_page-0001.jpg';
import img2 from '../../assests/images/downloadforms/Certificate 2_page-0001.jpg';
import img3 from '../../assests/images/downloadforms/Cerificate 3_page-0001.jpg';
import img4 from '../../assests/images/downloadforms/Cerificate 4_page-0001.jpg';
import img5 from '../../assests/images/downloadforms/Address Cer_page-0001.jpg';
import img6 from '../../assests/images/downloadforms/Medical Certificate_page-0001.jpg';
import pdf1 from '../../assests/images/pdf/Certificate 1.pdf';
import pdf2 from '../../assests/images/pdf/Certificate 2.pdf';
import pdf3 from '../../assests/images/pdf/Cerificate 3.pdf';
import pdf4 from '../../assests/images/pdf/Cerificate 4.pdf';
import pdf5 from '../../assests/images/pdf/Address Cer.pdf';
import pdf6 from '../../assests/images/pdf/Medical Certificate.pdf';

export default function Forms() {
  const FormsData = [
    {
      id: 1,
      img: img1,
      name: 'Click here for Download',
      link: pdf1
    },
    {
      id: 2,
      img: img2,
      name: 'Click here for Download',
      link: pdf2
    },
    {
      id: 3,
      img: img3,
      name: 'Click here for Download',
      link: pdf3
    },
    {
      id: 4,
      img: img4,
      name: 'Click here for Download',
      link: pdf4
    },
    {
      id: 5,
      img: img5,
      name: 'Click here for Download',
      link: pdf5
    },
    {
      id: 6,
      img: img6,
      name: ' Click here for Download',
      link: pdf6
    },
    // {
    //   id: 7,
    //   img: img6,
    //   name: 'Medical Fitness',
    //   link: pdf6
    // }
  ]


  return (
    <div id='forms'>
      <div className=' mt-5 d-flex flex-wrap container'>
        {FormsData.map((data, i) => (
          <div className='dformdiv prosimg m-2 ' key={i}>
            <img src={data.img} alt='' className='img-fluid p-2' />
            <div className='overlay'>
              <a href={data.link} download={`${data.name}.pdf` } className='text-decoration-none'>
                <p className='overlay-text'>{data.name}</p>
              </a>
            </div>
          </div>
        ))
        }
      </div>
    </div>
  )
}
