
import React, { useState , useEffect} from 'react';
import Prospectous from '../components/admission/Prospectous';
import Procedure from '../components/admission/Procedure';
import Notices from '../components/admission/Notices';
import Forms from '../components/admission/Forms';
import Faq from '../components/admission/Faq';
import {  useParams } from 'react-router-dom';

export default function Admissions() {
    const {tab} = useParams();
    const [activeTab, setActiveTab] = useState('prospectous');
   
    console.log(tab)
    useEffect(() => {
        if (tab) {
            setActiveTab(tab);
        }
    }, [tab]);

    const handleClick = (tab) => {
        setActiveTab(tab);
    };
  
    return (
        <div>
            <div className='aboutbg'>
                <div className='about-banner'>
                    <h2 className='title'>ADMISSIONS</h2>
                </div>
            </div>
            <div className='col-12 mt-5 container '>
                <div className='d-flex admbtn-div flex-wrap '>
                    <div className={`adm-btn ${activeTab === 'prospectous' ? 'activeTab' : ''}`} onClick={() => handleClick('prospectous')} >Download Prospectus</div>
                    <div className={`adm-btn ${activeTab === 'procedure' ? 'activeTab' : ''}`} onClick={() => handleClick('procedure')}>Admission Procedure</div>
                    <div className={`adm-btn ${activeTab === 'notices' ? 'activeTab' : ''}`} onClick={() => handleClick('notices')}>Admission Notices</div>
                    <div className={`adm-btn ${activeTab === 'forms' ? 'activeTab' : ''}`} onClick={() => handleClick('forms')} >Download Forms</div>
                    <div className={`adm-btn ${activeTab === 'faq' ? 'activeTab' : ''}`} onClick={() => handleClick('faq')}>Admissions FAQ'S</div>
                </div>
                <div className='btns-div mt-4'></div>
            </div>
            
            {activeTab === 'prospectous' && (
                <Prospectous />
            )}
            {activeTab === 'procedure' && (
                <Procedure />
            )}
            {activeTab === 'notices' && (
                <Notices />
            )}
            {activeTab === 'forms' && (
                <Forms />
            )}
            {activeTab === 'faq' && (
                <Faq />
            )}
        </div>
    )
}
