import React from "react";
import Banner from "../components/home/carousel/Banner";
import Award from "../components/home/carousel/Award";
import About from "../components/home/About";
import Map from "../components/home/Map";
import Contact from "../components/home/Contact";
import ImportantLinks from "../components/home/ImportantLinks";
import Visitors from "../components/home/Visitors";
import Testimonials from "../components/home/Testimonials";
import ENA from "../components/home/ENA";
import chatboat from "../assests/images/others/Group 62.png";
import RTI from "../components/home/RTI";
import Chatbot from "../components/chatbot/chatbot";

export default function HomePage() {
  return (
    <div>
      <Banner />
      <ENA />
      <About />
      <Award />
      <Visitors />
      <Testimonials />
      <ImportantLinks />
      {/* <RTI /> */}
      <Contact />
      <Map />
      <Chatbot />
    </div>
  );
}
