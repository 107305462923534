import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ArchiveData, coursesData } from "../../AddmissionData";

export default function Notices() {
  return (
    <div className="container" id="notices">
      <div className="mt-5">
        <div className="mt-5 d-flex flex-wrap justify-content-center">
          {coursesData.map((data, i) => (
            <div className="formdiv prosimg m-2 position-relative" key={i}>
              <img src={data.img} alt="" className="img-fluid p-2" />
              <div className="overlay">
                <a
                  href={data.link}
                  download={`${data.name}`}
                  className="text-decoration-none"
                >
                  <p className="overlay-text">
                    <small className=" p-2">{data.text}</small>
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex mt-5">
          <div className="col-lg-2 col-sm-3">
            <div className="archive">
              ARCHIVE <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div className="d-flex flex-column col-lg-9 col-sm-9 mx-2">
            {ArchiveData.map((data, i) => (
              <p className="progtext arc-sidetext p-3 mt-0" key={i}>
                {data.text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
