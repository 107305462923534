import React from "react";
import img1 from "../../../assests/images/infrastructure/19- Play Grounds/basketball 1.jpg";
import img2 from "../../../assests/images/infrastructure/19- Play Grounds/Batminton 1.jpg";
import img3 from "../../../assests/images/infrastructure/19- Play Grounds/Girls Hostel Common Room.jpg";
import img4 from "../../../assests/images/infrastructure/19- Play Grounds/tt table.jpg";
import img5 from "../../../assests/images/infrastructure/19- Play Grounds/IMG_2983.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

export default function Sports() {
  const audiimg = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
  ];
  return (
    <div className="container mt-4 sai">
      <p className="progsubhead">Sport & Gymnasium:</p>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          426: {
            slidesPerView: 2,
          },
        }}
      >
        {audiimg.map((img, i) => (
          <SwiperSlide key={i}>
            <img src={img.img} alt="" className="img-fluid swiperimg" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
