import React from 'react'
import Detail from '../components/infrastructure/Detail'
import Banner from '../components/infrastructure/Banner'
import Academic from '../components/infrastructure/siteArea/Academic'
import GHostel from '../components/infrastructure/siteArea/GHostel'
import BHostel from '../components/infrastructure/siteArea/BHostel'
import Campus from '../components/infrastructure/siteArea/Campus'
import Saiaudi from '../components/infrastructure/carousel/Saiaudi'
import Classrooms from '../components/infrastructure/carousel/Classrooms'
import Mootcourt from '../components/infrastructure/carousel/Mootcourt'
import Sports from '../components/infrastructure/carousel/Sports'

export default function Infrastructure() {
    return (
        <div>
            <Banner />
            <Detail />
            <Academic />
            <GHostel />
            <BHostel />
            <Saiaudi />
            <Classrooms />
            <Mootcourt />
            <Sports />
            <div className='container'>
                <p className='progsubhead p-3'>Campus Tour:</p>
                <Campus />
            </div>
        </div>
    )
}
