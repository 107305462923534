import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBars, faTimes, } from '@fortawesome/free-solid-svg-icons';
import point from '../../assests/images/others/point (1).png';
import { RulesData, RenewalData, BookReturn, FacultyData } from '../../LibraryData';

export default function Rules() {
   

   
    return (
        <div >
            
               
                    <h2 className='progsubhead'>General Library Rules</h2>
                    {
                        RulesData.map((rule, i) => (
                            <ul className='mt-4'>

                                <li className='d-flex'>
                                    <img src={point} alt='' className='hand img-fluid col-1' /><p className='px-4 aboutp mb-0 '>{rule.rule}</p>
                                </li>
                            </ul>
                        ))
                    }
                    <div className='mt-5'>
                        <h2 className='progsubhead'>Circulation Issue Systems</h2>
                        <p className='progtext'>Books will be issued on presentation of the library card along with the ID card. Students are instructed to check the books while borrowing and they will be responsible for any type of damage or mutilation noticed at the time of return.</p>
                    </div>
                    <h2 className='progsubhead'>Renewal</h2>
                    {
                        RenewalData.map((rule, i) => (
                            <ul className='mt-4'>

                                <li className='d-flex'>
                                    <img src={point} alt='' className='hand img-fluid col-1' /><p className='px-4 aboutp mb-0 '>{rule.rule}</p>
                                </li>
                            </ul>
                        ))
                    }

                    <div className='tables mt-5'>
                        <table className="Lcustom-table col-12 ">
                            <p className='tableName'>Student</p>
                            <tbody>
                            <tr className='lthead'>
                                <td className="col-3">Sr No</td>
                                <td className="col-3">Student Type</td>
                                <td className="col-3">Text Book</td>
                                <td className="col-3">Duration</td>
                            </tr>
                                {
                                    BookReturn.map((data, i) => (
                                        <tr key={i}>
                                            <td className="col-3">{data.id}</td>
                                            <td className="col-3">{data.type}</td>
                                            <td className="col-3">{data.book}</td>
                                            <td className="col-3">{data.duration}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='tables mt-5'>
                        <table className="Lcustom-table col-12 ">
                            <p className='tableName'>Faculty</p>
                            <tbody>
                            <tr className='lthead'>
                                <td className="col-1">Sr No</td>
                                <td className="col-4">ALC TEAM TYPE</td>
                                <td className="col-2 text-center">Text Book</td>
                                <td className="col-1">Duration</td>
                                <td className="col-3 text-center">ReferenceBook</td>
                                <td className="col-1">Duration</td>
                            </tr>
                                {
                                    FacultyData.map((data, i) => (
                                        <tr key={i}>
                                            <td className="col-1">{data.id}</td>
                                            <td className="col-4">{data.type}</td>
                                            <td className="col-2 text-center">{data.book}</td>
                                            <td className="col-1">{data.duration}</td>
                                            <td className="col-3 text-center">{data.refbook}</td>
                                            <td className="col-1">{data.duration2}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-5'>
                        <h2 className='progsubhead'>Library Membership</h2>
                        <p className='progtext'>Students and faculty members are allowed into the library only on production of their authorized/valid Identity Cards.</p>
                    </div>
                    <div className='mt-5'>
                        <h2 className='progsubhead'>Library Timing</h2>
                        <p className='tableName mb-0'>Reading Room Timing</p>
                        <p className='progtext mt-0'>Monday To Saturday: 9:00 Am To 11:00 Pm (Closed On Sunday And Holidays)</p>
                        <p className='tableName mb-0'>Issue/Return</p>
                        <p className='progtext mt-0'>Monday To Saturday: 9:00 Am To 7:00 Pm (Closed On Sunday And Holidays)</p>
                    </div>
                </div>
    )
}
