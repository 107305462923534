import React from 'react';
import phone from '../../assests/images/Contact/telephone-call.png';
import email from '../../assests/images/Contact/email.png';
import gps from '../../assests/images/Contact/gps.png';

export default function Contact() {
    return (
        <div className='contact'>
            <div className='container'>
                <h2>Contact Us</h2>
                <div className='d-flex mt-4  contactDiv flex-wrap '>
                    <div className='col-lg-6 col-md-5 col-sm-12 p-2'>
                        <div className='d-flex maindiv flex-wrap'>
                            <div className=' col-lg-6 col-12 p-2  '>
                              <div className='subdiv py-4 px-2 text-center'>  <img src={phone} alt='' />
                                <h2>Phone</h2>
                                <div className=' mt-3'>
                                    <p><span>Principal :</span>02114 297336</p>
                                    <p><span>Registrar :</span>02114 297338 <br /><p>(M:+91 9168513509)</p></p>
                                    <p><span>Office Superintendent :</span> +91 7767927701</p>
                                    <p><span>Office Tel :</span>02114 297339</p>
                                </div></div>
                            </div>
                            <div className=' col-lg-6 col-12 p-2 '>
                              <div className='subdiv py-4 px-2 text-center'>
                              <img src={email} alt='' />
                                <h2>E-Mail</h2>
                                <div className='mt-3'>
                                    {/* <p>info@alcpune.com</p> */}
                                    <p>admission.alcpune@gmail .com</p>
                                    <p>armylawcollegepune@gmail .com</p>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 p-2'>
                        <div className=' subdiv py-4 px-2 text-center'>
                            <img src={gps} alt='' />
                            <h2>Address</h2>
                            <div className='phonedetail mt-3'>
                                <p> Army Law College, Pune (Kanhe), 
                                    <br></br>
                                    GAT No. 182, 183, 184  <br></br>
                                    Near Old Pune- Mumbai National Highway NH- 04,
                                </p>
                                <p>Tal: Mawal, Pune- 412106 </p>
                                <p>(Maharashtra), India.</p>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                   <div className='col-lg-6 col-md-6 col-sm-12 p-4 maindiv'>
                   <div className='  subdiv form'>
                        <h2>Your Message</h2>
                        <div className='py-2'>
                            <div className="form-group mb-5">
                                <input type="text" className="form-control custom-input" id="name" placeholder="Enter name" required />
                            </div>
                            <div className="form-group mb-5">
                                <input type="email" className="form-control custom-input" id="email" placeholder="Enter email" required />
                            </div>
                            <div className="form-group mb-5">
                                <input type="text" className="form-control custom-input" id="subject" placeholder="Enter subject" required />
                            </div>
                            <div className="form-group mb-5">
                                <input type="textarea" className="form-control custom-input" id="message" placeholder="Enter message" required />
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='send'>Send</button>
                        </div>
                    </div>
                   </div>
                </div>
            </div>
        </div>
    )
}
