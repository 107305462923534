import React from 'react'
import Album from '../components/alumni/Album'
import EventJob from '../components/alumni/EventJob'
import Coordinator from '../components/alumni/Coordinator'
import Achievement from '../components/alumni/Achievement'

export default function AlumniPage() {
    return (
        <div>
           <div className='Alumni-bg'>
           <div className=' about-banner'>
                <h2 className='title'>Alumni</h2>
            </div>
           </div>
            <div className='progtext container mt-5'>
                <p>The bonding of students with ALC is unique and is long lasting one throughout the life of the student. The Alumni Association is an institution which cherishes this bond even after students end their academic association with the college after securing their degree. The association encourages the past students to contribute to college by taking several initiatives such as Annual Alumni Meet, inviting the Alumni as Resource person for various courses and guest lectures. The Alumni also contributes in return by providing training support through internships and placements to the present students of the College. They provide moral and monetary support by organizing various field visits and programs like Alumni Lecture Series, Participation in Lok Adalat's etc. The Alumni association also help the students in their career by regularly posting opportunities for the Alumni members. </p>
            </div>
            <Album />
            <Achievement/>
            <EventJob/>
            <Coordinator/>
        </div>
    )
}
