import React from 'react';
import Contact from "../components/home/Contact";
import Map from '../components/home/Map';

export default function ContactPage() {
  return (
    <div className='contactpage'>
      <Contact/>
      <Map/>
    </div>
  )
}
