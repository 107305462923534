import React from 'react'

export default function GrevienceForm() {
  return (
<div className='mt-5'>
<p className='titles text-center'>Redressal of Grievances (Students)</p>
    <div className=' grev-sub mt-2 d-flex justify-content-center'>
      
      <div className='col-lg-6 col-sm-12'>
                        <div className='grev-form'>
                            <button className='grev-btn'>Register Complaint</button>
                            <div className='py-4 w-100'>
                                <div className="form-group mb-2">
                                    <input type="text" className="form-control custom-input" id="name" placeholder=" Name" required />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="text" className="form-control custom-input" id="class" placeholder="Class" required />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="text" className="form-control custom-input" id="hostel" placeholder="Hostel" required />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="email" className="form-control custom-input" id="email" placeholder="Email-id" required />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="number" className="form-control custom-input" id="mobile" placeholder="Mobile no" required />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="text" className="form-control custom-input" id="subject" placeholder="Subject" required />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="textarea" className="form-control custom-input" id="message" placeholder="Grievance" required />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button className='send'>Submit</button>
                            </div>
                        </div>
                    </div>
    </div>
</div>
  )
}
