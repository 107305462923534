import React from "react";
import { Link } from "react-router-dom";
import pdf1 from "../../assests/images/pdf/quest indica.pdf";
import pdf2 from "../../assests/images/pdf/BBA& BA LL.B Advertisement.pdf";
import pdf3 from "../../assests/images/others/FDP.pdf";
import pdf4 from "../../assests/images/others/annNotice2.pdf";
import pdf5 from "../../assests/images/others/annNotice3.pdf";
import pdf6 from "../../assests/images/others/Workshop on Financial Planning by Dr. Ravi Ahuja - 03-07-2024.png";
import pdf7 from "../../assests/images/others/Celebration of National Librarian’s Day, 2024.pdf";
import pdf8 from "../../assests/images/others/revoluation justice workshot section 1.jpg";
import pdf9 from "../../assests/images/others/revoluation workshop justice section2.jpg";
import pdf10 from "../../assests/images/others/Revoluation justic workshop section 3.jpg";
import { Badge } from "react-bootstrap";
export default function Announcement() {
  const AnnounceData = [
    {
      id: 1,
      title: "BBA & BA LL.B Advertisement",
      link: pdf1,
      //   date: "01 Feb 2024",
    },
    {
      id: 2,
      title: "BBA & BA LL.B Advertisement",
      link: pdf2,
      //   date: "01 Feb 2024",
    },
    {
      id: 3,
      title:
        "FDP- Faculty Development Program (Hybrid Mode) 17 May to 23 May, 2024",
      link: pdf3,
      //   date: "01 Feb 2024",
    },
    {
      id: 4,
      title:
        "Symposium on Pathways to Success for Aspiring Lawyers in the Contemporary Era",
      link: pdf4,
      //   date: "01 Feb 2024",
    },
    {
      id: 5,
      title: "War & Peace Simulation Competition, 2024",
      link: pdf5,
      //   date: "01 Feb 2024",
    },
    {
      id: 6,
      title: "Workshop on Financial Planning by Dr. Ravi Ahuja - 03 July 2024",
      link: pdf6,
      //   date: "01 Feb 2024",
    },
    {
      id: 7,
      title: "Celebration of National Librarian’s Day, 2024",
      link: pdf7,
      //   date: "01 Feb 2024",
    },
    {
      id: 8,
      title: "Revoluation justic workshop section 1",
      link: pdf8,
      //   date: "01 Feb 2024",
    },
    {
      id: 9,
      title: "Revoluation justic workshop section 2",
      link: pdf9,
      //   date: "01 Feb 2024",
    },
    {
      id: 10,
      title: "Revoluation justic workshop section 3",
      link: pdf10,
      //   date: "01 Feb 2024",
    },
  ];
  AnnounceData.sort((a, b) => b.id - a.id);

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      {AnnounceData.map((data, i) => (
        <div className="d-flex align-items-center flex-wrap " key={i}>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <p className="ntdate">{data.date}</p>
            <a
              href={data.link}
              onClick={() => handleDownload(data.link)}
              className="linerm"
              target="_blank"
            >
              <p className="ntname progtext m-0">
                {data.title}
                {i < 2 && (
                <span className="mx-1">
                  <Badge bg="danger">New</Badge>
                </span>
              )}
              </p>
            </a>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12">
            <p
              className="event-btn"
              style={{ cursor: "pointer" }}
              onClick={() => handleDownload(data.link)}
            >
              <span className="view-btn">View</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
