import React from 'react';
import img1 from '../../assests/images/library/IMG_0114.jpg';
import img2 from '../../assests/images/library/IMG_0116.jpg';
import img3 from '../../assests/images/library/IMG_0119 (1).jpg';
import img4 from '../../assests/images/library/IMG_0123 (1).jpg';
import DigitalL from './DigitalL';
import WebL from './WebL';
export default function TakshilaL() {
    return (
        <div>
            <h2 className='progsubhead'> About Takshashila Library </h2>
            <p className='progtext'>The library, a repository of information, is a feature of every university. That's the simplest explanation. Is that all, though? The value of a library can only be understood by someone who understands what it means to enjoy reading.

                One of these  "Takshashila" information repositories is located at Army Law College. "Takshashila" was one of the biggest universities  in ancient India which was established in 700 BC. It used to hold a collection of 1800+ titles while 9 million books were burned but now it only holds 63,501 volumes of books.

                Similarly, our Library Takshashila possesses approximately 6500 plus books, 30 Print Journals  and Magazines, and 5 (Five) types of E-Resources (Manupatra, AIR Online, SCC Online, LexisNexis, and N-list), Reference books (Labour Law Journal, AIR manual, and SSC Report/Manual) and therefore, the students don’t have to purchase any books from outside stores.

                Our library also provides OPAC (Online Public Access Catalog) which will help  Patrons to use the online library cataloging software to find out any item under the control of the library. It displays the query search results in an easy-to-understand format to enable patrons to find digital material quickly.

                A spacious  reading room is over 1700 sq, the facilities are available  to the students from 9 AM. to 11 PM., and the reading room can accommodate more than 120 students in one go.

                . </p>
            <div className='col-lg-12 col-sm-12 col-md-12 d-flex flex-wrap'>
                <div className='col-6 p-2 l-img'>
                    <img src={img1} alt='' className='img-fluid' />
                </div>
                <div className='col-6 p-2 l-img'>
                    <img src={img2} alt='' className='img-fluid ' />
                </div>
                <div className='col-6 p-2 l-img'>
                    <img src={img3} alt='' className='img-fluid' />
                </div>
                <div className='col-6 p-2 l-img'>
                    <img src={img4} alt='' className='img-fluid' />
                </div>
            </div>
            <p className='progtext'>A specific room designated for lab work, with 10 computers with the latest software and Wi- Fi connected. Takshashila Library manages information resources, both in print and digital formats, ensures seamless discovery and access to these scholarly resources. Library plays the most important role in any law college as it acts as the primary source of knowledge for law students. It offers access to textbooks law reporters, reference books, journals, bare acts and audio- visual aids too. In addition to reference material, a law library acts as a place where young minds introspect, meet and discuss the intertwining of law and society Takshashila Library provides its students the infrastructure and services for their intellectual enhancement. It is reading room capacity of 120. Takshashila Library is automated with AutoLib NG Library Management software is User- friendly and advanced integrated library automation management software. In its information retrieval is simple, fast and efficient. It provides high standard tools and techniques to manage library daily transactions and also provides various reports of library operations. Bar- code technology is being used for circulation of books and periodicals. Takshashila Library is equipped with WEB OPAC which can be accessed by students and faculty by scanning the QR Code through laptops and smart phones from anywhere. Takshashila Library has USER TRACKING SYSTEM to register every entry of user which helps to keep track on the user usage of library and statistics of particular user.</p>
            <DigitalL />
            <WebL />
        </div>
    )
}
