import React from 'react';
import vision from '../../assests/images/others/vision.png';
import mission from '../../assests/images/others/mission.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight } from '@fortawesome/free-regular-svg-icons';
import point from '../../assests/images/others/point (1).png';
export default function Vision() {
    return (
        <div className='d-flex container vision justify-content-between flex-wrap'>
            <div className='col-lg-4'>
                <div className='d-flex align-items-center'>
                    <img src={vision} alt='' className='img-fluid' />
                    <span className='vmtitle px-4'>VISION</span>
                </div>
                <p className='mt-4 aboutp'>“To create employable, practice-ready and ethical lawyers equipped with knowledge, skills, values and global competence”</p>
            </div>
            <div className='col-lg-7'>
                <div className='d-flex align-items-center'>
                    <img src={mission} alt='' className='img-fluid ' />
                    <span className='vmtitle px-4'>MISSION</span>
                </div>
                <ul className='mt-4'>
                    <li className='d-flex'>
                        <img src={point} alt='' className='hand img-fluid' /><p className='px-4 aboutp'>Impart world-class quality legal education, especially, to the children of our brave, patriotic & selfless Army Personnel.</p>
                    </li>
                    <li className='d-flex'>
                        <img src={point} alt='' className='hand img-fluid' /><p className='px-4 aboutp'>Inculcate universal humanitarian values in our budding lawyers to make them responsible global citizens and sentinels of justice, equality & fraternity</p>
                    </li>
                    <li className='d-flex'>
                        <img src={point} alt='' className='hand img-fluid' /><p className='px-4 aboutp'>Create unique law professionals who will be inclined to serve the motherland & the humanity by strengthening the justice delivery system and protecting the civil liberties of fellow citizens.</p>
                    </li>
                    <li className='d-flex'>
                        <img src={point} alt='' className='hand img-fluid' /><p className='px-4 aboutp'>Enable our students to face the paradoxical challenges posed by globalization & reverse globalization, rapid industrialization & environmental degradation, digital revolution & privacy issues, technological revolution & unemployment, large scale inter- country migration & Geo- political conflicts, intellectual property & community interests and evolving medical science & ethical dilemmas.
</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}
