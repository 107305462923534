import React from 'react'

export default function Bannr() {
    return (
            <div className='staff-bg'>
      <div className='about-banner'>
        <h2 className='title'>ALC TEAM</h2>
      </div>
    </div>

    )
}
