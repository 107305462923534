import React from 'react';
import { QAssuranceData } from '../../SocietiesCellData';

export default function QAssurance() {
    return (
        <div className='qassurance py-5'>
             <h3 className='prog-title '>Internal Quality Assurance Cell (IQAC)</h3>
            <div class="tables  mt-3 container" >
                <table className="custom-table container">
                    <thead>
                        <tr className='rtihead'>
                            <td className="col-1">No</td>
                            <td className="col-3">Name</td>
                            <td className="col-2">Designation</td>
                            <td className="col-2">Member Type</td>
                            <td className='col-2'>Contact No.</td>
                            <td className='col-3'>Email-Id</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            QAssuranceData.map((data, i) => (
                                <tr key={i}>
                                    <td className="col-1">{data.id}</td>
                                    <td className="col-3">{data.name}</td>
                                    <td className="col-2">{data.designation}</td>
                                    <td className='col-2'>{data.type}</td>
                                    <td className='col-2'>{data.contact}</td>
                                    <td className='col-3'>{data.email}</td>
                                </tr>
                            ))
                        }
                    </tbody>
            </table>
        </div>
        </div >
    )
}
