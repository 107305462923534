import React from 'react';
import img1 from '../../../assests/images/infrastructure/16- Sai Audi/img1.jpg';
import img2 from '../../../assests/images/infrastructure/16- Sai Audi/img2.jpg';
import img3 from '../../../assests/images/infrastructure/16- Sai Audi/img3.jpeg';
import img4 from '../../../assests/images/infrastructure/16- Sai Audi/img4.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';





export default function Saiaudi() {
    const audiimg = [
        { img: img1 },
        { img: img2 },
        { img: img3 },
        { img: img4 },
    ]
    return (
        <div className='container mt-4 sai'>
            <p className='progsubhead'>Sai Auditorium:</p>
            <Swiper
             modules={[Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{ delay: 3000 }}
                breakpoints={{
                    426: {
                        slidesPerView: 2
                    }
                }}
            >
                {
                    audiimg.map((img, i) => (
                        <SwiperSlide key={i}>
                            <img src={img.img} alt='' className='img-fluid swiperimg' />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}
