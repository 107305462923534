import React from "react";
import aboutimg from "../../assests/images/others/about1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCaretRight } from "@fortawesome/free-solid-svg-icons";
// import chatboat from '../../assests/images/others/Group 62.png'
import Campus from "../infrastructure/siteArea/Campus";

export default function About() {
  return (
    <div className="container mt-5">
      <div className="abouta">
        <h2 className="abouta-title">ABOUT ARMY LAW COLLEGE, PUNE</h2>
        <p className="mt-4 aboutp">
          Army Law College, Pune was established in July 2018 with a noble
          objective of imparting quality legal education to the children of our
          brave, patriotic & selfless Army personnel. The College is governed
          and managed by Army Welfare Education Society (AWES), which is non-
          profit organization, registered under the Societies Registration Act
          XXI of 1860, since 29 April 1983. The Army Law College is the most
          recent professional college to be established in Pune under AWES. The
          location of the college is serene and ideal for educational institute.
          ALC is well connected by national highway and local train.
        </p>
        <p className="mt-4 aboutp">
          {" "}
          Army Law College, Pune is affiliated to “Savitribai Phule Pune
          University” (SPPU) which is among the top state universities with
          consistent NAAC A+ grade. Army Law College, Pune is approved by the
          Bar Council of India and Government of Maharashtra. It offers five-
          years integrated B.B.A, LL. B & B.A. LL.B. programs each with a
          student intake of 60 seats (exclusively for children of Army
          personnel).
        </p>
        <p className="mt-4 aboutp">
          {" "}
          The institution originated with the donation of initial campus and
          buildings by Dr. Prem Daryanani, Managing Trustee of Radha Kalyandas
          Daryanani Charitable Trust, has now developed into a 14-acer
          educational hub with the state-of-the-art academic infrastructure.
          Ample facilities for sports, hobbies and extracurricular activities
          exists on the campus for holistic development of the students. The
          College also has NSS & NCC sub-units.
        </p>
      </div>
      <div className="d-flex justify-content-center flex-wrap ">
        <div className="col-lg-6 col-12 position-relative">
          <Campus />
        </div>

        <div className="d-flex  justify-content-around abouta-sub">
          <div className="datadiv abouta-datadiv">
            <p className="count">280+</p>
            <p className="dataname">Students</p>
          </div>
          <div className="datadiv">
            <p className="count">15+</p>
            <p className="dataname">Professors</p>
          </div>
          <div className="datadiv">
            <p className="count">35%</p>
            <p className="dataname">Placed</p>
          </div>
        </div>
        {/* <div className='d-flex align-items-center col-3 justify-content-end'>
                    <img src={chatboat} alt='' className='img-fluid ' />
                </div> */}
      </div>
      <div>
        <p className="mt-4 aboutp">
          It has a fully residential campus, located in the midst of nature,
          tucked away from the hustle and bustle of Pune life. It is just 45 Kms
          from Pune on Old Pune-Mumbai Highway and nearest railway station Kanhe
          is just 1.5 Kms. It takes about 10 minutes to travel to the nearest
          Taluka Court, 1 hour to the Pune District Court and 2 hours to Bombay
          High Court by Road.
        </p>
        <p className="mt-4 aboutp">
          The college is located near the Automobile Manufacturing Hub at
          Chakan-Talegaon and Pimpri-Chinchwad belt. It is advantageous for
          effectively teaching Management Subjects of B.B.A by way of developing
          linkages with manufacturing companies for industrial visits,
          internships and inviting industry-experts for training the students.{" "}
        </p>
        <p className="mt-4 aboutp">
          Dr.Prem Daryanani, Managing Trustee of Radha Kaliandas Daryanani
          Charitable Trust, has donated six acres of land and 12 buildings at
          Kanheto the Indian Army for the Army Law College (ALC) that is being
          developed in a phased manner since July 2017. A foundation stone for
          the commencement of Phase-II infrastructure development of Army Law
          College was laid by Lieutenant General SK Saini General Officer
          Commanding-in-Chief, Southern Command in Pune on 9th September, 2019.
          The new Hostels with modern amenities are existing on the campus for
          100% Students. New Academic Block & Auditorium with 500 seating
          capacity are likely to be available in 2021-22.
        </p>
      </div>
    </div>
  );
}
