import React from 'react';
import mail from '../../assests/images/Contact/email.png';
import coimg from '../../assests/images/teaching/Mr. Prashant Vanshiv - Librarian.png';
import feedback from '../../assests/images/others/Feedback Form.png';


export default function Survey() {
    return (
        <div >
            <div className='col-lg-6 col-sm-10 col-md-9 mt-4'>
                <p className='progsubhead'>Ask a Librarian</p>
                <div className='col-md-6 col-sm-6 col-lg-8'>
                    <div className='teamcard' >
                        <img src={coimg} className="card-img-top img-fluid" alt="Card Image" />
                    </div>
                    <div className="card-body px-1 py-2 ">
                        <h5 className='teamname'>Mr. Prashant Vanshiv</h5>
                        <p className='destiny '>College Librarian</p>
                    </div>
                </div>
                <div className=' progtext'>
                    <img src={mail} alt=' ' className='img-fluid col-1' />
                    <span className='col-10'>  librarian.alc2018@gmail.com</span>
                </div>
            </div>
            <div className='mt-5'>
                <p className='progsubhead'>Library Feedback Form/ Survey Link :</p>
                <p className='d-flex flex-wrap justify-content-center align-items-center' onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdXNVBiUzRkvHSdt5li3qoQlIoybCqWFGk6CIE_nwOiawi0NQ/viewform", "_blank")}>
                    <a className='col-12 link ' href="https://docs.google.com/forms/d/e/1FAIpQLSdXNVBiUzRkvHSdt5li3qoQlIoybCqWFGk6CIE_nwOiawi0NQ/viewform" target='_blank'>https://docs.google.com/forms /d/e/1FAIpQL SdXNVBiUzRkvHSdt5li3 qoQlIoybCqWFGk6CIE_ nwOiawi0NQ/viewform</a>
                </p>
            </div>
            <div className='col-12 px-5 '>
            <div className='scanner col-lg-5 col-sm-10 col-md-5 p-3'>
                    <div className='scan p-3'>
                    <p className='m-0 col-lg-7 col-sm-12 col-md-9'>Scan for Library Feedback Form</p>
                    <img src={feedback} alt='' className='img-fluid p-2' />
                    </div>
                </div>
            </div>
        </div>
    )
}
