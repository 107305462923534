import React from 'react';
import img1 from '../../assests/images/facilities/GMC21112023_150245.png';

export default function MServices() {
  return (
    <div className='container'>
       <div className='d-flex flex-wrap mt-5 '>
                <div className='col-lg-7'>
                    <div className='fdlarge '>
                        <img src={img1} alt='' className='img-fluid' />
                    </div>
                </div>
                <div className='col-lg-5 col-sm-12'>
                    <p className='progsubhead'>Medical Services</p>
                    <p className='infrasub'> There is health centre in the campus near hostel. At present there is one Nursing Assistant Ms. Nitu Pathare (Nursing Assistant) In case of any illness, students are expected to report to the health centre. We have all emergency medicines and injections, though students are expected to buy the full course of medicines from outside. Dressing for minor injuries are also done. In case a student requires a specialist reference, they are guided accordingly. There is a recovery room in the campus health centre where students with communicable diseases like chicken pox, who require isolation can stay till they are under treatment. Students recovering from any surgery are also kept in the recovery till they are fit to shift to their hostel room. In case of any emergency any time medical assistance available on phone. During night hours if a student require hospitalization vehicle is made available..</p>
                </div>
            </div>
    </div>
  )
}
