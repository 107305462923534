import React from 'react';
import { sem10Data , ElectiveSem10Data} from '../../../SemData2';

export default function Sem10() {
    const totalL = sem10Data.reduce((acc, curr) => acc + curr.L, 0);
    const totalT = sem10Data.reduce((acc, curr) => acc + curr.T, 0);
    const totalP = sem10Data.reduce((acc, curr) => acc + curr.P, 0);
    const totalCredit = sem10Data.reduce((acc, curr) => acc + curr.credit, 0);
    const totalInternal = sem10Data.reduce((acc, curr) => acc + curr.internal, 0);
    const totalExternal = sem10Data.reduce((acc, curr) => acc + curr.external, 0);
    const totalTotal = sem10Data.reduce((acc, curr) => acc + curr.total, 0);
    const electiveTotalL = ElectiveSem10Data.reduce((acc, curr) => acc + curr.L, 0);
    const electiveTotalT = ElectiveSem10Data.reduce((acc, curr) => acc + curr.T, 0);
    const electiveTotalP = ElectiveSem10Data.reduce((acc, curr) => acc + curr.P, 0);
    const electiveTotalCredit = ElectiveSem10Data.reduce((acc, curr) => acc + curr.credit, 0);
    const electiveTotalInternal = ElectiveSem10Data.reduce((acc, curr) => acc + curr.internal, 0);
    const electiveTotalExternal = ElectiveSem10Data.reduce((acc, curr) => acc + curr.external, 0);
    const electiveTotalTotal = ElectiveSem10Data.reduce((acc, curr) => acc + curr.total, 0);

    const combinedTotalL = totalL + electiveTotalL;
    const combinedTotalT = totalT + electiveTotalT;
    const combinedTotalP = totalP + electiveTotalP;
    const combinedTotalCredit = totalCredit + electiveTotalCredit;
    const combinedTotalInternal = totalInternal + electiveTotalInternal;
    const combinedTotalExternal = totalExternal + electiveTotalExternal;
    const combinedTotalTotal = totalTotal + electiveTotalTotal;
  console.log(combinedTotalTotal)
    return (
        <div className='d-flex flex-column justify-content-center container mt-5'>
            <p className='sem'>SEMESTER X</p>
            <div class="tables mt-3">
                <table className="custom-table">
                    <thead className='tablehead'>
                        <tr>
                            <th className="col-1">No</th>
                            <th className="col-2">Course Code</th>
                            <th className="col-3">Course</th>
                            <th className='col-2'>Activity
                                <div className='d-flex justify-content-center'>
                                    <th className='col-4'>L</th>
                                    <th className='col-4'>T</th>
                                    <th className='col-4'>P</th>
                                </div></th>
                            <th className='col-1'>Credit</th>
                            <th className='col-3'>Evaluation Scheme
                                <div className='d-flex justify-content-center'>
                                    <th className='col-4'>Internal</th>
                                    <th className='col-4'>External</th>
                                    <th className='col-4'>Total</th>
                                </div></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sem10Data.map((data, i) => (
                                <tr key={i}>
                                    <td className='text-center col-1' >{data.id}</td>
                                    <td className='text-center col-2'>{data.code}</td>
                                    <td className='text-left col-3'>{data.subject}</td>
                                    <td className='col-2 '>
                                        <div className='d-flex justify-content-center'>
                                            <td className='col-4 text-center'>{data.L}</td>
                                            <td className='col-4 text-center'>{data.T}</td>
                                            <td className='col-4 text-center'>{data.P}</td>
                                        </div>
                                    </td>
                                    <td className='text-center col-1'>{data.credit} </td>
                                    <td className='col-2 '>
                                        <div className='d-flex justify-content-center'>
                                            <td className='col-4 text-center'>{data.internal}</td>
                                            <td className='col-4 text-center'>{data.external}</td>
                                            <td className='col-4 text-center'>{data.total}</td>
                                        </div>
                                    </td>
                                    
                                </tr>
                            ))
                        }
                        <tr className='table-name-row'>
                            <td colSpan="7" className='text-center'>Law Discipline Specific Elective Course (Any One from the following)</td>
                        </tr>
                        { ElectiveSem10Data.map((data, i) => (
                                <tr key={i}>
                                <td className='text-center col-1' >{data.id}</td>
                                <td className='text-center col-2'>{data.code}</td>
                                <td className='text-left col-3'>{data.subject}</td>
                                <td className='col-2 '>
                                    <div className='d-flex justify-content-center'>
                                        <td className='col-4 text-center'>{ data.L}</td>
                                        <td className='col-4 text-center'>{ data.T}</td>
                                        <td className='col-4 text-center'>{ data.P}</td>
                                    </div>
                                </td>
                                <td className='text-center col-1'>{data.credit} </td>
                                <td className='col-2 '>
                                    <div className='d-flex justify-content-center'>
                                        <td className='col-4 text-center'>{ data.internal}</td>
                                        <td className='col-4 text-center'>{ data.external}</td>
                                        <td className='col-4 text-center'>{data.total}</td>
                                    </div>
                                </td> 
                                
                            </tr>
                        ))

                        }
                    </tbody>
                    <tfoot >
                        <tr>
                            <td></td>
                            <td></td>
                            <td className='text-left'>Total</td>
                            <td className='col-2'>
                                <div className='d-flex justify-content-center'>
                                    <td className='col-4 text-center'>{combinedTotalL}</td>
                                    <td className='col-4 text-center'>{combinedTotalT}</td>
                                    <td className='col-4 text-center'>{combinedTotalP}</td>
                                </div>
                            </td>
                            <td className='col-1 text-center'>{combinedTotalCredit}</td>
                            <td className='col-3'>
                                <div className='d-flex justify-content-center'>
                                    <td className='col-4  text-center'>{combinedTotalInternal}</td>
                                    <td className='col-4  text-center'>{combinedTotalExternal}</td>
                                    <td className='col-4  text-center'>{combinedTotalTotal}</td>
                                </div>
                            </td>
                        </tr>

                    </tfoot>
                </table>
            </div>
        </div>
    )
}
