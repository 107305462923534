import React from "react";
import eventicon from "../../../assests/images/others/Group 1777.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import img1 from "../../../assests/images/events/2023-24/GUEST LECTUREON SELF IMPROVEMENT/Picture29.jpg";
import img2 from "../../../assests/images/events/2023-24/SESSION ON PERSONAL BRANDING FOR PROFESSIONAL GROWTH/Picture27.jpg";
import img3 from "../../../assests/images/events/2023-24/EXPERT LECTURE ON LAW RELATING TO BALLISTICS/Picture25.jpg";
import { Link } from "react-router-dom";
export default function Event() {
  const eventData = [
    {
      img: img1,
      content: {
        title:
          "Guest Lecture on Self-Improvement",
        date: " 14th Oct 2024",
        detail:
          "A  motivating Lecture on “SELF IMPROVEMENT” was organised by the Internship & Placement Cell  ...",
      },
    },
    {
      img: img2,
      content: {
        title:
          "Session on Personal Branding for Professional Growth",
        date: " 5th Oct 2024",
        detail:
          "A special session on Personal Branding for Professional Growth was conducted by the Internship and Placement Cell...",
      },
    },
    
    {
      img: img3,
      content: {
        title:
          "Expert Lecture on Law Relating to Ballistics",
        date: "09th Sept 2024",
        detail: "A guest Lecture for the students of B.B.A..LL.B. on “Law relating to Ballistics” was conducted on 30.09.24...",
      },
    },

 
  ];
  return (
    <div>
      <div className="headerdiv1">
        <img src={eventicon} alt="" className="img-fluid" />
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h2>Events</h2>
          <Link to="/events" className="linerm">
            {" "}
            <p className="mb-0">
              All Events{" "}
              <span>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div className="event">
        {eventData.map((event, index) => (
          <div
            className="event-content d-flex px-2 py-3 justify-content-between"
            key={index}
          >
            <div className="col-5 p-2">
              <img src={event.img} alt="" className="img-fluid" />
            </div>
            <div className="col-7">
              <h2 className="event-title">{event.content.title}</h2>
              <p className="event-date">{event.content.date}</p>
              <p className="event-detail">{event.content.detail}</p>
              {/* <p className='event-btn'>Read more</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
