import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBars, faTimes, } from '@fortawesome/free-solid-svg-icons';
import img1 from '../../assests/images/library/aironline-logo.png';
import img2 from '../../assests/images/library/logo (7).png';
import img3 from '../../assests/images/library/logo (8).png';
import img4 from '../../assests/images/library/scc-logo.png';
import img5 from '../../assests/images/library/heinonline-logo-login.png';
export default function Databases() {

    return (
        <div>
            <h2 className='progsubhead mb-3' style={{color:'#A5353A'}}></h2>
            <div className='col-3 my-3'>
                <img src={img1} alt='' className='img-fluid' />
            </div>
            <p className='tableName'>AIR ONLINE</p>
            <p className='progtext '>Air Online portal is self- learning and will further mature with each progressing day. The thrust is on making the treasure of AIR available on a unified platform with Artificial Intelligence capabilities. The new portal is interactive, has smart search engine, mobile friendly design, advanced data analytics, well organized President Memoirs, AIR (True) Print Facility, Case Notes, Lawyers Dairy, Prompt updates, daily notifications of case law & more. </p>
            {/* <ul className='database-list'>
                        <li>Citation Search:  </li>
                        <li>   Nominal Search:</li>
                        <li> Judgement Date:</li>
                        <li>  Bench: </li>
                        <li>  Court: </li>
                        <li> Judge:</li>
                        <li>Topical Search:</li>
                        <li> Act and Section Search:</li>
                        <li> Lawyer:</li>

                    </ul> */}
            <div className='col-3 my-3 mt-5'>
                <img src={img2} alt='' className='img-fluid' />
            </div>
            <p className='tableName'>MANUPATRA</p>
            <p className='progtext'>All the related materials in the database have been hyperlinked so that the user does not spend time searching for the referred or related documents. Thus, at the click of the button, user can go to the case cited, legislation It provides an access to complete archive and current updates of the full text of decisions delivered by the Supreme Court and all High Courts of India across all subjects of law from 1844 or year of inception of the court, whichever is later. Orders of the Tribunals are also covered. Manupatra database makes research faster, easier and precise.
                Referred or respective amending document. The database tracks Amendments across Bare Acts and Notifications, thus making available the current position of each document and further reducing the research time of the user.
            </p>
            {/* <p className='progtext'> Referred or respective amending document. The database tracks Amendments across Bare Acts and Notifications, thus making available the current position of each document and further reducing the research time of the user. </p>
                    <p className='progtext'>Each Judgment contains, where applicable, details about:</p>
                    <ul className='database-list'>
                        <li>Bench of Judges </li>
                        <li> Multiple Parallel Citations to over 280+ journals</li>
                        <li>  A digest of the case which covers the Subject, Catchwords, Case Note of the Judgment</li>
                        <li> Citing Reference </li>
                        <li> Ratio Decidendi and Authorities referred  </li>
                        <li> Cases Referred</li>
                        <li>Cases Overruled / Reversed Status </li>
                        <li> Acts, Rules Orders Cited</li>
                        <p className='progtext'>The section of the Acts referred in Judgments are hyperlinked. The user has the option of reading the Bare Act or Commentary or view the Digest.</p>
                        <li>   Legislation Database</li>
                    </ul> */}
            {/* <p className='progtext'>  A comprehensive repository of Central Bare Acts, 10000+ State Bare Acts and a collection of Rules and Regulations.</p>
            <p className='progtext'>Note: Link: https://www.manupatrafast.com/ Go to the Website https://www.manupatrafast.com/</p> */}
            <div className='col-3 my-3 mt-5'>
                <img src={img3} alt='' className='img-fluid' />
            </div>
            <p className='tableName'>N-LIST</p>
            <p className='progtext'>The N- LIST project provides access to e- resources to students, researchers and faculty from colleges and other beneficiary institutions through server(s) installed at the INFLIBNET Centre. Link: https://nlist.inflibnet.ac.in Systematic download of e- resources content is Strictly Prohibited. Terms and Condition for access to e- resources is available at https://nlist.inflibnet. ac.in/vlicence.php</p>
            <div className='col-3 my-3 mt-5'>
                <img src={img4} alt='' className='img-fluid' />
            </div>
            <p className='tableName'>SCC</p>
            <p className='progtext'>SCC Online is brought to you by Eastern Book Company - a publishing house of international repute and an acknowledged leader in the field of law publishing in India for more than 75 years.
                With a list of over 550 authoritative and well-known titles, it is well recognised for its contribution to legal literature worldwide.  Reliable and comprehensive law reporting of the Supreme Court of India has been achieved through EBC's weekly law report since 1969.
            </p>
            <div className='col-3 my-3 mt-5'>
                <img src={img5} alt='' className='img-fluid' />
            </div>
            <p className='tableName'>HEINONLINE</p>
            <p className='progtext'>HeinOnline is a premier online research platform that provides more than 204 million pages of multidisciplinary periodicals, essential government documents, international resources, case law, and much more. Composed of fully searchable image- based PDFs and available at an affordable price, the wealth of material allows academic institutions, government agencies, law firms, court systems, corporations, and other organizations access to authoritative, true- to- print digital material without the hassle or cost of using multiple research databases.
                Every document across HeinOnline’s 100+ databases is an exact facsimile of its print counterpart, providing access to original, authoritative sources as well as the ease and speed that accompanies online searching. To improve the discoverability of relevant content, we’ve also incorporated unique artificial intelligence tools and research aids throughout our various databases.
                .</p>

        </div>
    )
}
