import React from 'react'
import { Link } from 'react-router-dom';
import codeimg from '../../assests/images/others/code_Conduct_img.png';

export default function CodeofConduct() {
    return (
        <div className='container d-flex justify-content-center flex-column align-items-center mt-5'>
            <p className='titles'>Army Law College Code Of Conduct</p>
            <div className=' mt-3 '>
                <div className='formdiv prosimg m-2'>
                    <Link to='/codeconduct'>
                        <img src={codeimg} alt='' className='img-fluid p-2' />
                    </Link>
                </div>
            </div>
        </div>
    )
}
