import React from "react";
import img1 from "../../../assests/images/others/1.jpg";
import img2 from "../../../assests/images/others/2.jpg";
import img3 from "../../../assests/images/others/3.jpg";
import img4 from "../../../assests/images/others/4.jpg";
import img5 from "../../../assests/images/others/5.jpg";
import img6 from "../../../assests/images/others/6.jpg";
// import img7 from "../../../assests/images/others/7.jpg";
// import img8 from "../../../assests/images/others/8.jpg";
import img9 from "../../../assests/images/others/9.jpg";
import img10 from "../../../assests/images/others/10.jpg";
import img11 from "../../../assests/images/others/11.jpg";
import img12 from "../../../assests/images/others/12.jpg";
import img13 from "../../../assests/images/others/13.jpg";
import line from "../../../assests/images/others/line.svg";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faUser } from "@fortawesome/free-regular-svg-icons";

export default function Banner() {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    // img7,
    // img8,
    img9,
    img10,
    img11,
    img12,
    img13,
  ];
  return (
    <div className="banner">
      <div className="banner position-relative ">
        <div>
          <div className=" erp1">
            <a href="https://admissionalc.edupluscampus.com/" target="_blank">
              <button className=" d-flex align-items-center ">
                <FontAwesomeIcon icon={faCircleUser} className="me-2" />
                <span>
                  <small>Admission Login</small>
                </span>
              </button>
            </a>
          </div>
          {/* <div className=" erp ">
            <a href="https://admissionalc.edupluscampus.com/" target="_blank">
              <button className=" d-flex align-items-center ">
                <FontAwesomeIcon icon={faCircleUser} className="me-2" />
                <span><small>ERP Login</small></span>
              </button>
            </a>
          </div> */}

          {/* <div className='line-text position-absolute'>
                    <p>Title Goes Here</p>
                </div> */}
          {/* <img src={line} alt='' className='img-fluid line'/> */}
        </div>
        <Carousel>
          {images.map((img, i) => (
            <Carousel.Item key={i}>
              <div className="bannerimg">
                <img src={img} alt="" className="img-fluid" />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
