export const QAssuranceData = [
  {
    id: 1,
    name: "Mr. Ganeshappa Deshmane",
    designation: "Chairperson",
    type: "Management Representative",
    contact: "9764691545",
    email: "principal.alc@awesindia.edu.in",
  },
  {
    id: 2,
    name: "Dr. Ajit Borde",
    designation: "Member",
    type: "Senior Teacher",
    contact: "9860222199",
    email: "ajitb.alc@gmail.com",
  },
  {
    id: 3,
    name: "Dr. Hrishikesh Honkalas",
    designation: "Member",
    type: "Co-ordinator (IQAC)",
    contact: "9730326292",
    email: "dr.hrishikeshhonkalas.alc@gmail.com",
  },
  {
    id: 4,
    name: "Dr. Kranti Deshmukh",
    designation: "Member",
    type: "Academic External Expert",
    contact: "9922418912",
    email: "kranti.deshmukh@rediffmail.com",
  },
  {
    id: 5,
    name: "Adv. Dr. Swapnil Bengali",
    designation: "Member",
    type: "Industry Expert",
    contact: "9172529978",
    email: "swapnilgangali@gmail.com",
  },
  {
    id: 6,
    name: "Col. A K Pandey (Retd)",
    designation: "Member",
    type: "Registrar",
    contact: "9168513509",
    email: "registrar.alc@awesindia.edu.in",
  },
  {
    id: 7,
    name: "Mr. Sudam Jadhao",
    designation: "Member",
    type: "Administrative",
    contact: "7875378477",
    email: "sudam26m@gmail.com",
  },
  {
    id: 8,
    name: "Ms. Ananya Pandey",
    designation: "Member",
    type: "Alumni",
    contact: "9596361492",
    email: "ananyapandeyy99@gmail.com",
  },
];

export const RedressalCData = [
  {
    id: 1,
    name: "Col. A K Pandey (Retd)",
    designation: "Chairman",
    type: "Chairperson",
    contact: "9168513509",
    email: "registrar.alc@awesindia.edu.in",
  },
  {
    id: 2,
    name: "Mr. Ganeshappa Deshmane",
    designation: "Member",
    type: "Member 1",
    contact: "9764691545",
    email: "principal.alc@awesindia.edu.in",
  },
  {
    id: 3,
    name: "Ms. Anurita Yadav",
    designation: "Member",
    type: "Member 2",
    contact: "9205166365",
    email: "anuritayadav.alc@gmail.com",
  },
  {
    id: 4,
    name: "Dr. Madhushree Joshi",
    designation: "Chairperson",
    type: "Appointment",
    contact: "9767265327",
    email: "principal.alc@awesindia.edu.in"
  },
  {
    id: 5,
    name: "Ms. Tejaswini Dewangan",
    designation: "Faculty Member",
    type: "Member",
    contact: "7723014446",
    email: "tejaswini.lnmu2010@gmail.com"
  },
  {
    id: 6,
    name: "Mr. Aryan Gaur",
    designation: "Student Representative",
    type: "Student Representative",
    contact: "9819070072",
    email: "aryan.gaur17@gmail.com"
  },
  {
    id: 7,
    name: "Adv. Rajendra Uma",
    designation: "External Member",
    type: "Member",
    contact: "9822069465",
    email: "advrajendrauma@gmail.com"
  },
  {
    id: 8,
    name: "Mr. Sudam Jadhav",
    designation: "Secretary",
    type: "Member",
    contact: "7875378477",
    email: "sudam26m@gmail.com"
  }
];

export const OpportunityCData = [
  {
    id: 1,
    name: "Dr. Madhushree Joshi",
    designation: "Principal",
    type: "Chairman",
    contact: "9767256327",
    email: "dr.madhushreejoshi.alcpune@gmail.com",
  },
  {
    id: 2,
    name: "Col. A K. Pandey",
    designation: "Registrar",
    type: "Secretary",
    contact: "9168513509",
    email: "registrar.alc@awesindia.edu.in",
  },
  {
    id: 3,
    name: "Mr. Ganeshappa Deshmane",
    designation: "Assistant Professor (Law)",
    type: "Teacher Representative (Male)",
    contact: "9764691545",
    email: "ganesh.alc2021@gmail.com",
  },
  {
    id: 4,
    name: "Ms. Anurita Yadav",
    designation: "Assistant Professor (Law)",
    type: "Teacher Representative (Female)",
    contact: "9205166365",
    email: "anuritayadav.alc@gmail.com",
  },
  {
    id: 5,
    name: "Mrs. Anagha Joshi",
    designation: "Counsellor",
    type: "Counsellor",
    contact: "9011291122",
    email: "anaghaanilj@gmail.com",
  },
  {
    id: 6,
    name: "Dr. Swapnil Bangali",
    designation: "Expert Member",
    type: "Expert Member",
    contact: "9172529978",
    email: "swapnilbangali@gmail.com",
  },
  {
    id: 7,
    name: "Ms. Kavita Kumari",
    designation: "5th Year Student",
    type: "Student Representative",
    contact: "9306051055",
    email: "sheorankabita@gmail.com",
  },
  {
    id: 8,
    name: "Mr. Amarnath Prasad",
    designation: "5th Year Student",
    type: "Student Representative",
    contact: "8219142543",
    email: "amarnathprasad633@gmail.com",
  },
  {
    id: 9,
    name: "Mr. Devendra Singh Bisht",
    designation: "Warden",
    type: "Parent Representative",
    contact: "7017135425",
    email: "devbisht3351@gmail.com",
  },
  {
    id: 10,
    name: "Subedar R. P. Singh",
    designation: "Subedar",
    type: "Parent Representative",
    contact: "8437238612",
    email: "rana1978.r2@gmail.com",
  },
];

export const DivyangCData = [
  {
    id: 1,
    name: "Mr. Ganeshappa Deshmane",
    designation: "Chairman",
    type: "Principal",
    contact: "9764691545",
    email: "principal.alc@awesindia.edu.in",
  },
  {
    id: 2,
    name: "Dr. Ajit Borde",
    designation: "Vice-Chairman",
    type: "Vice-Principal",
    contact: "9860222199",
    email: "ajitb.alc@gmail.com",
  },
  {
    id: 3,
    name: "Dr. Hrishikesh Honkalas",
    designation: "Member-Secretary",
    type: "Student Develop-ment Officer",
    contact: "9730326292",
    email: "dr.hrishikeshhonkalas.alc@gmail.com",
  },
  {
    id: 4,
    name: "Mr. Ajay R. Tambulkar",
    designation: "Member",
    type: "Teacher Represen- tative (Male)",
    contact: "9823244244",
    email: "ajay.armylawcollege@gmail.com",
  },
  {
    id: 5,
    name: "Ms. Anurita Yadav",
    designation: "Member",
    type: "Teacher Represen- tative(Female)",
    contact: "9205166365",
    email: "anuritayadav.alc@gmail.com",
  },
  {
    id: 6,
    name: "Dr. Saurabh Uabale",
    designation: "Member",
    type: "Expert Advisor-1",
    contact: "9921277798",
    email: "ubale.saurabh@gmail.com",
  },
  {
    id: 7,
    name: "Mr. Gajanan Patil",
    designation: "Member",
    type: "Expert Advisor-2",
    contact: "9850065275",
    email: "patilgv30.alc@gmail.com",
  },
  {
    id: 8,
    name: "Mr. Lakshmi U S",
    designation: "Member",
    type: "Student Representative 1",
    contact: "8090410714",
    email: "lakshmius2020@gmail.com",
  },
  {
    id: 9,
    name: "Mr. Ankit Aman",
    designation: "Member",
    type: "Student Representative 2",
    contact: "7347543408",
    email: "ankitaman696@gmail.com",
  },
  {
    id: 10,
    name: "Mr. Satosh Bhor",
    designation: "Member",
    type: "Parent Representative 1",
    contact: "8485082751",
    email: "santoshbhor1976@gmail.com",
  },
  {
    id: 11,
    name: "Mr. Devendra Bisht",
    designation: "Member",
    type: "Parent Representative 2",
    contact: "7017135425",
    email: "devbisht3351@gmail.com",
  },
];

export const sexualHarresmentCData = [
  {
    id: 1,
    name: "Ms. Anurita Yadav",
    designation: "Chairman",
    type: "Chairperson",
    contact: "9205166365",
    email: "anuritayadav.alc@gmail.com",
  },
  {
    id: 2,
    name: "Ms. Tejswini Dewangan",
    designation: "Member",
    type: "In-charge",
    contact: "7723014446",
    email: "tejaswini.alc2022@gmail.com",
  },
  {
    id: 3,
    name: "Dr. Hrishikesh Honkalas",
    designation: "Member",
    type: "Co-ordinator (IQAC)",
    contact: "9730326292",
    email: "dr.hrishikeshhonkalas.alc@gmail.com",
  },
  {
    id: 4,
    name: "Mr. Ganeshappa Deshmane",
    designation: "Member",
    type: "Member 1",
    contact: "9764691545",
    email: "principal.alc@awesindia.edu.in",
  },
  {
    id: 5,
    name: "Col. A K Pandey (Retd)",
    designation: "Member",
    type: "Member 2",
    contact: "9168513509",
    email: "registrar.alc@awesindia.edu.in",
  },
  {
    id: 6,
    name: "Adv. Savita Shedge",
    designation: "Member",
    type: "Member 3",
    contact: "",
    email: "",
  },
];

export const antiRaggingCData = [
  {
    id: 1,
    name: "Mr. Ganeshappa Deshmane",
    designation: "Chairman",
    contact: "9764691545",
    email: "principal.alc@awesindia.edu.in",
  },
  {
    id: 2,
    name: "Col. A K Pandey (Retd)",
    designation: "Member",
    contact: "9168513509",
    email: "registrar.alc@awesindia.edu.in",
  },
  {
    id: 3,
    name: "Dr. Harita S Bangali",
    designation: "Member",
    contact: "9172529978",
    email: "haritanbangali@gmail.com",
  },
  {
    id: 4,
    name: "Mr. Ajay R. Tambulkar",
    designation: "Member",
    contact: "9823244244",
    email: "art23493@gmail.com",
  },
  {
    id: 5,
    name: "Ms. Anurita Yadav",
    designation: "Member",
    contact: "9205166365",
    email: "anuritayadav.alc@gmail.com",
  },
  // {
  //     id: 1,
  //     name: "Mr. Ganeshappa Deshmane",
  //     designation: "ChairMan",
  //     type:'ChairPerson',
  //     contact: "9764691545",
  //     email: "principal.alc@awesindia.edu.in"
  // },
  // {
  //     id: 2,
  //     name: "Col. A K Pandey (Retd)",
  //     designation: "Member",
  //     type:'In-Charge',
  //     contact: "9168513509",
  //     email: "registrar.alc@awesindia.edu.in"
  // },
  // {

  //     id: 3,
  //     name: "Dr. Hrishikesh Honkalas",
  //     designation: "Member",
  //     type:"Co-ordinator (IQAC)",
  //     contact: "9730326292",
  //     email: "dr.hrishikeshhonkalas.alc@gmail.com"
  // },
  // {
  //     id: 4,
  //     name: "Mr. Ajay R. Tambulkar",
  //     designation: "Member",
  //     type:"Member 1",
  //     contact: "9823244244",
  //     email: "art23493@gmail.com"
  // },
  // {
  //     id: 5,
  //     name: "Ms. Anurita Yadav",
  //     designation: "Member",
  //     type:"Member 2",
  //     contact: "9205166365",
  //     email: "anuritayadav.alc@gmail.com"
  // },
  // {
  //     id: 6,
  //     name: "Mr Nagesh Kanki",
  //     designation: "Member",
  //     type:"Member 3",
  //     contact:" 9096123446",
  //     email: "nageshkankialc@gmail.com"
  // }
];

export const DisiplinaryCommitee = [
  {
    id: 1,
    name: "Col. A K Pandey (Retd)",
    designation: "Chairperson",
    contact: "9168513509",
    email: "registrar.alc@awesindia.edu.in",
  },
  {
    id: 1,
    name: "Ms. Anurita Yadav",
    designation: "Member",
    contact: "9205166365",
    email: "anuritayadav.alc@gmail.com",
  },
  {
    id: 3,
    name: "Mr. Ajay R. Tambulkar",
    designation: "Member",
    contact: "9823244244",
    email: "ajay.armylawcollege@gmail.com",
  },
  {
    id: 4,
    name: "Dr. Ajit Borde",
    designation: "Member",
    contact: "9860222199",
    email: "ajitb.alc@gmail.com",
  },

  {
    id: 5,
    name: "Ms. Tanvi Goyal",
    designation: "Member",
    contact: "9993314806",
    email: "tanvigoyal.alc@gmail.com",
  },
  {
    id: 6,
    name: "Mr. Sudam Jadhao",
    designation: "Member",
    contact: "7875378477",
    email: "sudam26m@gmail.com",
  },
  {
    id: 7,
    name: "Mr. D S Bisht",
    designation: "Member",
    contact: "7017135425",
    email: "devbisht3351@gmail.com",
  },
  {
    id: 8,
    name: "Mrs. Amruta Lolge",
    designation: "Member",
    contact: "9325479749",
    email: "amrutalolge0@gmail.com",
  },
];

export const studentCouncilData = [
  {
    id: 1,
    name: "Mr. Ganeshappa Deshmane",
    designation: "Chairman",
    type: "Vice Principal / Senior Teacher",
    contact: "9764691545",
    email: "principal.alc@awesindia.edu.in",
  },
  {
    id: 2,
    name: "Mr Nagesh Kanki",
    designation: "Member",
    type: "Teacher Represen- tative (Male)",
    contact: " 9096123446",
    email: "nageshkankialc@gmail.com",
  },
  {
    id: 3,
    name: "Ms. Anurita Yadav",
    designation: "Member",
    type: "Teacher Represen- tative(Female)",
    contact: "9205166365",
    email: "anuritayadav.alc@gmail.com",
  },
  {
    id: 4,
    name: "Adv. Vinda Mahajan",
    designation: "Member",
    type: "Social worker",
    contact: "9404303209",
    email: "vindamahajan@gmail.com",
  },
  {
    id: 5,
    name: "Dr. Ajit Borde",
    designation: "Member",
    type: "Counsellor",
    contact: "9860222199",
    email: "ajitb.alc@gmail.com",
  },
  {
    id: 6,
    name: "Ms. Deah Kapoor",
    designation: "Member",
    type: "Student Council Representative 1",
    contact: "7087758838",
    email: "deahkapoor4295@gmail.com",
  },
  {
    id: 7,
    name: "Mr. Avinash Kumar",
    designation: "Member",
    type: "Student Council Representative 2",
    contact: "8080515917",
    email: "avinashbngly@gmail.com",
  },
];
