import React from "react";
import star from "../../assests/images/alumni/Group 273.png";
import job1 from "../../assests/images/jobs&tenders/JOBS1.jpg";
import job2 from "../../assests/images/jobs&tenders/JOBS2.jpg";

export default function Job() {
  const jobData = [
    // {
    //   job: "JOB1",
    //   btn: job1,
    // },
    // {
    //   job: "JOB2",
    //   btn: job2,
    // },
  ];
  const handleView = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      <div>
        <div className="headerdiv1 ">
          <img src={star} alt="" className="img-fluid" />
          <h2>JOBS</h2>
        </div>

        <div className="event ">
          {jobData && jobData.length > 0 ? (
            jobData.map((job, index) => (
              <div
                className="px-3 pt-2 d-flex justify-content-between align-items-center"
                key={index}
              >
                <p className="job col-10">{job.job}</p>
                {/* <p className='event-btn col-2'>{job.btn}</p> */}
                <div className="col-lg-2 col-md-3 col-sm-12">
                  <p
                    className="event-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleView(job.btn)}
                  >
                    <span className="view-btn">View</span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="progtext px-3">
              We regret to inform you that the job is currently unavailable. We
              will keep you informed of any updates.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
