import React from 'react';
import img1 from '../../assests/images/library/Digital Library- Lab.jpg'

export default function DigitalL() {
    return (
        <div className='mt-4'>
            <h2 className='progsubhead'> Digital Library</h2>
            <div className='col-12 p-2 dl-img '>
                <img src={img1} alt='' className='img-fluid ' />
            </div>
            <p className='progtext'>The E- library has 10 computers exclusively for students to access legal  databases and other resources subscribed as well open access E- Resources. Library premise  is covered under Wi- fi zone and CCTV system. Additionally, the library provides students with audio-  visual and Xerox facilities. Takshashila Library has best infrastructure which includes wide and attractive entrance and  circulation area, with a good journal and Magazine section and new arrival display area, reorganization of reference and reading halls, digital knowledge lab and special staff work areas. </p>
        </div>
    )
}
