import React from 'react'

export default function Bannr() {
    return (
       <div className='infra-bg'>
         <div className='about-banner '>
            <h2 className='title'>INFRASTRUCTURE</h2>
        </div>
       </div>
    )
}
