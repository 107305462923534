import React from 'react'

export default function Detail() {
  return (
   <div className='d-flex justify-content-center align-items-center  mt-5'>
     <div className='col-lg-8 col-md-10 col-sm-10 infradetail p-3'>
      <p>ALC, Pune Campus has state- of- the- art infrastructure including smart classrooms, 450 seating auditorium, seminar and conference halls, library with reading rooms, computer lab, Wi-Fi enabled campus. The campus has 100 % hostel accommodation for boys and girls, with integrated students mess, indoor recreational facilities and gymnasiums. Amphitheatre, synthetic sports courts for Basketball, Volleyball & Badminton provide excellent facilities for cultural and extracurricular growth of the students. Other campus facilities include a well-equipped Medical Room with ambulance, 100% power backup, 24/7 security with CCTV network, College bus and amenity transport.</p>
    </div>
   </div>
  )
}
