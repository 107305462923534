import React from 'react'
import Event from '../home/Events/Event'
import Job from './Job'

export default function EventJob() {
  return (
    <div className='d-flex container mt-5 flex-wrap justify-content-center'>
     <div className='mb-3 col-lg-6 col-sm-12 col-md-10'>
     <Event/>
     </div>
    <div className='col-lg-6 col-sm-12 col-md-10' >
    <Job/>
    </div>
    </div>
  )
}
