import teamstrip from "../../assests/images/others/teamstrip.svg";
import alcstrip from "../../assests/images/others/alcstrip.svg";
import img1 from "../../assests/images/9- Founder-Management Team/P-   Gen  Manoj Pande.jpg";
import img2 from "../../assests/images/9- Founder-Management Team/Maj Gen Shukla.jpg";
import img3 from "../../assests/images/9- Founder-Management Team/Brig OP Vaishnav.JPG";
import img4 from "../../assests/images/9- Founder-Management Team/kakade sir.jpeg";
import alcimg1 from "../../assests/images/9- Founder-Management Team/A - Lt Gen Dhiraj Seth, AVSM.png";
import alcimg2 from "../../assests/images/9- Founder-Management Team/B- Lt Gen S P Singh, YSM.png";
import alcimg3 from "../../assests/images/9- Founder-Management Team/Maj Gen Viney Handa1.png";
import alcimg4 from "../../assests/images/9- Founder-Management Team/C- Brig Ashish Katoch, SM.jpg";
import alcimg5 from "../../assests/images/9- Founder-Management Team/MG.jpg";
import alcimg7 from "../../assests/images/9- Founder-Management Team/anupam mathur.png";
import alcimg8 from "../../assests/images/teaching/Dr Madhushree .jpg";
import alcimg9 from "../../assests/images/9- Founder-Management Team/col SA warty.jpg";
import dimg from "../../assests/images/others/default-avatar-photo-placeholder-icon-grey-vector-38508163.jpg";
export default function Team() {
  const teamData = [
    {
      img: img1,
      name: "Gen Manoj Pande ",
      destiny: "PVSM, AVSM, VSM, ADC 1st Patron - Now COAS",
    },
    {
      img: img2,
      name: "Maj Gen J K Shukla",
      destiny: "VSM",
    },
    {
      img: img3,
      name: "Brig O P Vaishnao ",
      destiny: "SM, VSM",
    },
    {
      img: img4,
      name: "Brig Anil Kakade ",
      destiny: "Cdr 92 Inf Bde",
    },
  ];
  const alcteamData = [
    {
      post: "PATRON-IN-CHIEF",
      img: alcimg1,
      name: "Lt Gen Dhiraj Seth, AVSM",
      destiny: "GOC-in-C Southern Comd",
    },
    {
      post: "PATRON",
      img: alcimg2,
      name: " Lt Gen S P Singh, YSM",
      destiny: "COS Southern Comd",
    },
    {
      post: "CHAIRMAN",
      img: alcimg3,
      name: "Maj Gen Viney Handa",
      destiny: "MG AAD, Southern Comd",
    },
    {
      post: "DEPUTY CHAIRMAN",
      img: alcimg4,
      name: "Brig Ashish Katoch, SM",
      destiny: "Cdr, 787(I) AD BDE",
    },
    {
      post: "MANAGING DIRECTOR AWES",
      img: alcimg5,
      name: "Maj Gen P R Murali VSM (Retd)",
    },
    {
      post: "DIR COLLEGES, AWES",
      img: alcimg9,
      name: "Col S A Warty (Retd)",
    },
    {
      post: "DIR AWES CELL SC",
      img: alcimg7,
      name: "Col Anupam Mathur (Retd)",
    },
    {
      post: "PRINCIPAL ",
      img: alcimg8,
      name: "Dr Madhushree Joshi",
    },
  ];

  return (
    <div className="team" id="mangTeam">
      <div className="mt-5 py-5 px-3 text-center ">
        {/* <h2 className="abouta-title mb-4">Management Team</h2> */}
        <div>
          <img src={teamstrip} alt="" className="img-fluid" />
          <div className="d-flex p-2 flex-wrap justify-content-center">
            {teamData.map((team, i) => (
              <div key={i} className="teamcarddiv">
                <div className="teamcard">
                  <img
                    src={team.img}
                    className="card-img-top img-fluid"
                    alt="CardImage"
                  />
                </div>
                <div className="card-body py-2 px-3">
                  <h5 className="teamname">{team.name}</h5>
                  <p className="destiny ">{team.destiny}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5">
          <img src={alcstrip} alt="" className="img-fluid" />
          <div className="d-flex  p-2 flex-wrap justify-content-center">
            {alcteamData.map((team, i) => (
              <div key={i}>
                <div>
                  <p className="postname mt-3">{team.post}</p>
                </div>
                <div className="teamcarddiv">
                  <div className="teamcard">
                    <img
                      src={team.img}
                      className="card-img-top img-fluid"
                      alt="Card Image"
                    />
                  </div>
                  <div className="card-body py-2 px-3">
                    <h5 className="teamname">{team.name}</h5>
                    <p className="destiny ">{team.destiny}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
