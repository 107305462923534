import React from "react";

import jouimg1 from "../assests/images/others/journal1.png";
import jouimg2 from "../assests/images/others/journal2.png";
import jouimg3 from "../assests/images/others/journal3.png";
import newsletimg1 from "../assests/images/others/newsletter1.png";
import newsletimg2 from "../assests/images/others/newsletter2.png";
import newsletimg3 from "../assests/images/others/newsletter3.png";
import PlacementBroucherimg3 from "../assests/images/others/PlacementBrochure.png";
import pdf3 from "../assests/images/others/PLACEMENT BROCHURE 2024.pdf";
import pdf21 from "../assests/images/others/ALCP-Bulletin I.pdf";
import pdf22 from "../assests/images/others/ALCP-Bulletin II.pdf";
import pdf23 from "../assests/images/others/ALCP-Bulletin III.pdf";
import pdf11 from "../assests/images/others/ALCP Journal I.pdf";
import pdf12 from "../assests/images/others/ALCP Journal II.pdf";
import pdf13 from "../assests/images/others/ALCP Journal III.pdf";

const ItemList = ({ items }) => {
  const sortedItems = [...items].sort((a, b) => b.id - a.id);
  return (
    <div className="mt-3 mb-5 d-flex justify-content-left">
      {sortedItems.map((item, i) => (
        <div className="formdiv prosimg m-2" key={i}>
          <a href={item.link} download={item.download}>
            <img src={item.img} alt="" className="img-fluid p-2"></img>
          </a>
        </div>
      ))}
    </div>
  );
};

const researchJournalsData = [
  {
    id: 1,
    img: jouimg1,
    link: pdf11,
    download: pdf11,
  },
  {
    id: 2,
    img: jouimg2,
    link: pdf12,
    download: pdf12,
  },
  {
    id: 3,
    img: jouimg3,
    link: pdf13,
    download: pdf13,
  },
];

const newsletterMediaData = [
  {
    id: 1,
    img: newsletimg1,
    link: pdf21,
    download: pdf21,
  },
  {
    id: 2,
    img: newsletimg2,
    link: pdf22,
    download: pdf22,
  },
  {
    id: 3,
    img: newsletimg3,
    link: pdf23,
    download: pdf23,
  },
];

const placementBrochureData = [
  {
    id: 1,
    img: PlacementBroucherimg3,
    link: pdf3,
    download: pdf3,
  },
];
const ProgramsPage3 = () => {
  return (
    <div>
      <div className="aboutbg">
        <div className="about-banner">
          <h2 className="title text-center">Research & Publications</h2>
        </div>
      </div>
      <div className="container">
        <div className="abouta d-flex justify-content-center flex-column align-items-center mt-5">
          {/* <h2 className="abouta-title">Certificate Course: Diploma Course </h2> */}
        </div>
      </div>
      <div className="container mt-4  sai">
        <p className="progsubhead">Research Journals:</p>
        <ItemList items={researchJournalsData} />
        <p className="progsubhead">Newsletter & Media:</p>
        <ItemList items={newsletterMediaData} />
        <p className="progsubhead">Placement Brochure:</p>
        <ItemList items={placementBrochureData} />
      </div>
    </div>
  );
};

export default ProgramsPage3;
