import React from 'react';
import { Link } from 'react-router-dom';

export default function Commitees() {
    return (
        <div className='container mt-5 d-flex justify-content-center flex-column align-items-center'>
            <p className='titles text-center'>Commitees</p>
            <div className='d-flex flex-wrap col-10  align-items-center justify-content-center'>
                <div className='comidiv col-lg-4 col-sm-12 col-md-5 p-2 m-3 '>
                   <Link to='/societies' className='linerm'> <p className='progtext m-0 text-center'>Anti-Ragging Committee</p></Link>
                </div>
                <div className='comidiv col-lg-4 col-sm-12 col-md-5 p-2 m-3'>
                   <Link  to='/societies' className='linerm'> <p className='progtext m-0 text-center'>College Disciplinary Committee</p></Link>
                </div>
                <div className='comidiv col-lg-4 col-sm-12 col-md-5 p-2 m-3 '>
                <Link  to='/societies' className='linerm'> <p className='progtext m-0 text-center'>Grievance Redressal Cell</p></Link>
                </div>
            </div>
            <p className='titles text-center mt-2'>Feedback</p>
            <div className='d-flex flex-wrap col-10  align-items-center justify-content-center'>
                <div className='comidiv col-lg-4 col-sm-12 col-md-5 p-2 m-3 '>
                <a href="https://forms.gle/LcFJXUh2n3LQ8V1T8" className='linerm'><p className='progtext m-0 text-center'>Parent's</p></a>
                   
                </div>
                <div className='comidiv col-lg-4 col-sm-12 col-md-5 p-2 m-3'>
                <a href="https://forms.gle/LcFJXUh2n3LQ8V1T8" className='linerm'><p className='progtext m-0 text-center'>Student's</p></a>
                  
                </div>
                <div className='comidiv col-lg-4 col-sm-12 col-md-5 p-2 m-3 '>
                <a href="https://forms.gle/LcFJXUh2n3LQ8V1T8" className='linerm'><p className='progtext m-0 text-center'>Employer's</p></a>
                </div>
            </div>

        </div>
    )
}
