import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import img1 from '../../assests/images/34- Placement-Achievement/Aardhaya Kaushal_page-0001.jpg';
import img2 from '../../assests/images/34- Placement-Achievement/Aman Kumar_page-0001.jpg';
import img3 from '../../assests/images/34- Placement-Achievement/Abhishek MR_page-0001.jpg';
import img4 from '../../assests/images/34- Placement-Achievement/Akashra_page-0001.jpg';
import img5 from '../../assests/images/34- Placement-Achievement/Alka Swach_page-0001.jpg';
import img6 from '../../assests/images/34- Placement-Achievement/Megha_page-0001.jpg';
import img7 from '../../assests/images/34- Placement-Achievement/Mohit_page-0001.jpg';
import img8 from '../../assests/images/34- Placement-Achievement/Vasupriya_page-0001.jpg';
import img9 from '../../assests/images/34- Placement-Achievement/Lata Kumari_page-0001.jpg';
import img10 from '../../assests/images/34- Placement-Achievement/Amiti_page-0001.jpg';
import img11 from '../../assests/images/34- Placement-Achievement/Anantha_page-0001.jpg';
import img12 from '../../assests/images/34- Placement-Achievement/Anayana Pandey_page-0001.jpg';
import img13 from '../../assests/images/34- Placement-Achievement/BROCHURE (2)_page-0001.jpg';
import img14 from '../../assests/images/34- Placement-Achievement/Geetika_page-0001.jpg';
import img15 from '../../assests/images/34- Placement-Achievement/Lakshya Priya_page-0001.jpg';
import img16 from '../../assests/images/34- Placement-Achievement/Mehak_page-0001.jpg';
import img17 from '../../assests/images/34- Placement-Achievement/Mohit (LLM)_page-0001.jpg';
import img18 from '../../assests/images/34- Placement-Achievement/NANDINI SHARMA_page-0001.jpg';
import img19 from '../../assests/images/34- Placement-Achievement/Nivedeta Singh_page-0001.jpg';
import img20 from '../../assests/images/34- Placement-Achievement/PARINAAZ SOOD UPDATED_page-0001.jpg';
import img21 from '../../assests/images/34- Placement-Achievement/Pallav_page-0001.jpg';
import img22 from '../../assests/images/34- Placement-Achievement/Pratyush_page-0001.jpg';
import img23 from '../../assests/images/34- Placement-Achievement/Prerna Manocha_page-0001.jpg';
import img24 from '../../assests/images/34- Placement-Achievement/Rupali Adhakari_page-0001.jpg';
import img25 from '../../assests/images/34- Placement-Achievement/Shagun_page-0001.jpg';
import img26 from '../../assests/images/34- Placement-Achievement/Stuti Tewari_page-0001.jpg';
import img27 from '../../assests/images/34- Placement-Achievement/Swati_page-0001.jpg';
import img28 from '../../assests/images/34- Placement-Achievement/Vaishnavi Sharma_page-0001.jpg';
import img29 from '../../assests/images/34- Placement-Achievement/Vaishnavi_page-0001.jpg';
import img30 from '../../assests/images/34- Placement-Achievement/Vasupriya_page-0001.jpg';
import img31 from '../../assests/images/34- Placement-Achievement/priya singh_page-0001.jpg';
import img32 from '../../assests/images/34- Placement-Achievement/Raashi Suredia_page-0001.jpg';
import img33 from '../../assests/images/34- Placement-Achievement/Rakishta Singh_page-0001.jpg';
import img34 from '../../assests/images/34- Placement-Achievement/Ranveer Chawla_page-0001.jpg';
import img35 from '../../assests/images/34- Placement-Achievement/ABHISHEK CHAUHAN_page-0001.jpg';


export default function Achievement() {
    const AchievementImg = [

        { img: img1 },
        { img: img2 },
        { img: img3 },
        { img: img4 },
        { img: img5 },
        { img: img6 },
        { img: img7 },
        { img: img8 },
        { img: img9 },
        { img: img10 },
        { img: img11 },
        { img: img12 },
        { img: img13 },
        { img: img14 },
        { img: img15 },
        { img: img16 },
        { img: img17 },
        { img: img18 },
        { img: img19 },
        { img: img20 },
        { img: img21 },
        { img: img22 },
        { img: img23 },
        { img: img24 },
        { img: img25 },
        { img: img26 },
        { img: img27 },
        { img: img28 },
        { img: img29 },
        { img: img30 },
        { img: img31 },
        { img: img32 },
        { img: img33 },
        { img: img34 },
        { img: img35 }
    ]
    return (
        <div className='mt-5'>
            <p className='progsubhead container'>Alumni Achievement:</p>
            <div className='achievement'>
                <div className='container pt-5 achievementimg '>
                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay={{ delay: 3000 }}
                        breakpoints={{
                            426: {
                                slidesPerView: 2
                            }
                        }}
                    >
                        {
                            AchievementImg.map((img, i) => (
                                <SwiperSlide key={i}>
                                    <img src={img.img} alt='' className='img-fluid' />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
