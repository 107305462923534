import React from 'react';
import pdf from '../../assests/images/others/ico-pdf.png';
import govpdf from '../../assests/images/pdf/Govt. India/Govt-of-Maharashtra-Approval.pdf';
import sppupdf1 from '../../assests/images/pdf/SPPU/SPPU Affiliation AY 2023-24.pdf';
import sppupdf2 from '../../assests/images/pdf/SPPU/SPPU-Affiliation-2022-23.pdf';
import sppupdf3 from '../../assests/images/pdf/SPPU/SPPU-Affiliation-2021-22.pdf';
import sppupdf4 from  '../../assests/images/pdf/SPPU/SPPU-Affiliation-2020-21.pdf';
import sppupdf5 from '../../assests/images/pdf/SPPU/SPPU-Approval-2019-20.pdf';
import sppupdf6 from '../../assests/images/pdf/SPPU/SPPU-Approval-2018-19.pdf';
import bcipdf1 from '../../assests/images/pdf/BCI/BCI-Approval-2018-19.pdf';
import bcipdf2 from '../../assests/images/pdf/BCI/BCI-Approval-2019-20.pdf';
import bcipdf3 from '../../assests/images/pdf/BCI/BCI-Approval-2020-21.pdf';
import bcipdf4 from '../../assests/images/pdf/BCI/BCI-Approval-2021-22.pdf';
import bcipdf5 from '../../assests/images/pdf/BCI/BCI-Approval-2022-23.pdf';
import bcipdf6 from '../../assests/images/pdf/BCI/BCI-Approval-2023-24.pdf';

export default function StatuaryA() {
    const GovpdfData = [
        {
            img: pdf,
            content: "Gov. of Maharashtra 2021-23",
            link: govpdf,
            name: "Gov. of Maharashtra"
        },
        // {
        //     img: pdf,
        //     content: "Gov. of Maharashtra 2021-23",
        //     link: govpdf,
        //     name: "Gov. of Maharashtra"
        // },
        // {
        //     img: pdf,
        //     content: "Gov. of Maharashtra 2021-23",
        //     link: govpdf,
        //     name: "Gov. of Maharashtra"
        // },
    ]
    const SPPUpdfData = [
        {
            img: pdf,
            content: "Savitribai Phule Pune University (SPPU) 2023-24",
            link: sppupdf1,
            name: "Savitribai Phule Pune University (SPPU)"
        },
        {
            img: pdf,
            content: "Savitribai Phule Pune University (SPPU) 2022-23",
            link: sppupdf2,
            name: "Savitribai Phule Pune University (SPPU)"
        },
        {
            img: pdf,
            content: "Savitribai Phule Pune University (SPPU) 2021-22",
            link: sppupdf3,
            name: "Savitribai Phule Pune University (SPPU)"
        },
        {
            img: pdf,
            content: "Savitribai Phule Pune University (SPPU) 2020-21",
            link: sppupdf4,
            name: "Savitribai Phule Pune University (SPPU)"
        },
        {
            img: pdf,
            content: "Savitribai Phule Pune University (SPPU) 2019-20",
            link: sppupdf5,
            name: "Savitribai Phule Pune University (SPPU)"
        },
        {
            img: pdf,
            content: "Savitribai Phule Pune University (SPPU) 2018-19",
            link: sppupdf6,
            name: "Savitribai Phule Pune University (SPPU)"
        },     
    ]
    const BCIpdfData = [
        {
            img: pdf,
            content: "BCI Approval 2023-24",
            link: bcipdf6,
            name: "Bar Council of India"
        },
        {
            img: pdf,
            content: "BCI Approval 2022-23",
            link: bcipdf5,
            name: "Bar Council of India"
        },
        {
            img: pdf,
            content: "BCI Approval 2021-22",
            link: bcipdf4,
            name: "Bar Council of India"
        },
        {
            img: pdf,
            content: "BCI Approval 2020-21",
            link: bcipdf3,
            name: "Bar Council of India"
        },
        {
            img: pdf,
            content: "BCI Approval 2019-20",
            link: bcipdf2,
            name: "Bar Council of India"
        },
        {
            img: pdf,
            content: "BCI Approval 2018-19",
            link: bcipdf1,
            name: "Bar Council of India"
        },     
      
    ]
    return (
        <div className=' container mt-5' id='statuApprovals'>
            <h2 className='abouta-title text-center'>Statutory Approvals</h2>
            <div className='d-flex flex-wrap justify-content-center'>
                <div className=' approvaldiv p-4 mt-5 '>
                    <h4 className='text-center py-3'>Government of Maharashtra</h4>
                    <p>The Government of Maharashtra through letter dated 21stMarch, 2018 signed by Joint Director, Higher Education permitted to start Army Law College at Pune,
                        on Permanent Non-Grant Basis and to offer B. B. A, LL. B (5 –Year Integrated Course) with intake of 60 from the academic year 2018-19 onwards.
                    </p>
                    {
                        GovpdfData.map((pdf, i) => (
                            <div className='pdfcard p-3 my-3' key={i}>
                                <a href={pdf.link} download={`${pdf.name}.pdf`} className='text-decoration-none'>
                                    <img src={pdf.img} alt='' className='img-fluid' />
                                </a>
                                <p>{pdf.content}</p>

                            </div>
                        ))
                    }
                </div>
                <div className='  approvaldiv p-4 mt-5 '>
                    <h4 className='text-center py-3'>Savitribai Phule Pune University (SPPU)</h4>
                    <p>Savitribai Phule Pune University (SPPU) through Letter No. CA/341 dated 22 March, 2018 granted affiliation for one year to Army Law College, Pune to start B. B. A, LL. B (5 –Year Integrated Course) with intake of 60 from the academic year 2018-19. Savitribai Phule Pune University (SPPU) through Letter No. CA/763 dated 16 May, 2019 granted the affiliation for current academic year i.e. 2019-20 for B. B. A, LL. B (5 –Year Integrated Course) – 1st Year (Renewal) and 2nd Year (Natural Progression)
                        Subsequently, Army Law College Pune has been granted requisite affiliation approvals on year to year basis from Savitribai Phule Pune University (SPPU) with effect from academic session 2019-20 till the current academic year for natural progression of the BBA LL.B Integrated Course. Please refer the copy of SPPU affiliation approvals given below:
                    </p>
                    {
                        SPPUpdfData.map((pdf, i) => (
                            <div className='pdfcard p-3 my-3' key={i}>
                                <a href={pdf.link} download={`${pdf.name}.pdf`} className='text-decoration-none'>
                                    <img src={pdf.img} alt='' className='img-fluid' />
                                </a>
                                <p>{pdf.content}</p>
                            </div>
                        ))
                    }
                </div>
                <div className=' approvaldiv p-4 mt-5 '>
                    <h4 className='text-center py-3'>Bar Council of India</h4>
                    <p>The Bar Council of India vide letter 756/2018 (LE/Std. 22/23/24.6.2018) dated 28/06/2018 granted initial approval of affiliation to Army Law College Pune to conduct 5-year integrated BBA LL.B course with an intake of one section of 60 students for a period of one year i.e. for academic year 2018-19.
                        Subsequently, Army Law College, Pune has been granted requisite approvals on year to year basis from the Bar Council of India with effect from academic session 2019-20 till the current academic year for natural progression of the BBA LL.B Integrated Course with an annual intake of 60 students. Please refer the copy of BCI approvals given below:
                        Application for the approval of BCI in r/o B.A. LL. B Course for the Academic Year 2024-25 is in process. 
                    </p>
                    {
                        BCIpdfData.map((pdf, i) => (
                            <div className='pdfcard p-3 my-3' key={i}>
                                <a href={pdf.link} download={`${pdf.name}.pdf`} className='text-decoration-none'>
                                    <img src={pdf.img} alt='' className='img-fluid' />
                                </a>
                                <p>{pdf.content}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
