import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img1 from "../../assests/images/importantlinks/82633992.png";
import img2 from "../../assests/images/importantlinks/nic_left.png";
import img3 from "../../assests/images/importantlinks/logo-new.png";
import img4 from "../../assests/images/importantlinks/263146798_379218840660761_1685969332351298359_n.png";
import img5 from "../../assests/images/importantlinks/download (12).png";
import img6 from "../../assests/images/importantlinks/download (11).png";
import img7 from "../../assests/images/importantlinks/WorldLII.png";
import img8 from "../../assests/images/importantlinks/download (13).png";
import img9 from "../../assests/images/importantlinks/unnamed (1).png";
import img10 from "../../assests/images/importantlinks/logo (6).png";
import img11 from "../../assests/images/importantlinks/ugc_logo.png";
import img12 from "../../assests/images/importantlinks/aweslogo.jpg";
import img13 from "../../assests/images/importantlinks/logoawpo.png";
import img14 from "../../assests/images/importantlinks/Emblem_of_National_Cadet_Corps_(India).png";
import img15 from "../../assests/images/importantlinks/National-Service-Scheme_Preview.png";

import mou1 from "../../assests/images/importantlinks/MoU flyer.png";
import mou2 from "../../assests/images/importantlinks/Report on the signing of the MOU between Ayaan Placement and Training Academy.png"

import press1 from "../../assests/images/Press/WhatsApp Image 2024-07-19 at 13.39.49_9b3d9c72.jpg";
import press2 from "../../assests/images/Press/WhatsApp Image 2024-07-19 at 13.39.50_a8ef79a3.jpg";
import press3 from "../../assests/images/Press/WhatsApp Image 2024-07-19 at 13.39.51_7f9f3f94.jpg";
import press4 from "../../assests/images/Press/WhatsApp Image 2024-07-19 at 13.39.51_d0de68ff.jpg";
import press5 from "../../assests/images/Press/WhatsApp Image 2024-07-19 at 13.39.52_8b9cfee9.jpg";
import press6 from "../../assests/images/Press/WhatsApp Image 2024-07-19 at 13.39.53_3e71419a.jpg";
import press7 from "../../assests/images/Press/WhatsApp Image 2024-07-19 at 13.39.53_48fc61e7.jpg";

export default function ImportantLinks() {
  const images = [
    {
      img: img1,
      link: "https://swayam.gov.in/",
    },
    {
      img: img2,
      link: "https://scholarships.gov.in/",
    },
    {
      img: img3,
      link: "https://nad.gov.in/",
    },
    {
      img: img4,
      link: "https://legal.un.org/poa/avl.html",
    },
    {
      img: img5,
      link: "https://lawcommissionofindia.nic.in/",
    },
    {
      img: img6,
      link: "https://www.barcouncilofindia.org/home",
    },
    {
      img: img7,
      link: "http://www.worldlii.org/",
    },
    {
      img: img8,
      link: "https://ndl.iitkgp.ac.in/ ",
    },
    {
      img: img9,
      link: "http://www.unipune.ac.in/",
    },
    {
      img: img10,
      link: "https://epgp.inflibnet.ac.in/ ",
    },
    {
      img: img11,
      link: "https://www.ugc.gov.in/",
    },
    {
      img: img12,
      link: "https://www.awesindia.com/",
    },
    {
      img: img13,
      link: "https://www.exarmynaukri.com/php/login/newsite5/",
    },
    {
      img: img14,
      link: "https://indiancc.mygov.in/",
    },
    {
      img: img15,
      link: "https://nss.gov.in/",
    },
  ];

  const mou = [
    {
      img: mou1,
    },
    {
      img: mou2,
    },
  ];
  const press = [
    {
      img: press1,
    },
   
    {
      img: press2,
    },
   
    {
      img: press3,
    },
   
    {
      img: press4,
    },
   
    {
      img: press5,
    },
   
    {
      img: press6,
    },
   
    {
      img: press7,
    },
   
  ];
  return (
    <>
      <div className="container my-5">
        <h2 className="titles">Important Links</h2>
        <div className="mt-4 d-flex flex-wrap justify-content-center ">
          {/* <div className='col-3 linkdiv justify-content-center text-center align-items-center d-flex'>
                    <button className='readmore'>Know more <span><FontAwesomeIcon icon={faArrowUp} className='uparrow' /></span></button>
                </div> */}
          {images.map((img, index) => (
            <div
              className="impimg my-3 justify-content-center text-center align-items-center d-flex"
              key={index}
            >
              <a href={img.link} target="_blank">
                <img src={img.img} alt="" className="img-fluid col-8" />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="container my-5">
        <h2 className="titles">MoU</h2>
        <div className="mt-4 d-flex flex-wrap justify-content-center ">
          {/* <div className='col-3 linkdiv justify-content-center text-center align-items-center d-flex'>
                    <button className='readmore'>Know more <span><FontAwesomeIcon icon={faArrowUp} className='uparrow' /></span></button>
                </div> */}
          {mou.map((img, index) => (
            <div
              className="impimg my-3 justify-content-center text-center align-items-center d-flex"
              key={index}
            >
              <a href={img.img} download={img.img}>
                <img src={img.img} alt="" className="img-fluid col-8" />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="container my-5">
        <h2 className="titles">Press</h2>
        <div className="mt-4 d-flex flex-wrap justify-content-center ">
          {/* <div className='col-3 linkdiv justify-content-center text-center align-items-center d-flex'>
                    <button className='readmore'>Know more <span><FontAwesomeIcon icon={faArrowUp} className='uparrow' /></span></button>
                </div> */}
          {press.map((img, index) => (
            <div
              className="impimg my-3 justify-content-center text-center align-items-center d-flex"
              key={index}
            >
              <a href={img.img} download={img.img}>
                <img src={img.img} alt="" className="img-fluid col-8" />
              </a>
            </div>
          ))}
        </div>
      </div>
      
    </>
  );
}
