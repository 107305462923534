import React from 'react'
import QAssurance from '../components/socities/QAssurance'
import OpportunityC from '../components/socities/OpportunityC'
import RedressalC from '../components/socities/RedressalC'
import DivyangC from '../components/socities/DivyangC'
import SexualHarresmentC from '../components/socities/SexualHarrasmentC'
import StudentCouncil from '../components/socities/StudentCouncil'
import AntiraggingC from '../components/socities/AntiraggingC'
import CollegeC from '../components/socities/CollegeC'
import Disiplinary from '../components/studentlife/commitee/Disiplinary'

export default function Societies() {
    return (
        <div>
            <div className='aboutbg'>
                <div className='about-banner'>
                    <h2 className='title text-center'>SOCIETIES & CELLS</h2>
                </div>
            </div>
          <div className='d-flex justify-content-center my-5'>
          <div className='title-btn'>
                <div className='title-border'>
                    Statutory Committees
                </div>
            </div>
          </div>
            <div className='society'>
                <QAssurance />
                <RedressalC />
                <OpportunityC />
                <DivyangC />
                <SexualHarresmentC />
                <AntiraggingC />
                <Disiplinary />
                <StudentCouncil />
            </div>
        </div>
    )
}
