export const semData = [
  {
    id: 1,
    code: "CE 0101",
    subject: "General English",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "BB 0102",
    subject: "Business Accounting ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "BB 0103",
    subject: "Principles of Management ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "BB 0104",
    subject: "Business Communications ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const sem2Data = [
  {
    id: 1,
    code: "CE 0201",
    subject: "English for Law",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "BB 0202",
    subject: "Organizational Behaviors ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "BB 0203",
    subject: "Management Information System",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "BB 0204",
    subject: "Principles of Marketing ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const sem3Data = [
  {
    id: 1,
    code: "CE 0301",
    subject: "Legal Language and Legal Reasoning ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "BB 0302",
    subject: "Basics of Finance ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "BB 0303",
    subject: "Managerial Economics",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "BB 0304",
    subject: "	Business Ethics and Corporate Governance ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const sem4Data = [
  {
    id: 1,
    code: "CE 0401",
    subject: "Law and Literature ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "BB 0402",
    subject: "Human Resource Management ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "BB 0403",
    subject: "Case Studies in Business Environment",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "BB 0404",
    subject: "Business Research Methods",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const sem5Data = [
  {
    id: 1,
    code: "LCC 0501",
    subject: "Constitutional Law – I  ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "LCC 0502",
    subject: "Law of Contract - I ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "LCC 0503",
    subject: "Family Law - I ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "LCC 0504",
    subject: "Law of Crimes",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const ElectiveSem5Data = [
  {
    id: 5,
    code: "LDSE 0505",
    subject: "Health and Food Law ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 6,
    code: "LDSE 0506",
    subject: "Equity and Trust Law ",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 7,
    code: "LDSE 0507",
    subject: "Criminal Psychology and Criminal Sociology  ",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 8,
    code: "LDSE 0508",
    subject: "Agricultural Marketing Law ",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 9,
    code: "LGE 0509",
    subject: "Intellectual Property Rights  ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 10,
    code: "EPM  05010",
    subject: "English*  ",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
];

export const sem6Data = [
  {
    id: 1,
    code: "LCC 0601",
    subject: "Constitutional Law - II  ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "LCC 0602",
    subject: "Law of Contract – II ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "LCC 0603",
    subject: "Family Law - II",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "LCC 0604",
    subject: "Jurisprudence",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const ElectiveSem6Data = [
  {
    id: 5,
    code: "LDSE 0605",
    subject: "Election Law",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 6,
    code: "LDSE 0606",
    subject: "Insurance Law",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 7,
    code: "LDSE 0607",
    subject: "Penology and Victimology",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 8,
    code: "LDSE 0608",
    subject: "Comparative Constitution",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 9,
    code: "LGE 0609",
    subject: "Media Laws ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const sem7Data = [
  {
    id: 1,
    code: "LCC 0701",
    subject: "Criminal Procedure Code  ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "LCC 0702",
    subject: "Law of Evidence ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "LCC 0703",
    subject: "Interpretation of Statutes",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "LAEC 0704",
    subject:
      "Practical Training (I):  Professional Ethics and Contempt of Court Law",
    L: 2,
    T: 0,
    P: 2,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const ElectiveSem7Data = [
  {
    id: 5,
    code: "LDSE 0705",
    subject: "Investment and Securities Law",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 6,
    code: "LDSE 0706",
    subject: "Criminal Minor Acts",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 7,
    code: "LDSE 0707",
    subject: "Cooperative Law",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 8,
    code: "LDSE 0708",
    subject: "Private International Law",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
];

export const sem8Data = [
  {
    id: 1,
    code: "LCC 0801",
    subject: "Civil Procedure Code and Limitation Act ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "LCC 0802",
    subject: "Company Law ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "LCC 0803",
    subject: "Law of Tort and Consumer Protection",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "LAEC 0804",
    subject: "Practical Training (II):Alternate Dispute Resolution System",
    L: 2,
    T: 0,
    P: 2,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const ElectiveSem8Data = [
  {
    id: 5,
    code: "LDSE 0805",
    subject: "Human Rights Law and Practice",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 6,
    code: "LDSE 0806",
    subject: "Civil Minor Acts",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 7,
    code: "LDSE 0807",
    subject: "Competition Law",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 8,
    code: "LDSE 0808",
    subject: "International Economic Law",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
];

export const sem9Data = [
  {
    id: 1,
    code: "LCC 0901",
    subject: "Property Law and Easement   ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "LCC 0902",
    subject: "Public International Law ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "LCC 0903",
    subject: "Administrative Law ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "LAEC 0904",
    subject: " Practical Training (III): Drafting Pleading and Conveyance  ",
    L: 2,
    T: 0,
    P: 2,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 5,
    code: "LSEC  0905",
    subject: "Introduction to Civil and Criminal Manual",
    L: 2,
    T: 0,
    P: 2,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
];

export const ElectiveSem9Data = [
  {
    id: 6,
    code: "LDSE 0906",
    subject: "Defence and Strategic Studies ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 7,
    code: "LDSE 0907",
    subject: "Law of Forensic Science ",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 8,
    code: "LDSE 0908",
    subject: "Land Laws",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 9,
    code: "LDSE 0909",
    subject: "International Law on Air, Space and Sea ",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
];

export const sem10Data = [
  {
    id: 1,
    code: "LCC 1001",
    subject: "Labour Laws",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 2,
    code: "LCC 1002",
    subject: "Principles of Taxation",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 3,
    code: "LCC 1003",
    subject: "Environmental Law ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 4,
    code: "LAEC 1004",
    subject: " Practical Training (IV): Moot Court Exercise and Internship ",
    L: 0,
    T: 0,
    P: 4,
    credit: 4,
    internal: 90,
    external: 10,
    total: 100,
  },
  {
    id: 5,
    code: "LSEC  1005",
    subject: "Skills of Legislative Drafting and Judgment Writing",
    L: 2,
    T: 0,
    P: 0,
    credit: 2,
    internal: 15,
    external: 35,
    total: 50,
  },
];

export const ElectiveSem10Data = [
  {
    id: 6,
    code: "LDSE 1006",
    subject: "Bankruptcy and Insolvency Law ",
    L: 3,
    T: 1,
    P: 0,
    credit: 4,
    internal: 30,
    external: 70,
    total: 100,
  },
  {
    id: 7,
    code: "LDSE 1007",
    subject: "Comparative Criminal Justice System",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 8,
    code: "LDSE 1008",
    subject: "Humanitarian and Refugee Law",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
  {
    id: 9,
    code: "LDSE 1009",
    subject: "Real Estate Laws ",
    L: 0,
    T: 0,
    P: 0,
    credit: 0,
    internal: 0,
    external: 0,
    total: 0,
  },
];
