import React from "react";
import logo from "../../assests/images/logo/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagramSquare,
  faLinkedin,
  faSquareXTwitter,
  faYoutubeSquare,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export default function Footer() {
  const items = [
    { title: "Home", link: "/" },
    { title: "About Us", link: "/about" },
    { title: "Infrastructure", link: "/infra" },
    { title: "Courses", link: "/programs" },
    { title: "Admissions", link: "/addmission/prospectous" },
    { title: "Library", link: "/library" },
    { title: "Contact Us", link: "/contact" },
    { title: "Student Life", link: "/studentlife" },
  ];

  const subItem = [
    { title: "Events", link: "/events" },
    { title: "Staff", link: "/staff" },
    { title: "Alumni", link: "/alumni" },
    { title: "NAAC", link: "/committee/NAAC" },
    // { title: "MoU", link: "/" },
    { title: "Cells & Society", link: "/societies" },
    { title: "Careers & Placements", link: "/committee/Carrer" },
    { title: "Jobs & Tenders", link: "/committee/JOBS" },
    { title: "ALC Publication", link: "/courseprogram3" },
  ];
  return (
    <div className="footer">
      <div className="d-flex  pb-2 pt-4 mt-5 flex-wrap justify-content-between">
        <div className="col-lg-4 col-12 collegename d-flex justify-content-center mb-3 align-items-center">
          <img src={logo} alt="" className="col-lg-2" />
          <div>
            <h2> ARMY LAW COLLEGE PUNE </h2>
            <p>(Affiliated to SavitriBai Phule Pune University)</p>
            <p>(Approved By Bar Council Of India, Govt. Of Maharashtra)</p>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 px-5 mb-3">
          <p className="footerhead">Menus/Tabs</p>
          <div className="d-flex">
            <div className="col-6">
              {items.map((item, index) => (
                <Link to={item.link} className="text-decoration-none">
                  {" "}
                  <li key={index}>{item.title}</li>
                </Link>
              ))}
            </div>
            <div className="col-6">
              {subItem.map((item, index) => (
                <Link to={item.link} className="text-decoration-none">
                  {" "}
                  <li key={index}>{item.title}</li>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-5 col-sm-12 px-5 ">
          <p className="footerhead">Follow Us on</p>
          <div className="d-flex footericon">
            <a href="https://m.facebook.com/registrar.alc/">
              <FontAwesomeIcon icon={faFacebook} className="icon" />
            </a>
            <a href="https://www.linkedin.com/company/army-law-college-pune" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="icon" />
            </a>
            {/* <a href="">
              <FontAwesomeIcon icon={faSquareXTwitter} className="icon" />
            </a> */}
            <a href="https://www.instagram.com/armylawcollegepune/" target="_blank" rel="noopener noreferrer">
              {" "}
              <FontAwesomeIcon icon={faInstagramSquare} className="icon" />
            </a>
            {/* <a href="">
              <FontAwesomeIcon icon={faYoutubeSquare} className="icon" />
            </a> */}
            {/* <a href="https://wa.me/your-phonenumber">
              {" "}
              <FontAwesomeIcon icon={faWhatsapp} className="icon" />
            </a> */}
          </div>
          <div></div>
        </div>
      </div>
      <div className="bottombar p-2">
        <p>© 2024 All Rights Reserved</p>
        <p>Design and Development by RAK Softech</p>
      </div>
    </div>
  );
}
