import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import img1 from "../../assests/images/visitors/1.Army-Commander-and-Patron-in-Chief-Lt.-Gen.-J.-S.-Nain-PVSM-AVSM-SM_1-150x150.png";
import img2 from '../../assests/images/visitors/2.Col_.Jay-Gaurav-Manhas-100x100.png';
import img3 from '../../assests/images/visitors/3.Adv-Dr-Sudhakar-E.-Avhad-Former-Chairman-Bar-Council-of-Maharashtra-and-Goa-100x100.png';
import leftdesign from '../../assests/images/others/right-corner.png';
import rightdesign from '../../assests/images/others/left-corner.png';
import card1 from '../../assests/images/visitors/VS05_Prof.-Amitabh-Dasgupta.png';
import card2 from '../../assests/images/visitors/2.Col_.Jay-Gaurav-Manhas_1.png';
import card3 from '../../assests/images/visitors/VS05_Prof.-Amitabh-Dasgupta.png';

export default function Visitors() {
    const visitorsData = [
        {
            img: img1,
            name: "Lt. Gen. J. S. Jain, PVSM, AVSM, SM",
            position: "Former Army Commander",
            card: card1
        },
        {
            img: img2,
            name: "Col. Jay Gaurav Manhas",
            position: "DJAG (Lit) Southern Commander",
            card: card2
        },
        {
            img: img3,
            name: "Adv (Dr) Sudhakar E. Avhad",
            position: "Former chairman, Bar counsil Maharashtra & Goa",
            card: card3
        }
    ]
    return (
        <div className='visitors'>
            <div className='container py-5'>
                <h2>What Our Visitors Says</h2>
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    spaceBetween={100}
                    slidesPerView={1}
                    autoplay={{delay:3000}}
                    pagination={{ clickable: true }}
                    // navigation
                >
                    {visitorsData.map((data, index) => (
                        <SwiperSlide key={index}>
                            <div className='mt-5 d-flex justify-content-center align-items-center sub-visitor'>
                                <div className='col-lg-5  col-md-6 col-sm-5 text-center d-flex flex-column justify-content-center align-items-center vimg '>
                                    <img alt='' src={data.img} className='img-fluid visitorimg col-md-6' />
                                    <p className='vname mt-3'>{data.name}</p>
                                    <p className='vposition'>{data.position}</p>
                                </div>
                                <div className='d-flex align-items-center justify-content-center position-relative '>
                                    <div className='note'>
                                        <div className='noteimg'>
                                            <img src={data.card} alt='' className='img-fluid'/>
                                        </div>
                                        <div className='left'>
                                            <img src={leftdesign} alt='' className='img-fluid' />
                                        </div>
                                        <div className='right'>
                                            <img src={rightdesign} alt='' className='img-fluid ' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
