import React from 'react'
import Banner from '../components/about/Banner'
import About from '../components/about/About'
import Vision from '../components/about/Vision'
import Team from '../components/about/Team'
import PrincipalDetail from '../components/about/PrincipalDetail'
// import RegistrarMsg from '../components/about/RegistrarMsg'
import StatuaryA from '../components/about/StatuaryA'

export default function AboutPage() {
  return (
    <div>
      <Banner/>
      <About/>
      <Vision/>
      <Team/>
      <PrincipalDetail/>
      {/* <RegistrarMsg/> */}
      <StatuaryA/>
    </div>
  )
}
