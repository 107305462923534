import React from 'react';
import img1 from '../../../assests/images/EventsPhoto/boys-hostel.JPG'
import img2 from '../../../assests/images/infrastructure/boys-hostel_alc-pune.png';
import img3 from '../../../assests/images/infrastructure/Girls Hostel Rooms.png';
import img4 from '../../../assests/images/infrastructure/Boys Hostel Gym Eqpmnt.png';
import img5 from '../../../assests/images/infrastructure/Boys Hostel Rooms.png';
export default function BHostel() {
    return (
        <div className='container infra d-flex flex-wrap'>
            <div className='p-3' >
                <p className='progsubhead'>Shivaji Boys Hostel :</p>
                <p className='infrasub'>Entry Lounge, 3 Bed Sharing 22 Rooms (for 66 boys), Common Toilets & Washrooms, Central Kitchen & Utility, 64 seater Dining Hall, Central Common/TV Room, Gymnasium, open terrace and Service Lift.</p>
                <p className='infrasub2'>The consolidated capacity of Boys hostels is 138.</p>
            </div>
            <div className='col-lg-6 col-sm-12 blockimg p-3'>
                <img src={img1} alt='' className='img-fluid' />
            </div>
            <div className='col-lg-6 col-sm-12 blockimg p-3'>
                <img src={img2} alt='' className='img-fluid' />
            </div>
            <div className='col-lg-4 col-sm-12 blockimg p-3'>
                <img src={img3} alt='' className='img-fluid' />
            </div>
            <div className='col-lg-4 col-sm-12 blockimg p-3'>
                <img src={img4} alt='' className='img-fluid' />
            </div>
            <div className='col-lg-4 col-sm-12 blockimg p-3'>
                <img src={img5} alt='' className='img-fluid' />
            </div>
            <div>
                <p className='infrasub2 p-3'>Sport Facilities include Indoor Badminton Court, Volleyball Court and Basketball Court along with the facilities for outdoor physical training and gym are existing on the campus.</p>
            </div>
        </div>
    )
}
