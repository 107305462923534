import React from 'react';
import img1 from '../../assests/images/library/OPAC 1 (1).jpg';
import point from '../../assests/images/others/point (1).png';
export default function WebL() {
    return (
        <div className='mt-4'>
            <h2 className='progsubhead'> WEB- OPAC</h2>
            <div className='col-12 p-2 dl-img '>
                <img src={img1} alt='' className='img-fluid ' />
            </div>
            <p className='progtext'>Web OPAC is an acronym for "Web- based Online Public Access Catalog." In simpler terms, it is an online platform that serves as a gateway to our college library's collection of books, journals, periodicals, and other valuable resources. Our Web OPAC is designed to help you explore and access these resources with ease, from anywhere and at any time. Web OPAC is a powerful tool that empowers students, faculty, and researchers to explore our library's rich collection of resources with ease and convenience. It's a vital addition to our commitment to enhancing the educational experience for all members of our college community. Discover new materials related to your field of study through our recommendation feature, which suggests resources based on your previous searches and borrowing history.  </p>
            <div className='mt-4'>
                <h2 className='progsubhead'> How to Access Web OPAC:</h2>
                <ul className='mt-4'>
                    <li className='d-flex'>
                        <img src={point} alt='' className='hand img-fluid col-1' /><p className='px-4 aboutp'>Click on the "Web OPAC" or "Library Catalog" link. (Id is your Library Membership No ex. 1047 and Password id your birthdate ex. 18/10/1994.</p>
                    </li>
                    <li className='d-flex'>
                        <img src={point} alt='' className='hand img-fluid col-1' /><p className='px-4 aboutp'>Start exploring the wealth of knowledge at your fingertips!.</p>
                    </li>
                </ul>
                <p className='progsub'>PLEASE CLICK HERE TO ACCESS: </p>
                <p className='link access-btn col-lg-6 col-sm-12 col-md-8'>http://115.247.158.202 /AutoLibWebOPAC/Login.aspx</p>
            </div>
        </div>
    )
}
