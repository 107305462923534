import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState, useEffect} from 'react'
import { Eventsdata } from '../../EventsData'
import { useNavigate,useParams } from 'react-router-dom'

export default function EventDetail() {
    const { name, id } = useParams();
    const navigate= useNavigate()
    const [selectedIndex, setSelectedIndex] = useState(null);
   
    useEffect(() => {
        if (id) {
            const index = Eventsdata.findIndex(event => event.id === parseInt(id));
            setSelectedIndex(index);
        }
    }, [id]);
    
//  console.log(name, id)
 console.log(selectedIndex)
    return (
        <div>
            <div className='aboutbg'>
                <div className='about-banner'>
                    <h2 className='title'>Events</h2>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='d-flex flex-wrap'>
                    <div className='leftarr' onClick={() => navigate('/events')}>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </div>
                    {selectedIndex !== null && Eventsdata[selectedIndex] && Eventsdata[selectedIndex].relatedEvents && Eventsdata[selectedIndex].relatedEvents.length > 0 && Eventsdata[selectedIndex].relatedEvents.map((data, index) => (
                        <div key={index} className='edetail'>
                            <div className='d-flex flex-column justify-content-center align-items-center '>
                                <h2>{data.name}</h2>
                                {/* <p className='progtext text-center col-lg-6 col-sm-10 col-md-7 mt-0 '>{data.detail}</p> */}
                            </div>
                            <div className='d-flex flex-wrap mt-4 '>
                                {data.Images.map((img, imgIndex) => (
                                    <img key={imgIndex} src={img} alt={`Image ${imgIndex}`} className='col-lg-6 col-md-6 col-sm-10 p-2 img-fluid' />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
