import React from 'react';
import codeimg from '../assests/images/others/code_Conduct_img.png';
import pdf from '../assests/images/pdf/ALC Code of Conduct.pdf';

export default function CodeCPage() {
    const prosData = [
        {
            id: 1,
            img: codeimg,
            link: pdf,
            name: "Code of Conduct"
        },
    ]
  return (
    <div>
       <div className='aboutbg'>
        <div className='about-banner'>
          <h2 className='title'>CODE OF CONDUCT</h2>
        </div>
      </div>
      <div className=' mt-5 d-flex justify-content-center container '>
                <div className='formdiv prosimg m-2'>
                {
                        prosData.map((data, i) => (
                            <div className='formdiv prosimg '>
                                <a href={data.link} download={`${data.name}.pdf`}>
                                    <img src={data.img} alt='' className='img-fluid p-2' />
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
    </div>
  )
}
