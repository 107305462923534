import React from "react";
import { Link } from "react-router-dom";
// import pdf1 from "../../assests/images/pdf/2nd Moot Court Competition-Quest Indicia- 2024.pdf";
import { Badge } from "react-bootstrap";
import pdf2 from "../../assests/images/others/Notice.pdf";
import pdf3 from "../../assests/images/others/Notice2.pdf";
import pdf4 from "../../assests/images/others/Notice4.pdf";
import pdf5 from "../../assests/images/others/PTM Notice.pdf";
import pdf6 from "../../assests/images/others/Notice_Workshop_financial.pdf";
import pdf7 from "../../assests/images/others/Notice- Celebration of National Librarian’s Day, 2024.pdf";
import pdf8 from "../../assests/images/others/Orientation Programme 2024- First Year B.B.A. & B.A. LL.B 2024- 25 On 20-21 August 2024.pdf";
import pdf9 from "../../assests/images/others/Notice- workshop new cr. law.pdf";
import pdf10 from "../../assests/images/others/NOTICE - SOFT SKILLS SESSION.pdf";
import pdf11 from "../../assests/images/others/Notice_Avishkar Competition, 2024..pdf";
import pdf13 from "../../assests/images/others/role of forensic science in investigation of crime.jpg";
import pdf14 from "../../assests/images/others/5.9.24 GUEST LECTURE ON CONTEMPT OF COURT'.pdf";
import pdf15 from "../../assests/images/others/GUEST LECTURE ON  PROF ETHICS 5.9.24.pdf";
import pdf16 from "../../assests/images/others/6.9.24 ORIENTATION OF INTERNSHIP & PLACEMENT CELL.pdf";
import pdf17 from "../../assests/images/others/9.9.24 TRAINING SESSION FOR 1 YEAR ON BASICS OF LINKEDIN.pdf";
import pdf18 from "../../assests/images/others/LECTURE ON LAAW OF BALLISTICS 28.09.24.pdf";
import pdf19 from "../../assests/images/others/5.10.24 SESSION ON PERSONAL BRANDING.pdf";
import pdf20 from "../../assests/images/others/GUEST LECTURE ON SELF IMPROVEMENT 14.10.24.pdf";
export default function Notices() {
  const NoticeData = [
    // {
    //   id: 1,
    //   title: "Quest Indicia' 2024-2nd Moot Court Competition.",
    //   link: pdf1,

    //   // date: "01 Feb 2024",
    // },
    {
      id: 2,
      title: "Notice- Symposium On 05.05.24",
      link: pdf2,
      // date: "01 Feb 2024",
    },
    {
      id: 3,
      title:
        " Notice- Field Marshal SHFJ Manekshaw 2nd Humanitarian Law Symposium 10-11 May 2024",
      link: pdf3,
      // date: "01 Feb 2024",
    },
    {
      id: 4,
      title: " Notice- Filling Examination Form of April- May 2024 Examination",
      link: pdf4,
      // date: "01 Feb 2024",
    },
    {
      id: 5,
      title: " Notice- Parent's Teacher's Meeting- 29 June 2024",
      link: pdf5,
      // date: "01 Feb 2024",
    },
    {
      id: 6,
      title: "Notice- Workshop on Financial Planning- 03 July 2024",
      link: pdf6,
      // date: "01 Feb 2024",
    },
    {
      id: 7,
      title: " Notice- Celebration of National Librarian’s Day, 2024",
      link: pdf7,
      // date: "01 Feb 2024",
    },
    {
      id: 8,
      title:
        "Notice- Orientation Programme 2024- First Year B.B.A. & B.A. LL.B 2024- 25 On 20-21 August 2024",
      link: pdf8,
      // date: "01 Feb 2024",
    },
    {
      id: 9,
      title: "Notice- Workshop on Recent Criminal Law Legislations ",
      link: pdf9,
    },
    {
      id: 10,
      title: "NOTICE - SOFT SKILLS SESSION",
      link: pdf10,
    },
    {
      id: 11,
      title: "NOTICE - Avishkar Competition, 2024 ",
      link: pdf11,
    },
    {
      id: 12,
      title: "Registration form: Avishkar Competition, 2024 ",
      link: "https://docs.google.com/forms/d/e/1FAIpQLScBqIriVXeth7ws67-1Lr5rn9c4nyzuIC3AEBKG5GaXSem7Cw/viewform ",
    },
    {
      id: 13,
      title:
        "Present Guest Lecture on Role Of Forensic Science In Investigaation Of Crime ",
      link: pdf13,
    },
    {
      id: 14,
      title: "Guest Lecture on Contempt of Court",
      link: pdf14,
    },
    {
      id: 15,
      title: "Guest Lecture on Professional Ethics",
      link: pdf15,
    },
    {
      id: 16,
      title: "Orientation of Internship & Placement Cell",
      link: pdf16,
    },
    {
      id: 17,
      title: "Training Session for 1st Year on Basics of LinkedIn",
      link: pdf17,
    },
    {
      id: 18,
      title: "Lecture on Law of Ballistics",
      link: pdf18,
    },
    {
      id: 19,
      title: "Session on Personal Branding",
      link: pdf19,
    },
    {
      id: 20,
      title: "Guest Lecture on Self Improvement",
      link: pdf20,
    },
    
  ];

  NoticeData.sort((a, b) => b.id - a.id);

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      {NoticeData.map((data, i) => (
        <div className="row  d-flex align-items-center flex-wrap " key={i}>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <p className="ntname progtext m-0">
              {data.title}{" "}
              {i < 2 && (
                <span className="mx-1">
                  <Badge bg="danger">New</Badge>
                </span>
              )}
            </p>

            <a href={data.link} className="linerm" target="_blank">
              {/* <p className="ntdate">
                {data.date} <span className="mx-1"></span>
              </p> */}
            </a>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12">
            <p
              className="event-btn"
              style={{ cursor: "pointer" }}
              onClick={() => handleDownload(data.link)}
            >
              <span className="view-btn">View</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
