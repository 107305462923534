import React from 'react';
import img1 from '../../../assests/images/infrastructure/dharmsanstha.png';
import img2 from '../../../assests/images/infrastructure/Classroom Block _ Chanakya.png';
import img3 from '../../../assests/images/EventsPhoto/1-ALC Campus, Kanhe.jpg'

export default function Academic() {
    return (
        <div className='container infra d-flex flex-wrap'>
            <div className='col-lg-6 col-sm-12 p-3'>
                <p className='progsubhead'>Chanakya Academic cum Adm Block:</p>
                <p className='infrasub'>12 class rooms, Reception Lounge, Auditorium with 450 seating capacity, 2 Moot Courts, VIP Visitor Chamber, 30- seater Conference Hall, HOD office, 18 cabins for Teachers, Boys’ & Girls’ Common Rooms (in new hostels), Legal Aid Clinic, Placement and Entrepreneurship Development Cell, Administrative Office, Principal’s Office, Registrar’s Office, Student Washrooms, Staff Washrooms, Central Pantry Station and Lift.</p>
            </div>
            <div className='col-lg-6 col-sm-12 blockimg p-3'>
                <img src={img1} alt='' className='img-fluid' />
            </div>
          <div className='col-lg-6 col-sm-12 blockimg p-3'>
                <img src={img2} alt='' className='img-fluid' />
            </div>
            <div className='col-lg-6 col-sm-12 blockimg p-3'>
                <img src={img3} alt='' className='img-fluid' />
            </div>
        </div>
    )
}
