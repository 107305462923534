import React from "react";
import img1 from "../../assests/images/facilities/Sudens Room.png";
import img2 from "../../assests/images/facilities/Girls Hostel Common Room.png";
import img3 from "../../assests/images/facilities/Girls Hostel Rooms.png";
import img4 from "../../assests/images/infrastructure/19- Play Grounds/tt table.jpg";
import img5 from "../../assests/images/facilities/Girls Hostel Mess.png";
import img6 from "../../assests/images/facilities/ALC_Mess.png";
import img7 from "../../assests/images/facilities/Mess.png";
import img8 from "../../assests/images/facilities/Batminton 1.png";
import img9 from "../../assests/images/facilities/basketball 1.png";
import img10 from "../../assests/images/facilities/IMG_2983.png";
import img11 from "../../assests/images/infrastructure/19- Play Grounds/tt table.jpg";

export default function Facilities() {
  return (
    <div className="container mt-5">
      <p className="titles text-center facilities-title">ALC Facilities</p>
      <div className="d-flex flex-wrap mt-5">
        <div className="col-lg-4 col-sm-12">
          <p className="progsubhead">Hostels</p>
          <p className="infrasub">
            The Army Law College, Pune is 100% residential institution. Separate
            hostels exist for boys and girls. Both Boys & Girls Hostels have 2/3
            sharing rooms. Entire hostel room furniture and furnishing is
            provided by the Institution. Students have to get their personal
            items as notified in Prospectus & Joining Instructions. In campus
            hostel has a spacious Dining Hall, Central Common/TV Room,
            Gymnasium, open terrace and Service Lift. 
          </p>
        </div>
        <div className="col-lg-7 ">
          <div className="col-12 px-3">
            <img src={img1} alt="" className="img-fluid " />
          </div>
          <div className="col-12 px-2 d-flex flex-wrap fsmalldiv">
            <div className="fsmall col-4 p-2">
              <img src={img2} alt="" className="img-fluid" />
            </div>
            <div className="fsmall col-4 p-2">
              <img src={img3} alt="" className="img-fluid" />
            </div>
            <div className="fsmall col-4 p-2">
              <img src={img4} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap mt-5 ">
        <div className="col-lg-7">
          <div className="fdlarge ">
            <img src={img5} alt="" className="img-fluid" />
          </div>
          <div className="d-flex flex-wrap ">
            <div className="fdsmall col-4 m-2 ">
              <img src={img6} alt="" className="img-fluid" />
            </div>
            <div className="fdsmall col-4 m-2">
              <img src={img7} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12">
          <p className="progsubhead">Dinning Halls</p>
          <p className="infrasub">
            {" "}
            The Dining Hall (DH) serves both vegetarian and non- vegetarian
            meals. It is managed by the Institute on a no profit no loss basis.
            The Dining Hall (DH) Committee, which comprises students’
            representatives, looks after The Dining Hall (DH) matters under the
            Chairpersonship of a faculty member. Packed Lunch is available on
            Field Work Days for students on request.
          </p>
        </div>
      </div>
      <div className="d-flex flex-wrap mt-5">
        <div className="col-lg-4 col-sm-12">
          <p className="progsubhead">Recreational Facilities</p>
          <p className="infrasub">
            {" "}
            The governing body over all extracurricular, cultural, and sporting
            activities at ALC is called Recreational Facility. A Recreational is
            essential to a student's entire growth. Students can use it as a
            platform for showcasing their athletic and cultural talents.
            Participating in Recreational activities enhances qualities like
            dedication, involvement, balance, and leadership. All students
            actively participate in the range of events organized by
            Recreational. On the institute playground, Recreational offers
            facilities for popular games like Volleyball, Football, Kabaddi,
            Kho-Kho, and Cricket. Students have access to indoor game
            facilities, such as table tennis, Indoor tennis, Carrom and Chess..
          </p>
        </div>
        <div className="col-lg-8 col-sm-12 d-flex flex-wrap  justify-content-center">
          <div className="col-5 m-2">
            <img src={img8} alt="" className="img-fluid" />
          </div>
          <div className="col-5 m-2">
            <img src={img9} alt="" className="img-fluid" />
          </div>
          <div className="col-5 mx-2">
            <img src={img10} alt="" className="img-fluid" />
          </div>
          <div className="col-5 mx-2">
            <img src={img11} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}
