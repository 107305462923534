import React from 'react';
import img1 from '../../../assests/images/infrastructure/18- Moot Court/Moot Court- -2-01.jpg';
import img2 from '../../../assests/images/infrastructure/18- Moot Court/Moot Court -02.jpg';
import img3 from '../../../assests/images/infrastructure/18- Moot Court/Mootcourt-01-01.jpg';
import img4 from '../../../assests/images/infrastructure/18- Moot Court/Mootcourt-01-02.jpg';
import img5 from '../../../assests/images/infrastructure/18- Moot Court/Mootcourt-01-04.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';


export default function Mootcourt() {
    const audiimg = [
        { img: img1 },
        { img: img2 },
        { img: img3 },
        { img: img4 },
        { img: img5 },
    ]
    return (
        <div className='container mt-4 sai'>
            <p className='progsubhead'>Moot Court:</p>
            <p className='progtext'></p>
            <Swiper
                modules={[Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{ delay: 3000 }}
                breakpoints={{
                    426: {
                        slidesPerView: 2
                    }
                }}
            >
                {
                    audiimg.map((img, i) => (
                        <SwiperSlide key={i}>
                            <img src={img.img} alt='' className='img-fluid swiperimg' />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}
