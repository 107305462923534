import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import TakshilaL from '../components/library/TakshilaL'
import DigitalL from '../components/library/DigitalL'
import WebL from '../components/library/WebL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBars, faTimes, } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import WebResources from '../components/library/WebResources';
import Rules from '../components/library/Rules';
import Services from '../components/library/Services';
import AdvisoryC from '../components/library/AdvisoryC';
import Databases from '../components/library/Databases';
import ImportantLinks from '../components/home/ImportantLinks';
import Survey from '../components/library/Survey';



export default function Library() {
    // const [openMenu, setOpenMenu] = useState(true);
    const [activeComponent, setActiveComponent] = useState('AboutLibrary');
    const [isMobileOpen, setIsMobileOpen] = useState(false)

    const toggleMenu = () => {
        // setOpenMenu(!openMenu)
        setIsMobileOpen(!isMobileOpen)
    }

    const handleClick = (componentName) => {
        setActiveComponent(componentName);
        setIsMobileOpen(false)
    }

    return (
        <div>
            <div className='aboutbg '>
                <div className='about-banner'>
                    <h2 className='title'>Library</h2>
                </div>
            </div>
            <div className='d-flex flex-wrap mt-5 container'>
                <div className='col-lg-3 col-sm-12 col-md-3 px-3 mb-3'>
                <FontAwesomeIcon icon={faBars} className='eventbar' onClick={toggleMenu} />
                {isMobileOpen && (
                    <ul className='position-absolute mobiledropdown'>
                            <div className='mobiledropdownMenu'>
                            <li onClick={() => handleClick('AboutLibrary')} className={activeComponent === 'AboutLibrary' ? 'Ldropdown dropheader active' : 'dropheader'}>About Library  {activeComponent === 'AboutLibrary' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            <li onClick={() => handleClick('Collection')} className={activeComponent === 'Collection' ? 'Ldropdown active' : ''}>Collection {activeComponent === 'Collection' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            <li onClick={() => handleClick('Rules')} className={activeComponent === 'Rules' ? 'Ldropdown active' : ''}>Rules & Regulations {activeComponent === 'Rules' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            {/* <li onClick={() => handleClick('Journals')} className={activeComponent === 'Journals' ? 'active' : ''}>Journals</li> */}
                            <li onClick={() => handleClick('Services')} className={activeComponent === 'Services' ? 'Ldropdown active' : ''}>Services {activeComponent === 'Services' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            <li onClick={() => handleClick('AdvisoryCommittee')} className={activeComponent === 'AdvisoryCommittee' ? 'Ldropdown active' : ''}>Advisory Committee {activeComponent === 'AdvisoryCommittee' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            <li onClick={() => handleClick('Databases')} className={activeComponent === 'Databases' ? 'Ldropdown active' : ''}>Databases {activeComponent === 'Databases' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                        </div>
                    </ul>
                )}
                        <div className='dropdownMenu'>
                            <li onClick={() => handleClick('AboutLibrary')} className={activeComponent === 'AboutLibrary' ? 'Ldropdown dropheader active' : 'dropheader'}>About Library  {activeComponent === 'AboutLibrary' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            <li onClick={() => handleClick('Collection')} className={activeComponent === 'Collection' ? 'Ldropdown active' : ''}>Collection {activeComponent === 'Collection' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            <li onClick={() => handleClick('Rules')} className={activeComponent === 'Rules' ? 'Ldropdown active' : ''}>Rules & Regulations {activeComponent === 'Rules' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            {/* <li onClick={() => handleClick('Journals')} className={activeComponent === 'Journals' ? 'active' : ''}>Journals</li> */}
                            <li onClick={() => handleClick('Services')} className={activeComponent === 'Services' ? 'Ldropdown active' : ''}>Services {activeComponent === 'Services' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            <li onClick={() => handleClick('AdvisoryCommittee')} className={activeComponent === 'AdvisoryCommittee' ? 'Ldropdown active' : ''}>Advisory Committee {activeComponent === 'AdvisoryCommittee' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                            <li onClick={() => handleClick('Databases')} className={activeComponent === 'Databases' ? 'Ldropdown active' : ''}>Databases {activeComponent === 'Databases' && <FontAwesomeIcon icon={faAngleRight} className='icon' />}</li>
                        </div>
                    
                </div>
                <div className='col-lg-9 col-sm-12 col-md-12 px-2 lib' >
                    {activeComponent === 'AboutLibrary' && <TakshilaL />}
                    {activeComponent === 'Collection' && <WebResources />}
                    {activeComponent === 'Rules' && <Rules />}
                    {/* {activeComponent === 'Journals' && <Journals />} */}
                    {activeComponent === 'Services' && <Services />}
                    {activeComponent === 'AdvisoryCommittee' && <AdvisoryC />}
                    {activeComponent === 'Databases' && <Databases />}
                    <Survey/>
                </div>
                <ImportantLinks />
            </div>



        </div>
    )
}
