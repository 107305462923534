import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { TeachingStaffData, NonTeachingStaffData } from "../../StaffData";

export default function Intro() {
  const [activeTab, setActiveTab] = useState("biography");
  const { id, staffType } = useParams();
  const staffMember =
    staffType === "teaching"
      ? TeachingStaffData.find((staff) => staff.id === parseInt(id))
      : NonTeachingStaffData.find((staff) => staff.id === parseInt(id));

  if (!staffMember) {
    return <div>No staff data available</div>;
  }

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="staffintro ">
        <div className="intro d-flex flex-wrap">
          {staffMember.img ? (
            <div className="introimg p-2">
              <img src={staffMember.img} alt="" className="img-fluid" />
            </div>
          ) : (
            <div></div>
          )}

          <div className=" m-4">
            <p className="staffname">
              {" "}
              {staffMember.name ? (
                staffMember.name
              ) : (
                <span>Name not available</span>
              )}
            </p>
            <p className="staffpost">
              {staffMember.post ? (
                staffMember.post
              ) : (
                <span>Post not available</span>
              )}
            </p>
            <p className="staffedu">
              {staffMember.qualification ? (
                staffMember.qualification
              ) : (
                <span>Qualification not available</span>
              )}
            </p>
            <p className="staffedu">{staffMember.qualification1 || " "}</p>
            <p className="staffedu">{staffMember.qualification2 || " "}</p>
            <p className="staffemail">
              {staffMember.email ? (
                staffMember.email
              ) : (
                <span>Email not available</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 bio">
        <div className="d-flex biobtn ">
          <p
            className={`biography ${activeTab === "biography" ? "active" : ""}`}
            onClick={() => handleClick("biography")}
          >
            Biography
          </p>
          <p
            className={`biography ${activeTab === "research" ? "active" : ""}`}
            onClick={() => handleClick("research")}
          >
            Research & Publications
          </p>
          {/* <p
            className={`biography ${activeTab === "presentation" ? "active" : ""}`}
            onClick={() => handleClick("presentation")}
          >
            Paper Presentation
          </p> */}
        </div>
        {activeTab === "biography" && (
          <p className="biodetail mt-3">
            {staffMember.biography ? (
              staffMember.biography
            ) : (
              <span>No biography data available</span>
            )}
          </p>
        )}
        {activeTab === "research" && (
          <>
            <div className="biodetail mt-3">
              <>
                {staffMember?.research?.length > 0 ? (
                  <ul>
                    {staffMember.research.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No research data available</p>
                )}
              </>
            </div>
            <div className="biodetail mt-3">
              <>
                {staffMember?.presentation?.length > 0 ? (
                  <>
                    <h4>Paper Presentation:</h4>
                    <ul>
                      {staffMember.presentation.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>
            {/* <div className="biodetail mt-3">
              <>
                {staffMember?.Authored_Book?.length > 0 ? (
                  <>
                    <h4>Authored Book:</h4>
                    <ul>
                      {staffMember.Authored_Book.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div> */}

            <div className="biodetail mt-3">
              <>
                {staffMember?.Authored_Book?.length > 0 ? (
                  <>
                    <h4>Authored Book:</h4>
                    <ul>
                      {staffMember.Authored_Book.map((book, index) => (
                        <li key={index}>
                          <strong>{book.title}</strong>
                          <ul>
                            {book.publishers.map((publisher, idx) => (
                              <li key={idx}>Publisher: {publisher}</li>
                            ))}
                            {book.isbns.map((isbn, idx) => (
                              <li key={idx}>ISBN: {isbn}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>

            <div className="biodetail mt-3">
              <>
                {staffMember?.Authored_Book_Chapters?.length > 0 ? (
                  <>
                    <h4>Authored Book Chapters:</h4>
                    <ul>
                      {staffMember.Authored_Book_Chapters.map(
                        (chapter, index) => (
                          <li key={index}>
                            <strong>{chapter.title}</strong>
                            <ul>
                              {chapter.editors &&
                                chapter.editors.map((editor, idx) => (
                                  <li key={idx}>Editor: {editor}</li>
                                ))}
                              {chapter.authors &&
                                chapter.authors.map((author, idx) => (
                                  <li key={idx}>Author: {author}</li>
                                ))}
                              {chapter.book && <li>Book: {chapter.book}</li>}
                              {chapter.publishers &&
                                chapter.publishers.map((publisher, idx) => (
                                  <li key={idx}>Publisher: {publisher}</li>
                                ))}
                              {chapter.pages && <li>Pages: {chapter.pages}</li>}
                              {chapter.isbns &&
                                chapter.isbns.map((isbn, idx) => (
                                  <li key={idx}>ISBN: {isbn}</li>
                                ))}
                            </ul>
                          </li>
                        )
                      )}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>

            <div className="biodetail mt-3">
              <>
                {staffMember?.Authored_Journal_Articles?.length > 0 ? (
                  <>
                    <h4>Authored Journal Articles:</h4>
                    <ul>
                      {staffMember.Authored_Journal_Articles.map(
                        (article, index) => (
                          <li key={index}>
                            <strong>{article.title}</strong>
                            <ul>
                              {article.journal && (
                                <li>Journal: {article.journal}</li>
                              )}
                              {article.issns &&
                                article.issns.map((issn, idx) => (
                                  <li key={idx}>ISSN: {issn}</li>
                                ))}
                              {article.pISSNs &&
                                article.pISSNs.map((pISSN, idx) => (
                                  <li key={idx}>pISSN: {pISSN}</li>
                                ))}
                              {article.eISSNs &&
                                article.eISSNs.map((eISSN, idx) => (
                                  <li key={idx}>eISSN: {eISSN}</li>
                                ))}
                              {article.pages && <li>Pages: {article.pages}</li>}
                            </ul>
                          </li>
                        )
                      )}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>

            <div className="biodetail mt-3">
              <>
                {staffMember?.WORKSHOPS?.length > 0 ? (
                  <>
                    <h4>WORKSHOPS:</h4>
                    <ul>
                      {staffMember.WORKSHOPS.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>
            <div className="biodetail mt-3">
              <>
                {staffMember?.PAPER_PRESENTATION_PARTICIPATION?.length > 0 ? (
                  <>
                    <h4>PAPER PRESENTATION/PARTICIPATION:</h4>
                    <ul>
                      {staffMember.PAPER_PRESENTATION_PARTICIPATION.map(
                        (item, index) => (
                          <li key={index}>
                            <strong>{item.title}</strong>
                            <ul>
                              {item.description &&
                                item.description.map((des, idx) => (
                                  <li key={idx}> {des}</li>
                                ))}
                            </ul>
                          </li>
                        )
                      )}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>
            <div className="biodetail mt-3">
              <>
                {staffMember?.CONFERENCES_AND_SEMINARS?.length > 0 ? (
                  <>
                    <h4>CONFERENCES AND SEMINARS:</h4>
                    <ul>
                      {staffMember.CONFERENCES_AND_SEMINARS.map(
                        (item, index) => (
                          <li key={index}>{item}</li>
                        )
                      )}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>
          </>
        )}
      </div>
    </>
  );
}
