import React from 'react'
import Intro from '../components/aboutstaff/Intro'
import Biography from '../components/aboutstaff/Biography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function AboutStaffPage() {
  return (
    <div>
      <div className='aboutbg'>
        <div className='about-banner'>
         <div className='flex-column w-100 container'>
        <Link to='/staff'>  <div className=' p-2 leftarrow'>
            <FontAwesomeIcon icon={faLeftLong}  className='lefticon'/>
          </div></Link>
          <div className='col-12 text-center'>
            <h2 className='title'>ABOUT STAFF</h2>
          </div>
         </div>
        </div>
      </div>
      <Intro />
    </div>
  )
}
