import React, { useState, useEffect, useRef } from "react";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBars,
  faTimes,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import armylogo from "../../assests/images/logo/armylogo.png";
import logo from "../../assests/images/logo/logo.png";

import Marquee from "react-fast-marquee";
import styled from "styled-components";

const MarqueeItem = styled.div`
  display: inline-block;
  margin-right: 40px;
  font-size: 1.5em;
  font-weight: 700;
  font-style: italic;
  color: black;
`;

export default function Header() {
  const location = useLocation();
  const homePage = location.pathname === "/";
  const shouldShowMarquee = location.pathname === "/";
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeSecondDropdown, setActiveSecondDropdown] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [secondDropdownOpen, setSecondDropdownOpen] = useState({});
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // You can adjust the threshold as needed based on your layout
      if (scrollY > 10) {
        // Example threshold of 50 pixels from the top
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const DropdownTitle = [
    {
      title: "Staff",
      options: ["Teaching Staff", "Non-Teaching Staff"],
      links: ["/staff/#teaching", "/staff/#nonteaching"],
    },
    { title: "Alumni", options: [], links: "/alumni" },
    { title: "NAAC", options: [], links: "/committee/NAAC" },
    {
      title: "Societies & Cells",
      options: ["Statuory Committees", "College Committees"],
      links: ["/societies", "/collegeC"],
    },
    { title: "Jobs & Tenders", options: [], links: "/committee/JOBS" },
    { title: "Placements", options: [], links: "/committee/Carrer" },
    { title: "ALC Publication", options: [], links: "/courseprogram3" },
    {
      title: "ERP Login",
      options: ["Staff Login", "Student Login"],
      links: [
        "https://alc.edupluscampus.com",
        "https://myalc.edupluscampus.com",
      ],
    },
  ];

  const DropdownHead = [
    { title: "Home", options: [], links: "/" },
    {
      title: "About Us",
      options: [],

      links: "/about",
    },
    { title: "Infrastructure", options: [], links: "/infra" },
    {
      title: "Admissions",
      options: [
        "Download Prospectous",
        "Admission Procedures",
        "Admission Notices",
        "Download Forms",
        "Admissions FAQ'S",
      ],
      links: [
        "/addmission/prospectous",
        "/addmission/procedure",
        "/addmission/notices",
        "/addmission/forms",
        "/addmission/faq",
      ],
    },
    { title: "Library", options: [], links: "/library" },
    {
      title: "Academics",
      options: ["BBA LLB", "BA LLB"],
      links: ["/bbaprogram", "/programs"],
    },
    { title: "Events", options: [], links: "/events" },
    { title: "Student Life", options: [], links: "/studentlife" },
  ];

  useEffect(() => {
    // Close the dropdown when the page changes
    setActiveDropdown(null);
    setActiveSecondDropdown(null);
  }, [location.pathname]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the clicked element is inside the dropdown or navbar
      if (
        !event.target.closest(".dropdown") &&
        !event.target.closest(".navbar")
      ) {
        // Clicked outside, close the dropdown
        setActiveDropdown(null);
        setActiveSecondDropdown(null);
      }
    };

    // Add event listener when component mounts
    document.body.addEventListener("click", handleOutsideClick);

    // Remove event listener when component unmounts
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
    setActiveSecondDropdown(null);
  };

  const toggleDropdown2 = (index) => {
    setActiveSecondDropdown(activeSecondDropdown === index ? null : index);
    setActiveDropdown(null);
  };

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
    setActiveDropdown(null);
    setActiveSecondDropdown(null);
  };

  const closeNavbar = () => {
    setIsNavbarOpen(false);
    setActiveDropdown(null);
    setActiveSecondDropdown(null);
  };

  const renderLink = (link, label) => {
    if (link.startsWith("http")) {
      return (
        <a
          href={link}
          className="linerm"
          target="_blank"
          rel="noopener noreferrer"
          onClick={closeNavbar}
        >
          {label}
        </a>
      );
    } else {
      return (
        <Link to={link} className="linerm" onClick={closeNavbar}>
          {label}
        </Link>
      );
    }
  };

  return (
    <div>
      <Navbar
        className={`d-flex justify-content-between navbar p-3 navbar-expand-lg  position-fixed w-100  ${
          homePage ? "" : "headerbg"
        } `}
      >
        <Link to="/" className="linerm">
          <div className="logodiv d-flex justify-content-between align-items-center col-12">
            <div className="logo">
              <img src={logo} alt="" className="img-fluid cursor-pointer" />
            </div>
            <div className="collegename">
              <h2> ARMY LAW COLLEGE PUNE </h2>
              <p>(Affiliated to Savitribai Phule Pune University)</p>
              <p>(Approved by Bar Council of India & Govt. of Maharashtra)</p>
            </div>
            <div className="logo logo-1">
              <img src={armylogo} alt="" className="img-fluid" />
            </div>
          </div>
        </Link>
        <div className="dropdown col-9">
          <div className="d-flex  align-items-center justify-content-end">
            {DropdownHead.map((dropdown, index) => (
              <div key={index} className="p-1">
                {dropdown.options.length === 0 && (
                  <div>
                    {renderLink(dropdown.links, dropdown.title)}
                    {index !== DropdownHead.length - 1 && (
                      <span className="p-1 vert-line">|</span>
                    )}
                  </div>
                )}
                {dropdown.options.length > 0 && (
                  <div>
                    <div
                      className={`dropTitle ${
                        activeSecondDropdown === index ? "open" : ""
                      }`}
                      onClick={() => toggleDropdown2(index)}
                    >
                      {dropdown.title}
                      <span className="p-1">
                        <FontAwesomeIcon
                          icon={
                            activeSecondDropdown === index
                              ? faAngleUp
                              : faAngleDown
                          }
                        />
                      </span>
                      {index !== DropdownHead.length - 1 && (
                        <span className="p-1 vert-line">|</span>
                      )}
                    </div>
                    <div
                      className={`dropdown-menu ${
                        activeSecondDropdown === index ? "show" : ""
                      }`}
                      aria-labelledby="dropdownMenu2"
                    >
                      {dropdown.options.map((option, optionIndex) => (
                        <li
                          key={optionIndex}
                          className="dropdown-item"
                          onClick={closeNavbar}
                        >
                          {renderLink(dropdown.links[optionIndex], option)}
                        </li>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <Link to="/contact" className="linerm">
              {" "}
              <button className="nav-btn px-3 py-1 mx-3">Contact Us</button>
            </Link>
          </div>
          <div className="d-flex justify-content-end mt-2">
            {DropdownTitle.map((dropdown, index) => (
              <div key={index} className="p-1">
                {dropdown.options.length === 0 && (
                  <div>
                    {renderLink(dropdown.links, dropdown.title)}
                    {index !== DropdownTitle.length - 1 && (
                      <span className="p-1 vert-line">|</span>
                    )}
                  </div>
                )}
                {dropdown.options.length > 0 && (
                  <div>
                    <div
                      className={`dropTitle ${
                        activeDropdown === index ? "open" : ""
                      }`}
                      onClick={() => toggleDropdown(index)}
                    >
                      {dropdown.title}
                      <span className="p-1">
                        <FontAwesomeIcon
                          icon={
                            activeDropdown === index ? faAngleUp : faAngleDown
                          }
                        />
                      </span>
                      {index !== DropdownTitle.length - 1 && (
                        <span className="p-1 vert-line">|</span>
                      )}
                    </div>
                    <div
                      className={`dropdown-menu ${
                        activeDropdown === index ? "show" : ""
                      }`}
                      aria-labelledby="dropdownMenu2"
                    >
                      {dropdown.options.map((option, optionIndex) => (
                        <li
                          key={optionIndex}
                          className="dropdown-item"
                          onClick={closeNavbar}
                        >
                          {renderLink(dropdown.links[optionIndex], option)}
                        </li>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex">
          <Link to="/contact" className="linerm">
            {" "}
            <button className="nav-btn px-3 py-1 mx-3 res-btn">
              Contact Us
            </button>
          </Link>
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleNavbar();
            }}
          >
            {isNavbarOpen ? (
              <FontAwesomeIcon icon={faTimes} className="baricon" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="baricon" />
            )}
          </div>
        </div>
      </Navbar>
      <div
        className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
        id="navbarNav"
      >
        <ul className=" navbar transparent-bg position-fixed w-100 mt-5 ">
          {DropdownHead.map((dropdown, index) => (
            <div key={index} className="p-1">
              {dropdown.options.length === 0 && (
                <div>{renderLink(dropdown.links, dropdown.title)}</div>
              )}
              {dropdown.options.length > 0 && (
                <div>
                  <div
                    className={`dropTitle ${
                      activeSecondDropdown === index ? "open" : ""
                    }`}
                    onClick={() => toggleDropdown2(index)}
                  >
                    {dropdown.title}
                    <span className="p-1">
                      <FontAwesomeIcon
                        icon={
                          activeSecondDropdown === index
                            ? faAngleUp
                            : faAngleDown
                        }
                      />
                    </span>
                  </div>
                  <div
                    className={`dropdown-menu ${
                      activeSecondDropdown === index ? "show" : ""
                    }`}
                    aria-labelledby="dropdownMenu2"
                  >
                    {dropdown.options.map((option, optionIndex) => (
                      <li
                        key={optionIndex}
                        className="dropdown-item"
                        onClick={closeNavbar}
                      >
                        {renderLink(dropdown.links[optionIndex], option)}
                      </li>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
          {DropdownTitle.map((dropdown, index) => (
            <div key={index} className="p-1">
              {dropdown.options.length === 0 && (
                <div>{renderLink(dropdown.links, dropdown.title)}</div>
              )}
              {dropdown.options.length > 0 && (
                <div>
                  <div
                    className={`dropTitle ${
                      activeDropdown === index ? "open" : ""
                    }`}
                    onClick={() => toggleDropdown(index)}
                  >
                    {dropdown.title}
                    <span className="p-1">
                      <FontAwesomeIcon
                        icon={
                          activeDropdown === index ? faAngleUp : faAngleDown
                        }
                      />
                    </span>
                  </div>
                  <div
                    className={`dropdown-menu ${
                      activeDropdown === index ? "show" : ""
                    }`}
                    aria-labelledby="dropdownMenu2"
                  >
                    {dropdown.options.map((option, optionIndex) => (
                      <li
                        key={optionIndex}
                        className="dropdown-item"
                        onClick={closeNavbar}
                      >
                        {renderLink(dropdown.links[optionIndex], option)}
                      </li>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </ul>
      </div>
      {shouldShowMarquee && (
        <div
          style={{
            visibility: isHidden ? "hidden" : "visible",
          }}
          className="marquee-div"
        >
          <Marquee>
            <MarqueeItem>
              * Final Merit List & Admission List-2 of 1st Year BA LL.B 2024-25
              is posted under Admission Notices *
            </MarqueeItem>
            <MarqueeItem>
              *Army Law College, Pune ranked 8th at All India Level (Private)
              Law Institutes and 3rd in the State of Maharashtra as per the
              Indian Institutional Ranking Framework (IIRF) - 2024*
            </MarqueeItem>
          </Marquee>
        </div>
      )}
    </div>
  );
}
