import React, { useState } from 'react';
import rightArrow from '../../assests/images/others/arrow_right_alt_FILL0_wght400_GRAD0_opsz24.svg';
import downArrow from '../../assests/images/others/arrow_right_alt_FILL0_wght400_GRAD0_opsz24 (1).svg';
import { FaqData } from '../../AddmissionData';
import Contact from '../home/Contact';

export default function Faq() {
  const [showAnswer, setShowAnswer] = useState(1);
 
  const toggleAnswer = (id) => {
    setShowAnswer(id === showAnswer ? null : id);
  };
  return (
    <div id='faq'>
      <div className='d-flex mt-5 flex-wrap container'>
        <div className=' col-lg-3 col-sm-12 col-md-3'>
          <p className='faq-text'>
            FAQ's will help you to know Everything about the college. Can't find the answer what you're looking for please contact
          </p>
        </div>
        <div className='d-flex flex-column col-lg-9 col-sm-12 col-md-9  px-2 faqdiv mb-5'>
        {FaqData.map((data, i) => (
            <div key={i} className={`arc-sidetext mb-2 py-3 px-4 ${data.id === showAnswer ? 'ansactive' : ''}`}>
              <div className='progtext d-flex mt-0'>
                <div className='col-1' onClick={() => toggleAnswer(data.id)}>
                  <img src={data.id === showAnswer ? downArrow : rightArrow} alt='' className='img-fluid' />
                </div>
                <div className='col-11 mb-0'>
                  <p><span>Q{data.id}.</span> {data.que}</p>
                  {data.id === showAnswer && (
                    <p className='faq-ans mt-3'>{data.ans}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
            </div>
        </div>
        <Contact/>
      </div>
      )
}
