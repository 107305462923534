import React from 'react';
import img1 from '../../assests/images/facilities/IMG_0945.png';
import img2 from '../../assests/images/facilities/IMG_0985.png';
import img3 from '../../assests/images/facilities/IMG_0996.png';
import img4 from '../../assests/images/facilities/IMG_1015.png'

export default function Counselling() {
  return (
    <div className='container'>
        <div className='d-flex flex-wrap mt-5'>
                <div className='col-lg-4 col-sm-12'>
                    <p className='progsubhead'>Counselling Centre</p>
                    <p className='infrasub'>  The Counselling services at the Army Law College, Pune, a part of Student Support Services. The Counselling Centre has been providing services like individual counselling and developmental group activities for Army Law College students and staff. It is open six days a week and its hours are adapted to student’s needs. The Counsellors help the students in their day-to-day concerns and also enhance their overall functioning. Workshops on topics relevant to increasing the capacities of the students are regularly conducted by the Counsellors.</p>
                </div>
                <div className='col-lg-8 col-sm-12 d-flex flex-wrap  justify-content-center'>
                    <div className='col-5 m-2'>
                        <img src={img1} alt='' className='img-fluid' />
                    </div>
                    <div className='col-5 m-2'>
                        <img src={img2} alt='' className='img-fluid' />
                    </div>
                    <div className='col-5 mx-2'>
                        <img src={img3} alt='' className='img-fluid' />
                    </div>
                    <div className='col-5 mx-2'>
                        <img src={img4} alt='' className='img-fluid' />
                    </div>
                </div>
            </div>
    </div>
  )
}
