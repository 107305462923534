import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBars, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { servicesData } from '../../LibraryData';

export default function Services() {
    return (
        <div>

            {
                servicesData.map((data, i) => (
                    <div key={i}>
                        <h2 className='progsubhead'>{data.title}</h2>
                        <p className='progtext mt-0 mb-5'>{data.description}</p>
                    </div>
                ))
            }
        </div>
    )
}
