import React from "react";
import principal from "../../assests/images/teaching/Dr Madhushree .jpg";
import pen from "../../assests/images/others/pen.png";

export default function PrincipalDetail() {
  return (
    <div className="text-center container mt-5" id="princMsg">
      <h2 className="abouta-title">PRINCIPAL’S MESSAGE</h2>
      <div className="d-flex mt-5 principaldiv flex-wrap">
        <div className="col-lg-4 col-md-8 col-sm-4 princimg ">
          <img src={principal} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-7 col-12 mx-2 princdetail">
          <p className="aboutp ">
            Army Law College, Pune is established with the noble objective of
            imparting the quality legal education to the children of our brave,
            patriotic & selfless Army Personnel. In the age of rapid
            commercialization & commodification of higher education, Army Law
            College, Pune is committed to sacrosanct & altruistic mission of
            creating ethical, knowledgeable and skilled lawyers, who will be
            responsible as a global citizen and sentinels of the civil liberties
            of people. Martin Luther King (Jr.) said: “Injustice anywhere is
            threat to justice everywhere”. Army Law College, Pune is dedicated
            to make practice- ready lawyers who will serve as the guardians of
            our justice delivery system. In a highly competitive and globalized
            world, we, at Army Law College, Pune are determined to enable our
            students to face the new challenges posed by globalization & reverse
            globalization, rapid industrialization & environmental degradation,
            digital revolution & privacy issues, technological revolution &
            unemployment, large scale inter- country migration & Geo- political
            conflicts, intellectual property & community interests and evolving
            medical science & ethical dilemmas. Apart from imparting the domain
            knowledge of law, we also emphasize on experiential learning and
            skill building through moot courts, mock trials, internships, field
            visits and soft- skills training. Our mission is to shape the minds
            of bright and aspiring law students so that they can serve the
            humanity. We have a bright hope that Army Law College, Pune will
            undoubtedly set new benchmarks in the field of quality legal
            education and carve a niche of itself at national & international
            level, with the sustained efforts of its vibrant & visionary
            management of AWES, scholarly & dedicated faculty and our bright
            budding lawyers.
          </p>
          <div className="writer p-3">
            <div>
              <h3>Dr Madhushree Joshi</h3>
              <p>Principal, Army Law College, Pune</p>
            </div>
            <div>
              <img src={pen} alt="" className="img-fluid pen" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
