import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBars, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { AdvisoryCommitee } from '../../LibraryData';

export default function AdvisoryC() {
    return (
        <div>
                    <h2 className='progsubhead'>Advisory Committee</h2>
                    <p className='progtext'>The library has an advisory committee which comprises of Registrar, Principal, Secretary, Members and Students Members. The duties of the committee include formulation of policies and strategies for the development of the library services and facilities.</p>
                    <h2 className='progsubhead mt-5'>Library Advisory Committee:</h2>
                    <div className='tables mt-5'>
                        <table className="Lcustom-table col-12 ">
                            <tbody>
                            <tr className='lthead'>
                                <td className="col-2">Sr No</td>
                                <td className="col-6">Name and Designation</td>
                                <td className="col-4">Position in LAC</td>
                            </tr>
                                {
                                    AdvisoryCommitee.map((data, i) => (
                                        <tr key={i}>
                                            <td className="col-2">{data.id}</td>
                                            <td className="col-6">{data.name} <br></br>{data.designation}</td>
                                            <td className="col-4">{data.post}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
    
       
    )
}
